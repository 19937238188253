const bbaspecialization = [
    {
        id: 1,
        title: "BBA in Banking, Finance, and Insurance Services",
        coursename: "BBA in Banking, Finance, and Insurance Services Course Details",
        coursepageimg: "",
        courseTitle: "",
        courseDesc: "Bachelor of Business Administration (BBA) in Banking, Finance, and Insurance Services is a specialized undergraduate program designed to provide students with a comprehensive understanding of banking, finance, and insurance principles, practices, and operations. This program integrates core business administration subjects with specialized coursework focused on banking, financial services, and insurance sectors.",
        coresubjectstitle1: "Principles of Management: ",
        coresubjectsdescription1: "Introduction to management concepts, functions of management, organizational behavior, and leadership styles relevant to banking, finance, and insurance sectors",
        coresubjectstitle2: "Business Communication: ",
        coresubjectsdescription2: "Developing effective communication skills for business contexts, including written communication, presentations, and interpersonal skills.",
        coresubjectstitle3: "Microeconomics and Macroeconomics: ",
        coresubjectsdescription3: "Understanding economic principles at the micro and macro levels, including supply and demand, market structures, and fiscal and monetary policy.",
        coresubjectstitle4: "Business Mathematics and Statistics: ",
        coresubjectsdescription4: "Mathematical techniques and statistical methods for business analysis, including financial calculations, data interpretation, and regression analysis.",
        coresubjectstitle5: "Financial Accounting: ",
        coresubjectsdescription5: "Basics of financial accounting, understanding financial statements, and financial analysis relevant to banking and finance decision-making.        ",
        coresubjectstitle6: "Banking Fundamentals: ",
        coresubjectsdescription6: "Introduction to banking operations, banking products and services, banking regulations, and customer relationship management.        ",
        coresubjectstitle7: "Insurance Principles: ",
        coresubjectsdescription7: "Overview of insurance principles, insurance products and services, insurance regulations, and risk management.",
        coresubjectstitle8: "Corporate Finance: ",
        coresubjectsdescription8: "Introduction to corporate finance principles, including capital structure, financial markets, and investment decision-making.",
        coresubjectstitle9: "Financial Management: ",
        coresubjectsdescription9: "Techniques for financial decision-making, capital budgeting, cost of capital, and working capital management.",
        coresubjectstitle10: "Risk Management in Banking and Insurance: ",
        coresubjectsdescription10: "Identifying, assessing, and managing risks in banking and insurance operations, including credit risk, market risk, and operational risk.",
        specializedcoursetitle1: "Banking Operations: ",
        specializedcoursedescription1: "Managing day-to-day banking operations, including deposit management, lending practices, treasury operations, and electronic banking services.",
        specializedcoursetitle2: "Investment Analysis: ",
        specializedcoursedescription2: "Techniques for investment analysis, portfolio management, and risk-return trade-offs in investment portfolios.        ",
        specializedcoursetitle3: "Financial Markets and Instruments: ",
        specializedcoursedescription3: "Overview of financial markets, including money markets, capital markets, and derivative markets, and financial instruments traded in these markets.        ",
        specializedcoursetitle4: "Insurance Underwriting: ",
        specializedcoursedescription4: "Principles of insurance underwriting, risk assessment, and premium calculation for various insurance products.",
        specializedcoursetitle5: "Regulatory Compliance: ",
        specializedcoursedescription5: "Understanding banking, finance, and insurance regulations, compliance requirements, and ethical considerations in financial services.",
        internshiptitle: "Internship/Practical Training:",
        internshipdescription: "BBA in Banking, Finance, and Insurance Services programs often include an internship or practical training component where students gain hands-on experience in banking, finance, or insurance roles at financial institutions, insurance companies, or regulatory agencies.",
        careeropportunitiestitle: "Career Opportunities:",
        careeropportunitiesdescription1: "Bank Officer/Assistant",
        careeropportunitiesdescription2: "Financial Analyst",
        careeropportunitiesdescription3: "Investment Analyst",
        careeropportunitiesdescription4: "Credit Analyst",
        careeropportunitiesdescription5: "Insurance Agent/Broker",
        careeropportunitiesdescription6: "Risk Analyst",
        careeropportunitiesdescription7: "Financial Planner",
        careeropportunitiesdescription8: "Compliance Officer",
        whypursuespecializationtitle: "Why Pursue BBA in Banking, Finance, and Insurance Services?",
        whypursuespecializationdescription1: "Gain specialized knowledge and skills in banking, finance, and insurance sectors, highly valued in the financial services industry.",
        whypursuespecializationdescription2: "Develop a strong foundation in business administration subjects combined with specialized coursework focused on banking, finance, and insurance principles and practices.",
        whypursuespecializationdescription3: "Expand career opportunities and advance to entry-level positions in banking, finance, and insurance organizations.",
        whypursuespecializationdescription4: "Acquire practical experience through internships or practical training components included in the program.",
        whypursuespecializationdescription5: "Prepare for further professional certifications such as Chartered Financial Analyst (CFA) or Certified Insurance Professional (CIP).",
    },
    {
        id: 2,
        title: "BBA in Entrepreneurship Course",
        coursename: "BBA in Entrepreneurship Course Details",
        coursepageimg: "",
        courseTitle: "",
        courseDesc: "Bachelor of Business Administration (BBA) in Entrepreneurship is a specialized undergraduate program designed to provide students with the knowledge, skills, and mindset required to start, manage, and grow their own businesses. This program integrates core business administration subjects with specialized coursework focused on entrepreneurship, innovation, and business creation.",
        coresubjectstitle1: "Principles of Management: ",
        coresubjectsdescription1: "Introduction to management concepts, functions of management, organizational behavior, and leadership styles relevant to entrepreneurship.",
        coresubjectstitle2: "Business Communication: ",
        coresubjectsdescription2: "Developing effective communication skills for business contexts, including written communication, presentations, and interpersonal skills essential for entrepreneurship.",
        coresubjectstitle3: "Microeconomics and Macroeconomics: ",
        coresubjectsdescription3: "Understanding economic principles at the micro and macro levels, including market analysis, demand forecasting, and business environment analysis.",
        coresubjectstitle4: "Business Mathematics and Statistics: ",
        coresubjectsdescription4: "Mathematical techniques and statistical methods for business analysis, including financial calculations, data interpretation, and market research.",
        coresubjectstitle5: "Marketing Management: ",
        coresubjectsdescription5: "Principles of marketing, including market research, product development, pricing strategies, distribution channels, and promotion techniques relevant to startups.",
        coresubjectstitle6: "Financial Management: ",
        coresubjectsdescription6: "Techniques for financial decision-making, budgeting, financial forecasting, and cash flow management crucial for entrepreneurial ventures.",
        coresubjectstitle7: "Entrepreneurial Mindset and Creativity: ",
        coresubjectsdescription7: "Developing an entrepreneurial mindset, fostering creativity, and recognizing opportunities for innovation and business creation.",
        coresubjectstitle8: "Business Law and Regulations: ",
        coresubjectsdescription8: "Understanding legal aspects of business formation, contracts, intellectual property, and compliance requirements for startups.",
        coresubjectstitle9: "Entrepreneurial Finance: ",
        coresubjectsdescription9: "Fundamentals of entrepreneurial finance, sources of funding, venture capital, angel investment, and crowdfunding options for startups.",
        coresubjectstitle10: "New Venture Creation: ",
        coresubjectsdescription10: "Process of starting a new business venture, including opportunity identification, feasibility analysis, business planning, and execution strategies.",
        specializedcoursetitle1: "Entrepreneurial Leadership: ",
        specializedcoursedescription1: "Developing leadership skills essential for entrepreneurship, including decision-making, problem-solving, and team management.",
        specializedcoursetitle2: "Innovation and Technology Management: ",
        specializedcoursedescription2: "Harnessing innovation and technology for business growth, managing technological change, and fostering innovation culture.",
        specializedcoursetitle3: "Social Entrepreneurship: ",
        specializedcoursedescription3: "Understanding social entrepreneurship concepts, creating social impact, and sustainability strategies for social enterprises.",
        specializedcoursetitle4: "Small Business Management: ",
        specializedcoursedescription4: "Managing small and medium-sized enterprises (SMEs), including operations management, supply chain management, and growth strategies.",
        specializedcoursetitle5: "Entrepreneurial Marketing: ",
        specializedcoursedescription5: "Marketing strategies tailored for startups and small businesses, guerrilla marketing techniques, and digital marketing for entrepreneurial ventures.",
        internshiptitle: "Internship/Practical Training:",
        internshipdescription: "BBA in Entrepreneurship programs often include an internship or practical training component where students gain hands-on experience in entrepreneurial environments, startups, or small business settings.",
        careeropportunitiestitle: "Career Opportunities:",
        careeropportunitiesdescription1: "Entrepreneur/Startup Founder",
        careeropportunitiesdescription2: "Small Business Owner/Manager",
        careeropportunitiesdescription3: "Business Development Manager",
        careeropportunitiesdescription4: "Innovation Consultant",
        careeropportunitiesdescription5: "Insurance Agent/Broker",
        careeropportunitiesdescription6: "Entrepreneurship Educator/Trainer",
        careeropportunitiesdescription7: "Social Entrepreneur",
        careeropportunitiesdescription8: "Corporate Intrapreneur",
        whypursuespecializationtitle: "Why Pursue BBA in Entrepreneurship?",
        whypursuespecializationdescription1: "Gain specialized knowledge and skills in entrepreneurship, innovation, and business creation, essential for starting and managing your own ventures.",
        whypursuespecializationdescription2: "Develop a strong foundation in business administration subjects combined with specialized coursework focused on entrepreneurial principles and practices.",
        whypursuespecializationdescription3: "Expand career opportunities and explore various entrepreneurial paths, including starting your own business, joining a startup, or working in innovation-driven organizations.",
        whypursuespecializationdescription4: "Acquire practical experience through internships or practical training components included in the program, providing valuable insights into the entrepreneurial ecosystem.",
        whypursuespecializationdescription5: "Connect with mentors, industry experts, and fellow entrepreneurs through networking opportunities and entrepreneurship events offered by the program.",
    },
    {
        id: 3,
        title: "BBA in Digital Marketing Course",
        coursename: "BBA in Digital Marketing Course Details",
        coursepageimg: "",
        courseTitle: "",
        courseDesc: "Bachelor of Business Administration (BBA) in Digital Marketing is a specialized undergraduate program designed to equip students with the skills and knowledge required to excel in the rapidly evolving field of digital marketing. This program integrates core business administration subjects with specialized coursework focused on digital marketing strategies, tools, and technologies.",
        coresubjectstitle1: "Principles of Management: ",
        coresubjectsdescription1: "Introduction to marketing concepts, including the marketing mix (product, price, place, promotion), market segmentation, and consumer behavior.",
        coresubjectstitle2: "Business Communication: ",
        coresubjectsdescription2: "Developing effective communication skills for business contexts, including written communication, presentations, and interpersonal skills relevant to digital marketing roles.",
        coresubjectstitle3: "Business Mathematics and Statistics: ",
        coresubjectsdescription3: "Mathematical techniques and statistical methods for business analysis, including data interpretation, hypothesis testing, and regression analysis.",
        coresubjectstitle4: "Principles of Management: ",
        coresubjectsdescription4: "Introduction to management concepts, functions of management, organizational behavior, and leadership styles.",
        coresubjectstitle5: "Financial Accounting: ",
        coresubjectsdescription5: "Basics of financial accounting, understanding financial statements, and financial analysis relevant to marketing decision-making.",
        coresubjectstitle6: "Introduction to Digital Marketing: ",
        coresubjectsdescription6: "Overview of digital marketing channels, strategies, and trends, including search engine optimization (SEO), search engine marketing (SEM), social media marketing (SMM), email marketing, and content marketing.",
        coresubjectstitle7: "Marketing Analytics: ",
        coresubjectsdescription7: "Using data analytics tools and techniques to measure and analyze digital marketing performance, track key performance indicators (KPIs), and optimize marketing campaigns.",
        coresubjectstitle8: "Digital Consumer Behavior: ",
        coresubjectsdescription8: "Understanding consumer behavior in the digital age, including online shopping behavior, decision-making processes, and factors influencing online purchase decisions.",
        coresubjectstitle9: "Website Design and Development: ",
        coresubjectsdescription9: "Basics of website design, user experience (UX) design principles, and website development tools and platforms.",
        coresubjectstitle10: "Social Media Marketing: ",
        coresubjectsdescription10: "Strategies for leveraging social media platforms (e.g., Facebook, Instagram, Twitter, LinkedIn) for brand building, customer engagement, and lead generation.",
        specializedcoursetitle1: "Search Engine Optimization (SEO): ",
        specializedcoursedescription1: "Techniques for optimizing website content, structure, and performance to improve search engine rankings and visibility",
        specializedcoursetitle2: "Search Engine Marketing (SEM): ",
        specializedcoursedescription2: "Managing pay-per-click (PPC) advertising campaigns on search engines, keyword research, bid management, and ad copywriting.",
        specializedcoursetitle3: "Email Marketing: ",
        specializedcoursedescription3: "Designing and executing email marketing campaigns, list building strategies, email automation, and email marketing metrics analysis.",
        specializedcoursetitle4: "Content Marketing: ",
        specializedcoursedescription4: "Creating and distributing valuable content to attract, engage, and convert target audiences, including content strategy development, content creation, and content promotion.",
        specializedcoursetitle5: "Digital Advertising: ",
        specializedcoursedescription5: "Planning and executing digital advertising campaigns across various channels, including display advertising, video advertising, and native advertising.",
        internshiptitle: "Internship/Practical Training:",
        internshipdescription: "BBA in Digital Marketing programs often include an internship or practical training component where students gain hands-on experience in digital marketing roles at marketing agencies, digital marketing firms, or corporate marketing departments.",
        careeropportunitiestitle: "Career Opportunities:",
        careeropportunitiesdescription1: "Digital Marketing Coordinator/Assistant",
        careeropportunitiesdescription2: "Social Media Coordinator/Manager",
        careeropportunitiesdescription3: "SEO Specialist",
        careeropportunitiesdescription4: "SEM Specialist",
        careeropportunitiesdescription5: "Content Marketing Specialist",
        careeropportunitiesdescription6: "Email Marketing Specialist",
        careeropportunitiesdescription7: "Digital Marketing Analyst",
        careeropportunitiesdescription8: "Digital Marketing Strategist",
        whypursuespecializationtitle: "Why Pursue BBA in Digital Marketing?",
        whypursuespecializationdescription1: "Gain specialized knowledge and skills in digital marketing, online advertising, and social media marketing, highly valued in the digital marketing industry.",
        whypursuespecializationdescription2: "Develop a strong foundation in business administration subjects combined with specialized coursework focused on digital marketing principles and practices.",
        whypursuespecializationdescription3: "Expand career opportunities and advance to entry-level positions in digital marketing agencies, online marketing firms, and corporate marketing departments.",
        whypursuespecializationdescription4: "Acquire practical experience through internships or practical training components included in the program.",
        whypursuespecializationdescription5: "Prepare for industry-recognized certifications such as Google Ads Certification or HubSpot Digital Marketing Certification.",
    },
    {
        id: 4,
        title: "BBA in Business Analytics Course",
        coursename: "BBA in Business Analytics Course Details",
        coursepageimg: "",
        courseTitle: "",
        courseDesc: "Bachelor of Business Administration (BBA) in Business Analytics is a specialized undergraduate program designed to equip students with the skills and knowledge required to analyze data, extract insights, and make data-driven decisions in various business contexts. This program integrates core business administration subjects with specialized coursework focused on data analysis, statistical methods, and analytical tools.",
        coresubjectstitle1: "Principles of Management: ",
        coresubjectsdescription1: "Introduction to management concepts, functions of management, organizational behavior, and leadership styles relevant to business analytics.",
        coresubjectstitle2: "Business Communication: ",
        coresubjectsdescription2: "Developing effective communication skills for business contexts, including written communication, presentations, and interpersonal skills essential for data analysis and reporting.",
        coresubjectstitle3: "Microeconomics and Macroeconomics: ",
        coresubjectsdescription3: "Understanding economic principles at the micro and macro levels, including market analysis, demand forecasting, and economic indicators relevant to business decision-making.",
        coresubjectstitle4: "Business Mathematics and Statistics: ",
        coresubjectsdescription4: "Mathematical techniques and statistical methods for business analysis, including probability theory, hypothesis testing, regression analysis, and time series analysis.",
        coresubjectstitle5: "Introduction to Business Analytics: ",
        coresubjectsdescription5: "Overview of business analytics concepts, techniques, and applications in different business domains.",
        coresubjectstitle6: "Data Management and Warehousing: ",
        coresubjectsdescription6: "Basics of database management systems, data warehousing concepts, data integration, and data quality management.",
        coresubjectstitle7: "Data Visualization: ",
        coresubjectsdescription7: "Techniques for visualizing data and communicating insights effectively using visualization tools and software.",
        coresubjectstitle8: "Predictive Analytics: ",
        coresubjectsdescription8: "Introduction to predictive modeling techniques, machine learning algorithms, and forecasting methods for predicting future trends and outcomes.",
        coresubjectstitle9: "Descriptive Analytics: ",
        coresubjectsdescription9: "Analyzing historical data to understand past performance, trends, and patterns using descriptive statistical methods.",
        coresubjectstitle10: "Business Intelligence: ",
        coresubjectsdescription10: "Leveraging business intelligence tools and platforms for data analysis, reporting, and decision support.",
        specializedcoursetitle1: "Advanced Data Analytics:",
        specializedcoursedescription1: " Advanced techniques for data analysis, including predictive modeling, clustering, decision trees, and text mining.",
        specializedcoursetitle2: "Big Data Analytics: ",
        specializedcoursedescription2: "Handling and analyzing large volumes of structured and unstructured data using big data technologies such as Hadoop, Spark, and NoSQL databases.",
        specializedcoursetitle3: "Marketing Analytics: ",
        specializedcoursedescription3: "Applying analytics techniques to marketing data for customer segmentation, campaign optimization, and marketing performance measurement.",
        specializedcoursetitle4: "Financial Analytics: ",
        specializedcoursedescription4: "Analyzing financial data for investment decision-making, risk management, and financial performance evaluation.",
        specializedcoursetitle5: "Supply Chain Analytics: ",
        specializedcoursedescription5: "Using analytics to optimize supply chain operations, inventory management, and logistics planning.",
        internshiptitle: "Internship/Practical Training:",
        internshipdescription: "BBA in Business Analytics programs often include an internship or practical training component where students gain hands-on experience in data analysis roles at organizations, analytics firms, or corporate analytics departments.",
        careeropportunitiestitle: "Career Opportunities:",
        careeropportunitiesdescription1: "Business Analyst",
        careeropportunitiesdescription2: "Data Analyst",
        careeropportunitiesdescription3: "Marketing Analyst",
        careeropportunitiesdescription4: "Financial Analyst",
        careeropportunitiesdescription5: "Supply Chain Analyst",
        careeropportunitiesdescription6: "Business Intelligence Analyst",
        careeropportunitiesdescription7: "Data Visualization Specialist",
        careeropportunitiesdescription8: "Predictive Modeler",
        whypursuespecializationtitle: "Why Pursue BBA in Entrepreneurship?",
        whypursuespecializationdescription1: "Gain specialized knowledge and skills in business analytics, data analysis, and decision-making, highly valued in organizations across industries.",
        whypursuespecializationdescription2: "Develop a strong foundation in business administration subjects combined with specialized coursework focused on analytics principles and practices.",
        whypursuespecializationdescription3: "Expand career opportunities and advance to entry-level positions in analytics-driven organizations, including consulting firms, financial institutions, and technology companies.",
        whypursuespecializationdescription4: "Acquire practical experience through internships or practical training components included in the program, providing valuable insights into real-world analytics projects.",
        whypursuespecializationdescription5: "Prepare for further professional certifications such as Certified Business Analytics Professional (CBAP) or SAS Certified Data Scientist.",
    },
    {
        id: 5,
        title: "BBA in Human Capital Management Course",
        coursename: "BBA in Human Capital Management Course Details",
        coursepageimg: "",
        courseTitle: "",
        courseDesc: "Bachelor of Business Administration (BBA) in Human Capital Management is a specialized undergraduate program designed to provide students with a comprehensive understanding of human resource management principles, practices, and strategies, with a focus on optimizing organizational performance through effective human capital management.",
        coresubjectstitle1: "Principles of Management: ",
        coresubjectsdescription1: "Introduction to management concepts, functions of management, organizational behavior, and leadership styles relevant to human capital management.",
        coresubjectstitle2: "Business Communication: ",
        coresubjectsdescription2: "Developing effective communication skills for business contexts, including written communication, presentations, and interpersonal skills.",
        coresubjectstitle3: "Microeconomics and Macroeconomics: ",
        coresubjectsdescription3: "Understanding economic principles at the micro and macro levels, including.labor economics and the impact of macroeconomic factors on human resources.",
        coresubjectstitle4: "Business Mathematics and Statistics: ",
        coresubjectsdescription4: "Mathematical techniques and statistical methods for business analysis, including data interpretation, hypothesis testing, and regression analysis.",
        coresubjectstitle5: "Principles of Human Resource Management: ",
        coresubjectsdescription5: "Introduction to human resource management principles, including HR planning, recruitment, selection, training and development, performance management, and compensation.",
        coresubjectstitle6: "Organizational Behavior: ",
        coresubjectsdescription6: "Understanding individual and group behavior in organizations, motivation theories, leadership styles, and organizational culture.",
        coresubjectstitle7: "Talent Acquisition and Retention: ",
        coresubjectsdescription7: "Strategies for attracting, selecting, and retaining talent, including recruitment techniques, employer branding, and employee retention strategies.",
        coresubjectstitle8: "Performance Management: ",
        coresubjectsdescription8: "Designing performance appraisal systems, setting performance goals, providing feedback, and managing performance issues.",
        coresubjectstitle9: "Compensation and Benefits Management: ",
        coresubjectsdescription9: "Designing compensation and benefits packages, salary structures, incentive systems, and employee benefits administration.",
        coresubjectstitle10: "Employee Training and Development: ",
        coresubjectsdescription10: "Principles of training needs analysis, training program design, implementation, and evaluation.",
        specializedcoursetitle1: "Strategic Human Resource Management: ",
        specializedcoursedescription1: "Formulating and implementing HR strategies aligned with organizational goals, competitive analysis, and strategic workforce planning.",
        specializedcoursetitle2: "Employee Relations and Conflict Management: ",
        specializedcoursedescription2: "Managing employee relations, handling workplace conflicts, and implementing dispute resolution mechanisms.",
        specializedcoursetitle3: "Diversity and Inclusion Management: ",
        specializedcoursedescription3: "Understanding diversity and inclusion in the workplace, implementing diversity programs, and managing diverse teams.",
        specializedcoursetitle4: "HR Analytics: ",
        specializedcoursedescription4: "Using data analytics to inform HR decision-making, including workforce planning, talent management, and predictive analytics.",
        specializedcoursetitle5: "Organizational Development: ",
        specializedcoursedescription5: "Strategies for managing organizational change, culture transformation, and fostering employee engagement.",
        internshiptitle: "Internship/Practical Training:",
        internshipdescription: "BBA in Human Capital Management programs often include an internship or practical training component where students gain hands-on experience in HR roles at organizations, HR consulting firms, or corporate HR departments.",
        careeropportunitiestitle: "Career Opportunities:",
        careeropportunitiesdescription1: "HR Coordinator/Assistant",
        careeropportunitiesdescription2: "Talent Acquisition Specialist",
        careeropportunitiesdescription3: "Employee Relations Specialist",
        careeropportunitiesdescription4: "Training and Development Coordinator",
        careeropportunitiesdescription5: "Compensation and Benefits Analyst",
        careeropportunitiesdescription6: "HR Generalist",
        careeropportunitiesdescription7: "Organizational Development Assistant",
        careeropportunitiesdescription8: "HR Consultant",
        whypursuespecializationtitle: "Why Pursue BBA in Entrepreneurship?",
        whypursuespecializationdescription1: "Gain specialized knowledge and skills in human resource management, organizational behavior, and talent management, highly valued in organizations across industries.",
        whypursuespecializationdescription2: "Develop a strong foundation in business administration subjects combined with specialized coursework focused on human capital management principles and practices.",
        whypursuespecializationdescription3: "Expand career opportunities and advance to entry-level positions in HR management, organizational development, and employee relations roles.",
        whypursuespecializationdescription4: "Acquire practical experience through internships or practical training components included in the program.",
        whypursuespecializationdescription5: "Prepare for further professional certifications such as Professional in Human Resources (PHR) or Society for Human Resource Management Certified Professional (SHRM-CP).",
    },

]
export default bbaspecialization;