const Sdata1 = [
  {
    id: 1,
    partnersname: "VGU",
    title: "Online BBA",
    coursename: "Online Bachelor of Business Administration (BBA) Course",
    
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "3-6 Years",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    coursepageimg: require("../../assets/bba.jpg"),
    admissionprocedure: require("../../assets/admissionprocedure.jpeg"),
    courseTitle:
      "Online Bachelor of Business Administration (BBA) Course Overview",
    courseDesc:
      "The Online Bachelor of Business Administration (BBA) program is designed to provide students with a comprehensive understanding of commerce, business principles, accounting, finance, economics, and management. Through a flexible and accessible online platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The program equips graduates with the knowledge and skills necessary to excel in various roles in the field of commerce, finance, and business.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through online education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Marketing Executive",
    scopeandcareer2: "Business development executive",
    scopeandcareer3: "Executive Assistant",
    scopeandcareer4: "Brand manager",
    scopeandcareer5: "Event manager",
    scopeandcareer6: "Account manager",
    scopeandcareer7: "Travel and tourism manager",
    scopeandcareer8: "Field sales executive",
    scopeandcareer9: "Financial analyst",
    scopeandcareer10: "Operation analyst",
    scopeandcareer11: "Human resource manager",
    questioncontent1: "Core Business Curriculum",
    answercontent1:
      "The program typically includes a core curriculum covering essential business topics such as management, marketing, finance, accounting, economics, operations, and strategic planning. These courses provide students with a solid foundation in fundamental business concepts and principles.",
    questioncontent2: "Elective Specializations",
    answercontent2:
      "Students may have the opportunity to choose elective courses or specializations based on their interests and career goals. Elective options may include areas such as entrepreneurship, human resources, international business, digital marketing, or supply chain management, allowing students to tailor their learning experience to suit their aspirations.",
    questioncontent3: "Online Learning Platform",
    answercontent3:
      " The BBA program utilizes an advanced online learning platform that offers interactive lectures, multimedia resources, virtual classrooms, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent4: "Flexible Schedule",
    answercontent4:
      "Online BBA programs provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus program.",
    questioncontent5: "Faculty Support",
    answercontent5:
      "Experienced faculty members with expertise in their respective fields facilitate the online courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or online chat platforms to address students' questions and concerns.",
    questioncontent6: "Interactive Learning Tools",
    answercontent6:
      "Online BBA programs incorporate interactive learning tools and technologies to enhance the learning experience. These may include virtual simulations, case studies, video lectures, quizzes, and multimedia presentations, allowing students to apply theoretical concepts to real-world scenarios and develop practical skills.",
    questioncontent7: "Capstone Projects or Internships",
    answercontent7:
      " Many online BBA programs culminate in a capstone project or internship experience, where students can apply their knowledge and skills to solve real-world business challenges or gain hands-on experience in a professional setting. These practical experiences help students integrate classroom learning with practical application and prepare them for entry into the workforce.",
    specialization1: "General",
    specialization2: "Aviation",
    specialization3: "Enterprise risk",
    specialization4: "Digital marketing",
    specialization5: "Retail",
    specialization6: "Fintech",
    specialization7: "Travel & Tourism",
    specialization8: "International Finance",
    courseRequirementchild: "",
    growthandcareer:
      "Growth and Career Opportunities for Bachelor of Business Administration (BBA) Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "BBA graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " BBA graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, BBA graduates are well-positioned to start their ventures. The program equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the BBA program makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "BBA serves as a stepping stone for advanced education. Graduates can pursue Master of Business Administration (BBA) programs or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, BBA graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the program instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "BBA programs often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. BBA graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Bachelor of Business Administration provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, BBA graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
    whyonline: "WHY CHOOSE ONLINE?",
    whyonline1:
      "The online program gives you the flexibility to study from the location& time of your choice. This means online learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, online lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The online learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing online degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An online degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus programs. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of Online BBA Programs",
    benefitstitle1: "Flexibility: ",
    benefitstitle2: "Accessibility: ",
    benefitstitle3: "Cost-effectiveness: ",
    benefitstitle4: "Career Advancement: ",
    benefits1:
      "Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Online programs eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Online programs often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus programs.",
    benefits4:
      "Graduates of online BBA programs are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  {
    id: 2,
    partnersname: "VGU",
    title: "Online BCOM",
    coursename: "Online Bachelor of Commerce (BCom) Course",
    
    univers: "University",
    universCount: "10",
    duration: "Duration",
    coursepageimg: require("../../assets/olbcom.png"),
    admissionprocedure: require("../../assets/admissionprocedure.jpeg"),

    durationYear: "3-6 Years",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle: "Online  Bachelor of Commerce (BCom) Course Overview",
    courseDesc:
      "The Online Bachelor of Commerce (BCom) program is designed to provide students with a comprehensive understanding of commerce, business principles, accounting, finance, economics, and management. Through a flexible and accessible online platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The program equips graduates with the knowledge and skills necessary to excel in various roles in the field of commerce, finance, and business.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through online education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Diverse Opportunities",
    scopeandcareer2: "Industry Relevance",
    scopeandcareer3: "Specialized Roles",
    scopeandcareer4: "Entrepreneurship",
    scopeandcareer5: "Global Perspective",
    scopeandcareer6: "Networking Opportunities",
    scopeandcareer7: "Continuous Growth",
    scopeandcareer8: "Further Education",
    questioncontent1: "Core Commerce Curriculum:",
    answercontent1:
      "The BCom program typically includes a core curriculum covering fundamental commerce topics such as accounting, finance, economics, business law, taxation, marketing, management, and organizational behavior.",
    questioncontent2: "Accounting and Financial Management:",
    answercontent2:
      "Students learn accounting principles, financial statement analysis, budgeting, cost accounting, auditing, and financial management techniques used in business organizations.",
    questioncontent3: "Economics:",
    answercontent3:
      " The program covers microeconomics and macroeconomics concepts, including supply and demand, market structures, consumer behavior, national income accounting, monetary policy, and international trade.",
    questioncontent4: "Business Law and Taxation:",
    answercontent4:
      "Students gain an understanding of business law principles, contract law, corporate law, commercial law, and taxation laws relevant to business operations.",
    questioncontent5: "Management and Organizational Behavior:",
    answercontent5:
      "The program focuses on management principles, leadership styles, organizational behavior, human resource management, strategic management, and business ethics.",
    questioncontent6: "Marketing:",
    answercontent6:
      "Students learn marketing concepts, market research, consumer behavior, branding, advertising, promotion, pricing strategies, and digital marketing techniques.",
    questioncontent7: "Finance:",
    answercontent7:
      " The program covers financial markets, investment analysis, portfolio management, risk management, financial derivatives, and corporate finance principles.",
    questioncontent8: "Elective Specializations:",
    answercontent8:
      " Some BCom programs offer elective courses or specializations in specific areas of interest such as financial accounting, management accounting, taxation, banking, insurance, international business, entrepreneurship, or e-commerce.",
    questioncontent9: "Online Learning Platform:",
    answercontent9:
      " BCom programs utilize advanced online learning platforms that offer interactive lectures, multimedia resources, virtual classrooms, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent10: "Flexible Schedule:",
    answercontent10:
      " Online BCom programs provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus program.",
    questioncontent11: "Faculty Support: ",
    answercontent11:
      " Experienced faculty members with expertise in commerce, business, finance, and economics facilitate the online courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or online chat platforms to address students questions and concerns.",
    questioncontent12: "Interactive Learning Tools:",
    answercontent12:
      " Online BCom programs incorporate interactive learning tools and technologies to enhance the learning experience. These may include case studies, simulations, quizzes, assignments, and multimedia presentations, allowing students to apply theoretical concepts to real-world scenarios and develop practical skills.",
    questioncontent13: "Capstone Project or Internship:",
    answercontent13:
      " Many online BCom programs culminate in a capstone project or internship experience, where students can apply their knowledge and skills to solve real-world problems, gain practical experience, or contribute to ongoing projects in business organizations. These practical experiences help students integrate classroom learning with practical application and prepare them for entry into the workforce.",
    specialization1: "Banking",
    specialization2: "Human Resource",
    specialization3: "Finance",
    specialization4: "Marketing",
    courseRequirementchild: "",
    growthandcareer:
      "Growth and Career Opportunities for Bachelor of Business Administration (BCOM) Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "BCOM graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " BCOM graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, BCOM graduates are well-positioned to start their ventures. The program equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the BCOM program makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "BCOM serves as a stepping stone for advanced education. Graduates can pursue Master of Business Administration (BCOM) programs or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, BCOM graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the program instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "BCOM programs often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. BCOM graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Bachelor of Business Administration provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, BCOM graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
    whyonline: "WHY CHOOSE ONLINE?",
    whyonline1:
      "The online program gives you the flexibility to study from the location& time of your choice. This means online learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, online lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The online learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing online degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An online degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus programs. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of Online Bachelor of Commerce (BCom) Programs",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: Online programs eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: Online programs often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus programs.",
    benefits4:
      "Career Advancement: Graduates of online BBA programs are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  {
    id: 3,
    partnersname: "VGU",
    title: "Online BA",
    coursename: "Online Bachelor of Arts (BA) Course",
    
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "3-6 Years",
    coursepageimg: require("../../assets/ba.jpg"),
    admissionprocedure: require("../../assets/admissionprocedure.jpeg"),

    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle: "Online Bachelor of Arts (BA) Course Overview",
    courseDesc:
      "The Online Bachelor of Arts (BA) program offers students a wide range of academic disciplines to explore, allowing them to tailor their education to their interests and career goals. Through a flexible and accessible online platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The BA program equips graduates with critical thinking skills, cultural awareness, communication abilities, and a strong foundation in their chosen field of study.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through online education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Business processing outsourcing units",
    scopeandcareer2: "Broadcasting & media",
    scopeandcareer3: "Advertising & PR Agencies",
    scopeandcareer4: "Journalism",
    scopeandcareer5: "Community services",
    courseRequirementchild: "",
    questioncontent1: "Core Curriculum:",
    answercontent1:
      "The BA program typically includes a core curriculum covering foundational courses in humanities, social sciences, and liberal arts. These courses provide students with a broad understanding of various academic disciplines and foster interdisciplinary thinking.",
    questioncontent2: "Major Concentration:",
    answercontent2:
      "Students have the opportunity to choose a major concentration or area of specialization based on their interests and career aspirations. Popular majors include English literature, history, psychology, sociology, political science, economics, anthropology, philosophy, and communication studies.",
    questioncontent3: "Elective Courses:",
    answercontent3:
      " In addition to major requirements, students can select elective courses from a diverse range of subjects to complement their studies and broaden their academic horizons. Elective options may include foreign languages, fine arts, environmental studies, gender studies, global studies, and more.",
    questioncontent4: "Online Learning Platform:",
    answercontent4:
      "BA programs utilize advanced online learning platforms that offer interactive lectures, multimedia resources, virtual classrooms, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent5: "Flexible Schedule:",
    answercontent5:
      "Online BA programs provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus program.",
    questioncontent6: "Faculty Support:",
    answercontent6:
      "Experienced faculty members with expertise in their respective fields facilitate the online courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or online chat platforms to address students questions and concerns.",
    questioncontent7: "Interactive Learning Tools:",
    answercontent7:
      " Online BA programs incorporate interactive learning tools and technologies to enhance the learning experience. These may include virtual discussions, multimedia presentations, case studies, simulations, quizzes, and collaborative projects, allowing students to engage actively with course materials and apply theoretical concepts to real-world scenarios.",
    questioncontent8: "Capstone Project or Thesis: ",
    answercontent8:
      "Many online BA programs culminate in a capstone project, thesis, or research paper where students can demonstrate their understanding of the subject matter and conduct independent research under the guidance of faculty mentors. This culminating experience allows students to showcase their analytical abilities, critical thinking skills, and academic achievements.",
    specialization1: "Banking",
    specialization2: "Human Resource",
    specialization3: "Finance",
    specialization4: "Marketing",
    courseRequirementchild: "",
    growthandcareer:
      "Growth and Career Opportunities for Bachelor Of Arts Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "BA graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " BA graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, BA graduates are well-positioned to start their ventures. The program equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the BBA program makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "BBA serves as a stepping stone for advanced education. Graduates can pursue Master of Business Administration (BA) programs or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, BA graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the program instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "BBA programs often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. BA graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Bachelor of Business Administration provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, BA graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
    whyonline: "WHY CHOOSE ONLINE?",
    whyonline1:
      "The online program gives you the flexibility to study from the location& time of your choice. This means online learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, online lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The online learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing online degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An online degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus programs. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of Online BA Programs",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: Online programs eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: Online programs often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus programs.",
    benefits4:
      "Career Advancement: Graduates of online BBA programs are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  
  {
    id: 4,
    partnersname: "VGU",
    title: "Online BA-JMC",
    coursename: "Online BA-JMC Course",
    
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "3-6 Years",
    eligibility: "Eligibility",
    coursepageimg: require("../../assets/ba-jmc.jpg"),
    admissionprocedure: require("../../assets/admissionprocedure.jpeg"),

    qualif: "12th Passout",
    courseTitle: "Online BA-JMC Course Overview",
    courseDesc:
      "The Online BA-JMC program offers students a wide range of academic disciplines to explore, allowing them to tailor their education to their interests and career goals. Through a flexible and accessible online platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The BA program equips graduates with critical thinking skills, cultural awareness, communication abilities, and a strong foundation in their chosen field of study.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through online education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Journalist, Columnist or Reporter",
    scopeandcareer2: "Photojournalist",
    scopeandcareer3: "Illustrator",
    scopeandcareer4: "Public Relations Officer",
    scopeandcareer5: "Feature Writer",
    scopeandcareer6: "Staff or Freelance Writer",
    scopeandcareer7: "TV Correspondent",
    scopeandcareer8: "Video Jockey (VJ)",
    scopeandcareer8: "Radio Jockey (RJ)",
    scopeandcareer9: "News Analyst",
    scopeandcareer10: "Graphic Designers",
    scopeandcareer11: "Professor",
    scopeandcareer12: "Proofreader",
    scopeandcareer13: "Cartoonist",
    scopeandcareer14: "Editor",
    scopeandcareer15: "Feature Writer",
    scopeandcareer16: "Marketing Manager",
    questioncontent1: "Core Curriculum:",
    answercontent1:
      "The BA-JMC program typically includes a core curriculum covering foundational courses in humanities, social sciences, and liberal arts. These courses provide students with a broad understanding of various academic disciplines and foster interdisciplinary thinking.",
    questioncontent2: "Major Concentration:",
    answercontent2:
      "Students have the opportunity to choose a major concentration or area of specialization based on their interests and career aspirations. Popular majors include English literature, history, psychology, sociology, political science, economics, anthropology, philosophy, and communication studies.",
    questioncontent3: "Elective Courses:",
    answercontent3:
      " In addition to major requirements, students can select elective courses from a diverse range of subjects to complement their studies and broaden their academic horizons. Elective options may include foreign languages, fine arts, environmental studies, gender studies, global studies, and more.",
    questioncontent4: "Online Learning Platform:",
    answercontent4:
      "BA-JMC programs utilize advanced online learning platforms that offer interactive lectures, multimedia resources, virtual classrooms, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent5: "Flexible Schedule:",
    answercontent5:
      "Online BA-JMC programs provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus program.",
    questioncontent6: "Faculty Support:",
    answercontent6:
      "Experienced faculty members with expertise in their respective fields facilitate the online courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or online chat platforms to address students questions and concerns.",
    questioncontent7: "Interactive Learning Tools:",
    answercontent7:
      " Online BA-JMC programs incorporate interactive learning tools and technologies to enhance the learning experience. These may include virtual discussions, multimedia presentations, case studies, simulations, quizzes, and collaborative projects, allowing students to engage actively with course materials and apply theoretical concepts to real-world scenarios.",
    questioncontent8: "Capstone Project or Thesis: ",
    answercontent8:
      "Many online BA-JMC programs culminate in a capstone project, thesis, or research paper where students can demonstrate their understanding of the subject matter and conduct independent research under the guidance of faculty mentors. This culminating experience allows students to showcase their analytical abilities, critical thinking skills, and academic achievements.",
    specialization1: "Banking",
    specialization2: "Human Resource",
    specialization3: "Finance",
    specialization4: "Marketing",
    courseRequirementchild: "",
    growthandcareer: "Growth and Career Opportunities for BA-JMC Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      " BA-JMC graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      "  BA-JMC graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset,  BA-JMC graduates are well-positioned to start their ventures. The program equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the  BA-JMC program makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      " BA-JMC serves as a stepping stone for advanced education. Graduates can pursue Master of Business Administration (BA-JMC) programs or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development,  BA-JMC graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the program instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      " BA-JMC programs often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. BBA graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Bachelor of Business Administration provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, BBA graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",

    whyonline: "WHY CHOOSE ONLINE?",
    whyonline1:
      "The online program gives you the flexibility to study from the location& time of your choice. This means online learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, online lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The online learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing online degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An online degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus programs. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of Online BA-JMC Programs",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: Online programs eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: Online programs often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus programs.",
    benefits4:
      "Career Advancement: Graduates of Online BA-JMC programs are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  {
    id: 5,
    partnersname: "VGU",
    title: "Online BCA",
    coursename: "Online Bachelor of Computer Application (BCA) Course",
    
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "3-6 Years",
    coursepageimg: require("../../assets/bca.jpg"),
    admissionprocedure: require("../../assets/admissionprocedure.jpeg"),

    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle:
      "Online Bachelor of Computer Application (BCA) Course Overview",
    courseDesc:
      "The Online Bachelor of Computer Application (BCA) program is designed to provide students with a comprehensive understanding of computer science principles, programming languages, software development, and information technology concepts. Through a flexible and accessible online platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The program equips graduates with the knowledge and skills necessary to excel in various roles in the field of computer applications and technology.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through online education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Computer Programmer",
    scopeandcareer2: " Software  Developer",
    scopeandcareer3: "System Analyst",
    scopeandcareer4: "   Network Engineer",
    scopeandcareer5: " Software Architect",
    scopeandcareer5: " Software Tester",
    questioncontent1: "Core Computer Science Curriculum:",
    answercontent1:
      " The BCA program typically includes a core curriculum covering fundamental computer science topics such as computer programming, data structures, algorithms, database management systems, computer networks, software engineering, and web technologies.",
    questioncontent2: "Programming Languages:",
    answercontent2:
      " Students learn programming languages such as Java, C++, Python, and JavaScript, enabling them to develop software applications, algorithms, and solutions to real-world problems.Major Concentration",
    questioncontent3: "Software Development: ",
    answercontent3:
      " The program focuses on software development methodologies, techniques, and tools used in the design, development, testing, and maintenance of software systems and applications.",
    questioncontent4: "Information Technology Concepts: ",
    answercontent4:
      "Students gain an understanding of information technology concepts such as cybersecurity, cloud computing, data analytics, artificial intelligence, machine learning, and Internet of Things (IoT).",
    questioncontent5: "Elective Specializations: ",
    answercontent5:
      "Some BCA programs offer elective courses or specializations in specific areas of interest such as cybersecurity, data science, web development, mobile application development, game development, or digital marketing.",
    questioncontent6: "Online Learning Platform: ",
    answercontent6:
      "BCA programs utilize advanced online learning platforms that offer interactive lectures, multimedia resources, virtual labs, coding exercises, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent7: "Flexible Schedule: ",
    answercontent7:
      "Online BCA programs provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus program.",
    questioncontent8: "Faculty Support: ",
    answercontent8:
      "Experienced faculty members with expertise in computer science and information technology facilitate the online courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or online chat platforms to address students; questions and concerns.",
    questioncontent8: "Capstone Project or Internship: ",
    answercontent8:
      " Many online BCA programs culminate in a capstone project or internship experience, where students can apply their knowledge and skills to solve real-world problems, contribute to ongoing projects, or gain hands-on experience in a professional setting. These practical experiences help students integrate classroom learning with practical application and prepare them for entry into the workforce.",
    specialization1: "UX Design",
    specialization2: "Data Science",
    specialization3: "Robotics Process Automation",
    specialization4: "Cloud Technology & Information Security",
    specialization4: "Block Chain",
    specialization4: "Artificial Intelligence & Machine Learning",
    courseRequirementchild: "",
    growthandcareer:
      "Growth and Career Opportunities for Bachelor of Computer Application (BCA) Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "BCA graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " BCA graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, BCA graduates are well-positioned to start their ventures. The program equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the BCA program makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "BCA serves as a stepping stone for advanced education. Graduates can pursue Bachelor of Computer Application (BCA) programs or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, BCA graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the program instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "BCA programs often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. BCA graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Bachelor of Business Administration provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, BCA graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
    whyonline: "WHY CHOOSE ONLINE?",
    whyonline1:
      "The online program gives you the flexibility to study from the location& time of your choice. This means online learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, online lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The online learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing online degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An online degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus programs. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of Online BCA Programs",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: Online programs eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: Online programs often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus programs.",
    benefits4:
      "Career Advancement: Graduates of Online BCA programs are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  {
    id: 6,
    partnersname: "VGU",
    title: "BBA (ODL Distance)",
    coursename: "BBA Course (ODL Distance)",
    coursepageimg: require("../../assets/bbaodl.png"),


    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "3-6 Years",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle: "BBA Course (ODL Distance) Overview",
    courseDesc:
      "The BBA  program offers students a wide range of academic disciplines to explore, allowing them to tailor their education to their interests and career goals. Through a flexible and accessible online platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The BA program equips graduates with critical thinking skills, cultural awareness, communication abilities, and a strong foundation in their chosen field of study.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through odl education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Marketing Executive",
    scopeandcareer2: "Business development executive",
    scopeandcareer3: "Executive Assistant",
    scopeandcareer4: "Brand manager",
    scopeandcareer5: "Event manager",
    scopeandcareer6: "Account manager",
    scopeandcareer7: "Travel and tourism manager",
    scopeandcareer8: "Field sales executive",
    scopeandcareer9: "Financial analyst",
    scopeandcareer10: "Operation analyst",
    scopeandcareer11: "Human resource manager",
    questioncontent1: "Core Business Curriculum",
    answercontent1:
      "The program typically includes a core curriculum covering essential business topics such as management, marketing, finance, accounting, economics, operations, and strategic planning. These courses provide students with a solid foundation in fundamental business concepts and principles.",
    questioncontent2: "Elective Specializations",
    answercontent2:
      "Students may have the opportunity to choose elective courses or specializations based on their interests and career goals. Elective options may include areas such as entrepreneurship, human resources, international business, digital marketing, or supply chain management, allowing students to tailor their learning experience to suit their aspirations.",
    questioncontent3: "odl Learning Platform",
    answercontent3:
      " The BBA program utilizes an advanced odl learning platform that offers interactive lectures, multimedia resources, virtual classrooms, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent4: "Flexible Schedule",
    answercontent4:
      "odl BBA programs provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus program.",
    questioncontent5: "Faculty Support",
    answercontent5:
      "Experienced faculty members with expertise in their respective fields facilitate the odl courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or odl chat platforms to address students' questions and concerns.",
    questioncontent6: "Interactive Learning Tools",
    answercontent6:
      "odl BBA programs incorporate interactive learning tools and technologies to enhance the learning experience. These may include virtual simulations, case studies, video lectures, quizzes, and multimedia presentations, allowing students to apply theoretical concepts to real-world scenarios and develop practical skills.",
    questioncontent7: "Capstone Projects or Internships",
    answercontent7:
      " Many odl BBA programs culminate in a capstone project or internship experience, where students can apply their knowledge and skills to solve real-world business challenges or gain hands-on experience in a professional setting. These practical experiences help students integrate classroom learning with practical application and prepare them for entry into the workforce.",
    specialization1: "General",
    specialization2: "Aviation",
    specialization3: "Enterprise risk",
    specialization4: "Digital marketing",
    specialization5: "Retail",
    specialization6: "Fintech",
    specialization7: "Travel & Tourism",
    specialization8: "International Finance",
    growthandcareer:
      "Growth and Career Opportunities for Bachelor of Business Administration (BBA) Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "BBA graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " BBA graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, BBA graduates are well-positioned to start their ventures. The program equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the BBA program makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "BBA serves as a stepping stone for advanced education. Graduates can pursue Master of Business Administration (BBA) programs or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, BBA graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the program instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "BBA programs often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. BBA graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Bachelor of Business Administration provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, BBA graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",

    whyonline: "WHY CHOOSE ODL?",
    whyonline1:
      "The odl program gives you the flexibility to study from the location& time of your choice. This means odl learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, odl lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The odl learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing odl degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An odl degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus programs. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of BBA Programs",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: odl programs eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: odl programs often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus programs.",
    benefits4:
      "Career Advancement: Graduates of BBA programs are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  {
    id: 7,
    partnersname: "VGU",
    title: "BA (ODL Distance)",
    coursename: "BA Course (ODL Distance)",
    coursepageimg: require("../../assets/baodl.png"),
    
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "3-6 Years",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle: "BA Course (ODL Distance) Overview",
    courseDesc:
      "The BA Course offers students a wide range of academic disciplines to explore, allowing them to tailor their education to their interests and career goals. Through a flexible and accessible odl platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The BA program equips graduates with critical thinking skills, cultural awareness, communication abilities, and a strong foundation in their chosen field of study.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through odl education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Business processing outsourcing units",
    scopeandcareer2: "Broadcasting & media",
    scopeandcareer3: "Advertising & PR Agencies",
    scopeandcareer4: "Journalism",
    scopeandcareer5: "Community services",
    courseRequirementchild: "",
    questioncontent1: "Core Curriculum:",
    answercontent1:
      "The BA program typically includes a core curriculum covering foundational courses in humanities, social sciences, and liberal arts. These courses provide students with a broad understanding of various academic disciplines and foster interdisciplinary thinking.",
    questioncontent2: "Major Concentration:",
    answercontent2:
      "Students have the opportunity to choose a major concentration or area of specialization based on their interests and career aspirations. Popular majors include English literature, history, psychology, sociology, political science, economics, anthropology, philosophy, and communication studies.",
    questioncontent3: "Elective Courses:",
    answercontent3:
      " In addition to major requirements, students can select elective courses from a diverse range of subjects to complement their studies and broaden their academic horizons. Elective options may include foreign languages, fine arts, environmental studies, gender studies, global studies, and more.",
    questioncontent4: "odl Learning Platform:",
    answercontent4:
      "BA programs utilize advanced odl learning platforms that offer interactive lectures, multimedia resources, virtual classrooms, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent5: "Flexible Schedule:",
    answercontent5:
      "odl BA programs provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus program.",
    questioncontent6: "Faculty Support:",
    answercontent6:
      "Experienced faculty members with expertise in their respective fields facilitate the odl courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or odl chat platforms to address students questions and concerns.",
    questioncontent7: "Interactive Learning Tools:",
    answercontent7:
      " odl BA programs incorporate interactive learning tools and technologies to enhance the learning experience. These may include virtual discussions, multimedia presentations, case studies, simulations, quizzes, and collaborative projects, allowing students to engage actively with course materials and apply theoretical concepts to real-world scenarios.",
    questioncontent8: "Capstone Project or Thesis: ",
    answercontent8:
      "Many odl BA programs culminate in a capstone project, thesis, or research paper where students can demonstrate their understanding of the subject matter and conduct independent research under the guidance of faculty mentors. This culminating experience allows students to showcase their analytical abilities, critical thinking skills, and academic achievements.",
    specialization1: "Banking",
    specialization2: "Human Resource",
    specialization3: "Finance",
    specialization4: "Marketing",
    courseRequirementchild: "",
    growthandcareer:
      "Growth and Career Opportunities for Bachelor of Arts (BA) Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "BA graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " BA graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, BA graduates are well-positioned to start their ventures. The program equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the BA program makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "BA serves as a stepping stone for advanced education. Graduates can pursue Bachelor of Arts (BA) programs or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, BA graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the program instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "BA programs often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. BA graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Bachelor of Arts provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, BA graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
    whyonline: "WHY CHOOSE ODL?",
    whyonline1:
      "The odl program gives you the flexibility to study from the location& time of your choice. This means odl learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, odl lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The odl learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing odl degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An odl degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus programs. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of BA Programs",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: odl programs eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: odl programs often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus programs.",
    benefits4:
      "Career Advancement: Graduates of BA programs are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  {
    id: 8,
    partnersname: "VGU",
    title: "BCA (ODL Distance)",
    coursename: "BCA Course (ODL Distance)",
    coursepageimg: require("../../assets/bcaodl.png"),
    
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "3-6 Years",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle: "BCA Course (ODL Distance) Overview",
    courseDesc:
      "The BCA  program offers students a wide range of academic disciplines to explore, allowing them to tailor their education to their interests and career goals. Through a flexible and accessible odl platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The BA program equips graduates with critical thinking skills, cultural awareness, communication abilities, and a strong foundation in their chosen field of study.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through odl education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareer1: "Computer Programmer",
    scopeandcareer2: " Software  Developer",
    scopeandcareer3: "System Analyst",
    scopeandcareer4: "   Network Engineer",
    scopeandcareer5: " Software Architect",
    scopeandcareer5: " Software Tester",
    questioncontent1: "Core Computer Science Curriculum:",
    answercontent1:
      " The BCA program typically includes a core curriculum covering fundamental computer science topics such as computer programming, data structures, algorithms, database management systems, computer networks, software engineering, and web technologies.",
    questioncontent2: "Programming Languages:",
    answercontent2:
      " Students learn programming languages such as Java, C++, Python, and JavaScript, enabling them to develop software applications, algorithms, and solutions to real-world problems.Major Concentration",
    questioncontent3: "Software Development: ",
    answercontent3:
      " The program focuses on software development methodologies, techniques, and tools used in the design, development, testing, and maintenance of software systems and applications.",
    questioncontent4: "Information Technology Concepts: ",
    answercontent4:
      "Students gain an understanding of information technology concepts such as cybersecurity, cloud computing, data analytics, artificial intelligence, machine learning, and Internet of Things (IoT).",
    questioncontent5: "Elective Specializations: ",
    answercontent5:
      "Some BCA programs offer elective courses or specializations in specific areas of interest such as cybersecurity, data science, web development, mobile application development, game development, or digital marketing.",
    questioncontent6: "odl Learning Platform: ",
    answercontent6:
      "BCA programs utilize advanced odl learning platforms that offer interactive lectures, multimedia resources, virtual labs, coding exercises, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent7: "Flexible Schedule: ",
    answercontent7:
      "odl BCA programs provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus program.",
    questioncontent8: "Faculty Support: ",
    answercontent8:
      "Experienced faculty members with expertise in computer science and information technology facilitate the odl courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or odl chat platforms to address students; questions and concerns.",
    questioncontent8: "Capstone Project or Internship: ",
    answercontent8:
      " Many odl BCA programs culminate in a capstone project or internship experience, where students can apply their knowledge and skills to solve real-world problems, contribute to ongoing projects, or gain hands-on experience in a professional setting. These practical experiences help students integrate classroom learning with practical application and prepare them for entry into the workforce.",
    specialization1: "UX Design",
    specialization2: "Data Science",
    specialization3: "Robotics Process Automation",
    specialization4: "Cloud Technology & Information Security",
    specialization4: "Block Chain",
    specialization4: "Artificial Intelligence & Machine Learning",
    courseRequirementchild: "",
    growthandcareer:
      "Growth and Career Opportunities for Bachelor of Computer Application (BCA) Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "BCA graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " BCA graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, BCA graduates are well-positioned to start their ventures. The program equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the BCA program makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "BCA serves as a stepping stone for advanced education. Graduates can pursue Bachelor of Computer Application (BCA) programs or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, BCA graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the program instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "BCA programs often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. BCA graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Bachelor of Computer Application provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, BCA graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
    whyonline: "WHY CHOOSE ODL?",
    whyonline1:
      "The odl program gives you the flexibility to study from the location& time of your choice. This means odl learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, odl lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The odl learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing odl degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An odl degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus programs. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of BCA Programs",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: odl programs eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: odl programs often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus programs.",
    benefits4:
      "Career Advancement: Graduates of BCA programs are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  // {
  //   id: 10,
  //   partnersname: "VGU",
  //   title: "BA Honours (ODL Distance)",
  //   coursename: "BA Honours Course (ODL Distance)",
    // coursepageimg: require("../../assets/bba.jpg"),
  //   
  // univers: "University",
  //   universCount: "10",
  //   duration: "Duration",
  //   durationYear: "3-6 Years",
  //   eligibility: "Eligibility",
  //   qualif: "12th Passout",
  //   courseTitle: "BA Honours (ODL Distance) Overview",
  //   courseDesc: "The BA Honours  program offers students a wide range of academic disciplines to explore, allowing them to tailor their education to their interests and career goals. Through a flexible and accessible odl platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The BA program equips graduates with critical thinking skills, cultural awareness, communication abilities, and a strong foundation in their chosen field of study.",
  //   componentsTitle: "Key Components",
  //   enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
  //   enlightendesc: "Through odl education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
  //   scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
  //   scopeandcareer1: "Business processing outsourcing units",
  //   scopeandcareer2: "Broadcasting & media",
  //   scopeandcareer3: "Advertising & PR Agencies",
  //   scopeandcareer4: "Journalism",
  //   scopeandcareer5: "Community services",
  //   courseRequirementchild: "",
  //   questioncontent1: "Core Curriculum:",
  //   answercontent1: "The BA program typically includes a core curriculum covering foundational courses in humanities, social sciences, and liberal arts. These courses provide students with a broad understanding of various academic disciplines and foster interdisciplinary thinking.",
  //   questioncontent2: "Major Concentration:",
  //   answercontent2: "Students have the opportunity to choose a major concentration or area of specialization based on their interests and career aspirations. Popular majors include English literature, history, psychology, sociology, political science, economics, anthropology, philosophy, and communication studies.",
  //   questioncontent3: "Elective Courses:",
  //   answercontent3: " In addition to major requirements, students can select elective courses from a diverse range of subjects to complement their studies and broaden their academic horizons. Elective options may include foreign languages, fine arts, environmental studies, gender studies, global studies, and more.",
  //   questioncontent4: "odl Learning Platform:",
  //   answercontent4: "BA programs utilize advanced odl learning platforms that offer interactive lectures, multimedia resources, virtual classrooms, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
  //   questioncontent5: "Flexible Schedule:",
  //   answercontent5: "odl BA programs provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus program.",
  //   questioncontent6: "Faculty Support:",
  //   answercontent6: "Experienced faculty members with expertise in their respective fields facilitate the odl courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or odl chat platforms to address students questions and concerns.",
  //   questioncontent7: "Interactive Learning Tools:",
  //   answercontent7: " odl BA programs incorporate interactive learning tools and technologies to enhance the learning experience. These may include virtual discussions, multimedia presentations, case studies, simulations, quizzes, and collaborative projects, allowing students to engage actively with course materials and apply theoretical concepts to real-world scenarios.",
  //   questioncontent8: "Capstone Project or Thesis: ",
  //   answercontent8: "Many odl BA programs culminate in a capstone project, thesis, or research paper where students can demonstrate their understanding of the subject matter and conduct independent research under the guidance of faculty mentors. This culminating experience allows students to showcase their analytical abilities, critical thinking skills, and academic achievements.",
  //   specialization1: "Banking",
  //   specialization2: "Human Resource",
  //   specialization3: "Finance",
  //   specialization4: "Marketing",
  //   courseRequirementchild: "",
  //   growthandcareer: "Growth and Career Opportunities for Bachelor of Arts (BA) Graduates:",
  //   growthandcareertitle1: "Diverse Career Paths:",
  //   growthandcareerdesc1: "BA graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
  //   growthandcareertitle2: "Management Roles:",
  //   growthandcareerdesc2: " BA graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
  //   growthandcareertitle3: "Entrepreneurship:",
  //   growthandcareerdesc3: "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, BA graduates are well-positioned to start their ventures. The program equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
  //   growthandcareertitle4: "Specialized Roles:",
  //   growthandcareerdesc4: "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
  //   growthandcareertitle5: "Global Opportunities:",
  //   growthandcareerdesc5: "The global perspective gained through the BA program makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
  //   growthandcareertitle6: "Advanced Education:",
  //   growthandcareerdesc6: "BA serves as a stepping stone for advanced education. Graduates can pursue Bachelor of Arts (BA) programs or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
  //   growthandcareertitle7: "Leadership Roles:",
  //   growthandcareerdesc7: "With a focus on leadership development, BA graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the program instills the skills necessary for effective leadership in diverse business settings.",
  //   growthandcareertitle8: "Networking Opportunities:",
  //   growthandcareerdesc8: "BA programs often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
  //   growthandcareertitle9: "Continuous Learning:",
  //   growthandcareerdesc9: "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. BA graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
  //   growthandcareerdesc10: "The Bachelor of Arts provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, BA graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
  //   whyonline: "WHY CHOOSE ODL?",
  //   whyonline1: "The odl program gives you the flexibility to study from the location& time of your choice. This means odl learning offers students the accessibility of time and place in education.",
  //   whyonline2: "Additionally, odl lectures can be recorded, archived, and shared for future reference.",
  //   whyonline3: "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
  //   whyonline4: "The odl learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
  //   whyonline5: "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
  //   whyonline6: "One can do a job while pursuing odl degrees; basically it is for working professionals or for students preparing for competitive examinations.",
  //   whyonline7: "An odl degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus programs. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
  //   benefits: "Benefits of BA Honours Programs",
  //   benefits1: "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
  //   benefits2: "Accessibility: odl programs eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
  //   benefits3: "Cost-effectiveness: odl programs often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus programs.",
  //   benefits4: "Career Advancement: Graduates of BA Honours programs are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  // },
];
const Teamdata = [
  {
    id: 1,
    teamimg: require("../../assets/team-1.jpg"),
    teamtitle: "Sandeep",
    qualif: "MSc",
    role: "CEO",
    experience: " 10 Year+ Experience",
    morebutton: "More About",
  },
  {
    id: 2,
    teamimg: require("../../assets/team-1.jpg"),
    teamtitle: "Sandeep",
    qualif: "MSc",
    role: "CEO",
    experience: " 10 Year+ Experience",
    morebutton: "More About",
  },
  {
    id: 3,
    teamimg: require("../../assets/team-1.jpg"),
    teamtitle: "Sandeep",
    qualif: "MSc",
    role: "CEO",
    experience: " 10 Year+ Experience",
    morebutton: "More About",
  },
  {
    id: 4,
    teamimg: require("../../assets/team-1.jpg"),
    teamtitle: "Sandeep",
    qualif: "MSc",
    role: "CEO",
    experience: " 10 Year+ Experience",
    morebutton: "More About",
  },
  {
    id: 5,
    teamimg: require("../../assets/team-1.jpg"),
    teamtitle: "Sandeep",
    qualif: "MSc",
    role: "CEO",
    experience: " 10 Year+ Experience",
    morebutton: "More About",
  },
];
const Partnersdata = [
  {
    id: 1,
    partnersname: "LPU Online",
    // partnerstitle: "22 Courses",
    partnersimg: require("../../assets/partner/lpu-logo.png"),
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
    sidepara:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
    paraImage_one: "/images/singlepage/single-1.jpg",
    paraImage_two: "/images/singlepage/single-2.jpg",
  },
  {
    id: 2,
    partnersname: "Manipal University Online",
    // partnerstitle: "22 Courses",
    partnersimg: require("../../assets/partner/logo.png"),
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
    sidepara:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  },
  {
    id: 3,
    partnersname: "GLA Online",
    // partnerstitle: "22 Courses",
    partnersimg: require("../../assets/partner/GLA-U-logo-1024x432.jpg"),
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
    sidepara:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  },
  {
    id: 4,
    partnersname: "Jain University Online",
    // partnerstitle: "22 Courses",
    partnersimg: require("../../assets/jain_university.png"),
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
    sidepara:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  },
  {
    id: 5,
    partnersname: "AMITY Online",
    // partnerstitle: "22 Courses",
    partnersimg: require("../../assets/partner/amity.png"),
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
    sidepara:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  },
  {
    id: 6,
    partnersimg: require("../../assets/partner/dpulogo.png"),
    partnersname: "DPU Online",
    // partnerstitle: "22 Courses",
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
    sidepara:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  },
  {
    id: 7,
    partnersimg: require("../../assets/partner/suresh-gyan-vihar-logo.png"),
    partnersname: "SGVU ",
    // partnerstitle: "22 Courses",
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
    sidepara:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  },
  {
    id: 8,
    partnersimg: require("../../assets/partner/smu.png"),
    partnersname: "SMU Online",
    // partnerstitle: "22 Courses",
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
    sidepara:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  },
  {
    id: 9,
    partnersimg: require("../../assets/partner/chandigarh-university-logo.png"),
    partnersname: "CU Online",
    // partnerstitle: "22 Courses",
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
    sidepara:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  },
  {
    id: 10,
    partnersimg: require("../../assets/partner/vgu_logo.jpg"),
    partnersname: "VGU Online",
    // partnerstitle: "22 Courses",
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
    sidepara:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  },
  {
    id: 11,
    partnersimg: require("../../assets/partner/parullogo.png"),
    partnersname: "Parul Online",
    // partnerstitle: "22 Courses",
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
    sidepara:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  },
  {
    id: 12,
    partnersimg: require("../../assets/partner/maharishi.png"),
    partnersname: "MAHARISI Online",
    // partnerstitle: "22 Courses",
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
    sidepara:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  },
  {
    id: 13,
    partnersimg: require("../../assets/partner/jnu-logo1.png"),
    partnersname: "JNU",
    // partnerstitle: "22 Courses",
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
    sidepara:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  },
  {
    id: 14,
    partnersimg: require("../../assets/partner/jecrc--university1.png"),
    partnersname: "JECRC",
    // partnerstitle: "22 Courses",
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
    sidepara:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  },
  {
    id: 15,
    partnersimg: require("../../assets/partner/uttaranchal-logo.png"),
    partnersname: "UTTRANCHAL Online",
    // partnerstitle: "22 Courses",
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
    sidepara:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  },
  // {
  //   id: 16,
  //   partnersimg: require("../../assets/partner/logo.png"),
  //   partnersname: "MANIPAL Online",
  // partnerstitle: "22 Courses",
  //   desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
  //   sidepara:
  //     "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  // },
];

const TestimonialData = [
  {
    id: "1",
    studentname: "David Johnson",
    testiImg: require("../../assets/testimonial/quotes.png"),
    reviews:
      "Thanks to our marketplace model, our content keeps pace with market changes. You’ll find courses on the latest technologies and business practice and more!",
    role: "Student",
  },
  {
    id: "2",
    studentname: "David Johnson",
    testiImg: require("../../assets/testimonial/quotes.png"),
    reviews:
      "Thanks to our marketplace model, our content keeps pace with market changes. You’ll find courses on the latest technologies and business practice and more!",
    role: "Student",
  },
  {
    id: "3",
    studentname: "David Johnson",
    testiImg: require("../../assets/testimonial/quotes.png"),
    reviews:
      "Thanks to our marketplace model, our content keeps pace with market changes. You’ll find courses on the latest technologies and business practice and more!",
    role: "Student",
  },
  {
    id: "4",
    studentname: "David Johnson",
    testiImg: require("../../assets/testimonial/quotes.png"),
    reviews:
      "Thanks to our marketplace model, our content keeps pace with market changes. You’ll find courses on the latest technologies and business practice and more!",
    role: "Student",
  },
  {
    id: "5",
    studentname: "David Johnson",
    testiImg: require("../../assets/testimonial/quotes.png"),
    reviews:
      "Thanks to our marketplace model, our content keeps pace with market changes. You’ll find courses on the latest technologies and business practice and more!",
    role: "Student",
  },
];

const requirementData1 = [
  {
    id: "1",
    specialize: "General",
  },
  {
    id: "2",
    specialize: "Aviation",
  },
  {
    id: "3",
    specialize: "Enterprise risk",
  },
  {
    id: "4",
    specialize: "Digital marketing",
  },
  {
    id: "5",
    specialize: "Retail",
  },
  {
    id: "6",
    specialize: "Fintech",
  },
  {
    id: "7",
    specialize: "Travel & Tourism",
  },
  {
    id: "8",
    specialize: "International Finance",
  },
];

const requirementData2 = [
  {
    id: "1",
    specialize: "Marketing Executive",
  },
  {
    id: "2",
    specialize: "Business development executive",
  },
  {
    id: "3",
    specialize: "Executive Assistant",
  },
  {
    id: "4",
    specialize: "Brand manager",
  },
  {
    id: "5",
    specialize: "Event manager",
  },
  {
    id: "6",
    specialize: "Account manager",
  },
  {
    id: "7",
    specialize: "Travel and tourism manager",
  },
  {
    id: "8",
    specialize: "Field sales executive",
  },
  {
    id: "9",
    specialize: "Financial analyst",
  },
  {
    id: "10",
    specialize: "Operation analyst",
  },
  {
    id: "11",
    specialize: "Human resource manager",
  },
];

const requirementData = [
  {
    id: "1",
    specialize:
      "The online program gives you the flexibility to study from the location& time of your choice. This means online learning offers students the accessibility of time and place in education.",
  },
  {
    id: "2",
    specialize: "Business development executive",
  },
  {
    id: "3",
    specialize: "Executive Assistant",
  },
  {
    id: "4",
    specialize: "Brand manager",
  },
  {
    id: "5",
    specialize: "Event manager",
  },
  {
    id: "6",
    specialize: "Account manager",
  },
  {
    id: "7",
    specialize: "Travel and tourism manager",
  },
  {
    id: "8",
    specialize: "Field sales executive",
  },
  {
    id: "9",
    specialize: "Financial analyst",
  },
  {
    id: "10",
    specialize: "Operation analyst",
  },
  {
    id: "11",
    specialize: "Human resource manager",
  },
];
const Featuredata = [
  {
    id: "1",
    // featureImg: require("../../assets/category-1.svg"),
    featureTitle: "Diverse Courses and Programs",
    featureDescrip1:
      "Experience the Kaleidoscope of Learning: Diverse Courses and Programs Await You in the World of Online Education in India!;",
    featureDescrip2:
      "In the vibrant landscape of online education in India, theres an abundance of courses and programs waiting to be explored, each offering a unique opportunity for growth and discovery. Heres what makes this diversity so enticing:",
    featureDescrip3:
      "Endless Possibilities: From the majestic realms of mathematics to the enchanting realms of literature, online education in India offers a treasure trove of academic courses spanning every subject imaginable. Dive into a world of knowledge where the boundaries of learning are limitless.",
    featureDescrip4:
      "Unlock Your Potential: Whether youre aspiring to conquer the corporate jungle or unleash your creative genius, online platforms in India host an array of professional and vocational courses designed to equip you with the skills and expertise needed to thrive in todays dynamic world. Embark on a journey of self-discovery and transformation.",
    featureDescrip5:
      "Craft Your Destiny: Harness the power of skill development initiatives tailored to Indias burgeoning economy and entrepreneurial spirit. Explore specialized courses in emerging fields like digital marketing, data science, and e-commerce, and carve your path to success in the ever-evolving job market.",
    featureDescrip6:
      "Government-backed Empowerment: Take advantage of government-backed initiatives aimed at bridging the digital divide and empowering learners from all backgrounds. With programs like NDLM and PMKVY, education becomes not just a privilege but a fundamental right accessible to every Indian citizen.",
    featureDescrip7:
      "Tailored to You: Experience the freedom of personalized learning paths, where every course is curated to match your interests, aspirations, and learning style. With interactive content, adaptive learning algorithms, and virtual labs, education becomes an immersive and engaging adventure.",
    featureDescrip8:
      "Embrace the diversity of online education in India and embark on a journey of lifelong learning, empowerment, and success. Your future awaits, and the possibilities are boundless!",
  },
  {
    id: "2",
    // featureImg: require("../../assets/category-2.svg"),
    featureTitle: "Personalized Educational Experiences",
    featureDescrip1:
      "Enter the Realm of Personalized Educational Experiences: Explore Online Education in India Like Never Before!",
    featureDescrip2:
      "In the dynamic world of online education in India, personalized learning takes center stage, offering a transformative educational journey tailored just for you. Heres why personalized educational experiences are the key to unlocking your full potential:",
    featureDescrip3:
      "Your Learning, Your Way: Say goodbye to one-size-fits-all education! Online platforms in India empower you to customize your learning journey according to your interests, goals, and pace. Whether youre a visual learner, an auditory learner, or a hands-on learner, theres a personalized approach waiting for you.",
    featureDescrip4:
      "Unleash Your Passion: Dive deep into subjects that ignite your curiosity and passion. With a vast array of courses spanning diverse fields such as technology, business, arts, and sciences, online education in India allows you to explore your interests and pursue your passions like never before.",
    featureDescrip5:
      "Flexible and Convenient: Bid farewell to rigid schedules and long commutes. With online education, you have the flexibility to learn anytime, anywhere, fitting your studies seamlessly into your busy life. Whether youre a working professional, a busy parent, or a lifelong learner, online learning adapts to your schedule, not the other way around.",
    featureDescrip6:
      "Guided by Experts: Benefit from personalized guidance and mentorship from industry experts and experienced educators. Online courses in India are curated and led by renowned faculty members from top universities and institutions, ensuring that you receive the highest quality education and mentorship every step of the way.",
    featureDescrip7:
      "Real-world Application: Experience hands-on learning with practical, real-world applications. From virtual labs to industry projects, online education in India bridges the gap between theory and practice, equipping you with the skills and knowledge needed to excel in your chosen field.",
    featureDescrip8:
      "Continuous Growth: Embrace a culture of continuous growth and lifelong learning. With personalized learning paths, adaptive assessments, and self-paced modules, online education in India empowers you to evolve and upskill at your own rhythm, ensuring that your educational journey is as dynamic and ever-evolving as you are.",
    featureDescrip8:
      "Embark on a personalized educational journey with online education in India and unlock a world of endless possibilities. Let your curiosity lead the way, and watch as your dreams transform into reality. Your personalized educational experience awaits—seize it today!",
  },
  {
    id: "3",
    // featureImg: require("../../assets/category-4.svg"),
    featureTitle: "Affordability and Accessibility",
    featureDescrip1:
      "Experience the Power of Affordability and Accessibility: Transform Your Education with Online Learning in India!",
    featureDescrip2:
      "In the vibrant landscape of online education in India, affordability and accessibility are not just buzzwords—theyre the driving forces behind a revolution in learning. Heres how these key pillars are reshaping the educational landscape and opening doors to a world of opportunities:",
    featureDescrip3:
      "Affordability Redefined: Say goodbye to hefty tuition fees and exorbitant expenses. Online education in India offers a cost-effective alternative to traditional brick-and-mortar institutions, making quality education accessible to learners from all walks of life. With affordable course fees, no commuting costs, and the ability to study from the comfort of your home, online learning puts education within reach like never before.",
    featureDescrip4:
      "Accessibility for All: No matter where you are or what your circumstances may be, online education in India welcomes you with open arms. Whether you reside in bustling cities or remote villages, access to quality education is no longer a distant dream but a tangible reality. With just a computer and an internet connection, you can embark on a journey of learning and growth, breaking down geographical barriers and leveling the playing field for all learners.",
    featureDescrip5:
      "Flexible Learning Options: Tailor your education to fit your lifestyle and schedule. With online courses in India, you have the freedom to learn at your own pace, on your own terms. Whether youre a full-time professional, a busy parent, or a student juggling multiple responsibilities, online education offers flexible learning options that adapt to your needs, allowing you to balance your studies with work, family, and other commitments seamlessly.",
    featureDescrip6:
      "Opportunities Galore: Explore a diverse range of courses and programs that cater to every interest and aspiration. From academic subjects to professional certifications, online platforms in India offer an extensive selection of courses spanning various disciplines and industries. Whether youre looking to advance your career, pursue a passion, or acquire new skills, the opportunities are endless in the world of online education.",
    featureDescrip7:
      "Empowering the Future: Online education in India isnt just about acquiring knowledge—its about empowering individuals to shape their own destinies. By making education more affordable and accessible, online learning paves the way for a brighter future, where anyone with a thirst for knowledge and a drive to succeed can fulfill their dreams and aspirations.",
    featureDescrip8:
      "Experience the transformative power of affordability and accessibility with online education in India. Step into a world where learning knows no bounds and the possibilities are limitless. Your journey to a brighter tomorrow begins here—seize it with online learning today!",
  },
];
const Specializedata = [
  {
    id: "1",
    specialization: "General",
  },
  {
    id: "2",
    specialization: "Aviation",
  },
  {
    id: "3",
    specialization: "Enterprise risk",
  },
  {
    id: "4",
    specialization: "Digital marketing",
  },
  {
    id: "5",
    specialization: "Retail",
  },
  {
    id: "6",
    specialization: "Fintech",
  },
  {
    id: "7",
    specialization: "Travel & Tourism",
  },
  {
    id: "8",
    specialization: "International Finance",
  },
];
const carouselimgData = [
  {
    id: "1",
    carouselImg: require("../../assets/team-1.jpg"),
  },
];
const CollegecoursesData = [
  {
    id: "1",
    coursesimage: require("../../assets/team-1.jpg"),
    coursestitle: "JAIN UNIVERSITY ONLINE",
    coursesname: "ONLINE M.COM",
    coursesdescription: "Right Choice for Education",
    coursescardbutton: "Read More",
  },
  {
    id: "2",
    coursesimage: require("../../assets/team-1.jpg"),
    coursestitle: "JAIN UNIVERSITY ONLINE",
    coursesname: "ONLINE M.COM",
    coursesdescription: "Right Choice for Education",
    coursescardbutton: "Read More",
  },
  {
    id: "3",
    coursesimage: require("../../assets/team-1.jpg"),
    coursestitle: "JAIN UNIVERSITY ONLINE",
    coursesname: "ONLINE M.COM",
    coursesdescription: "Right Choice for Education",
    coursescardbutton: "Read More",
  },
  {
    id: "4",
    coursesimage: require("../../assets/team-1.jpg"),
    coursestitle: "JAIN UNIVERSITY ONLINE",
    coursesname: "ONLINE M.COM",
    coursesdescription: "Right Choice for Education",
    coursescardbutton: "Read More",
  },
  {
    id: "5",
    coursesimage: require("../../assets/team-1.jpg"),
    coursestitle: "JAIN UNIVERSITY ONLINE",
    coursesname: "ONLINE M.COM",
    coursesdescription: "Right Choice for Education",
    coursescardbutton: "Read More",
  },
  {
    id: "6",
    coursesimage: require("../../assets/team-1.jpg"),
    coursestitle: "JAIN UNIVERSITY ONLINE",
    coursesname: "ONLINE M.COM",
    coursesdescription: "Right Choice for Education",
    coursescardbutton: "Read More",
  },
];
export default Sdata1;
export {
  Teamdata,
  Partnersdata,
  TestimonialData,
  requirementData2,
  Featuredata,
  CollegecoursesData,
  Specializedata,
  carouselimgData,
};
