const Sdata2 = [
  {
    id: 1,
    title: "Online MBA",
    coursename: "Online Master of Business Administration (MBA) Course",
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "1-2 Years",
    coursepageimg: require("../../assets/mba.jpg"),
    admissionprocedure: require("../../assets/admissionprocedure.jpeg"),

    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle:
      "Online Master of Business Administration (MBA) Course Overview",
    courseDesc:
      "The Online Master of Business Administration (MBA) Course is designed to provide students with a comprehensive understanding of commerce, business principles, accounting, finance, economics, and management. Through a flexible and accessible online platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The Course equips graduates with the knowledge and skills necessary to excel in various roles in the field of commerce, finance, and business.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through online education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Marketing Executive",
    scopeandcareer2: "Business development executive",
    scopeandcareer3: "Executive Assistant",
    scopeandcareer4: "Brand manager",
    scopeandcareer5: "Event manager",
    scopeandcareer6: "Account manager",
    scopeandcareer7: "Travel and tourism manager",
    scopeandcareer8: "Field sales executive",
    scopeandcareer9: "Financial analyst",
    scopeandcareer10: "Operation analyst",
    scopeandcareer11: "Human resource manager",
    questioncontent1: "Core Business Curriculum",
    answercontent1:
      "The Course typically includes a core curriculum covering essential business topics such as management, marketing, finance, accounting, economics, operations, and strategic planning. These courses provide students with a solid foundation in fundamental business concepts and principles.",
    questioncontent2: "Elective Specializations",
    answercontent2:
      "Students may have the opportunity to choose elective courses or specializations based on their interests and career goals. Elective options may include areas such as entrepreneurship, human resources, international business, digital marketing, or supply chain management, allowing students to tailor their learning experience to suit their aspirations.",
    questioncontent3: "Online Learning Platform",
    answercontent3:
      " The MBA Course utilizes an advanced online learning platform that offers interactive lectures, multimedia resources, virtual classrooms, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent4: "Flexible Schedule",
    answercontent4:
      "Online MBA Courses provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus Course.",
    questioncontent5: "Faculty Support",
    answercontent5:
      "Experienced faculty members with expertise in their respective fields facilitate the online courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or online chat platforms to address students' questions and concerns.",
    questioncontent6: "Interactive Learning Tools",
    answercontent6:
      "Online MBA Courses incorporate interactive learning tools and technologies to enhance the learning experience. These may include virtual simulations, case studies, video lectures, quizzes, and multimedia presentations, allowing students to apply theoretical concepts to real-world scenarios and develop practical skills.",
    questioncontent7: "Capstone Projects or Internships",
    answercontent7:
      " Many online MBA Courses culminate in a capstone project or internship experience, where students can apply their knowledge and skills to solve real-world business challenges or gain hands-on experience in a professional setting. These practical experiences help students integrate classroom learning with practical application and prepare them for entry into the workforce.",
    specialization1: "General",
    specialization2: "Aviation",
    specialization3: "Enterprise risk",
    specialization4: "Digital marketing",
    specialization5: "Retail",
    specialization6: "Fintech",
    specialization7: "Travel & Tourism",
    specialization8: "International Finance",
    courseRequirementchild: "",
    growthandcareer:
      "Growth and Career Opportunities for Bachelor of Business Administration (MBA) Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "MBA graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " MBA graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, MBA graduates are well-positioned to start their ventures. The Course equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the MBA Course makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "MBA serves as a stepping stone for advanced education. Graduates can pursue Master of Business Administration (MBA) Courses or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, MBA graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the Course instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "MBA Courses often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. MBA graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Bachelor of Business Administration provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, MBA graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
    whyonline: "WHY CHOOSE Online?",
    whyonline1:
      "The Online Course gives you the flexibility to study from the location& time of your choice. This means online learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, Online lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The online learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing online degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An online degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus Courses. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of Online MBA Courses",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: Online Courses eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: Online Courses often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus Courses.",
    benefits4:
      "Career Advancement: Graduates of online MBA Courses are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  {
    id: 2,
    title: "Online MCOM",
    coursename: "Online Master of Commerce (MCOM) Course",
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "1-2 Years",
    eligibility: "Eligibility",
    coursepageimg: require("../../assets/mcom.jpg"),
    admissionprocedure: require("../../assets/admissionprocedure.jpeg"),

    qualif: "12th Passout",
    courseTitle: "Online Master of Commerce (MCOM) Course Overview",
    courseDesc:
      "The Online Master of Commerce (MCOM) Course is designed to provide students with a comprehensive understanding of commerce, business principles, accounting, finance, economics, and management. Through a flexible and accessible online platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The Course equips graduates with the knowledge and skills necessary to excel in various roles in the field of commerce, finance, and business.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through online education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Diverse Opportunities",
    scopeandcareer2: "Industry Relevance",
    scopeandcareer3: "Specialized Roles",
    scopeandcareer4: "Entrepreneurship",
    scopeandcareer5: "Global Perspective",
    scopeandcareer6: "Networking Opportunities",
    scopeandcareer7: "Continuous Growth",
    scopeandcareer8: "Further Education",
    questioncontent1: "Core Commerce Curriculum:",
    answercontent1:
      "The MCOM Course typically includes a core curriculum covering fundamental commerce topics such as accounting, finance, economics, business law, taxation, marketing, management, and organizational behavior.",
    questioncontent2: "Accounting and Financial Management:",
    answercontent2:
      "Students learn accounting principles, financial statement analysis, budgeting, cost accounting, auditing, and financial management techniques used in business organizations.",
    questioncontent3: "Economics:",
    answercontent3:
      " The Course covers microeconomics and macroeconomics concepts, including supply and demand, market structures, consumer behavior, national income accounting, monetary policy, and international trade.",
    questioncontent4: "Business Law and Taxation:",
    answercontent4:
      "Students gain an understanding of business law principles, contract law, corporate law, commercial law, and taxation laws relevant to business operations.",
    questioncontent5: "Management and Organizational Behavior:",
    answercontent5:
      "The Course focuses on management principles, leadership styles, organizational behavior, human resource management, strategic management, and business ethics.",
    questioncontent6: "Marketing:",
    answercontent6:
      "Students learn marketing concepts, market research, consumer behavior, branding, advertising, promotion, pricing strategies, and digital marketing techniques.",
    questioncontent7: "Finance:",
    answercontent7:
      " The Course covers financial markets, investment analysis, portfolio management, risk management, financial derivatives, and corporate finance principles.",
    questioncontent8: "Elective Specializations:",
    answercontent8:
      " Some MCOM Courses offer elective courses or specializations in specific areas of interest such as financial accounting, management accounting, taxation, banking, insurance, international business, entrepreneurship, or e-commerce.",
    questioncontent9: "Online Learning Platform:",
    answercontent9:
      " MCOM Courses utilize advanced online learning platforms that offer interactive lectures, multimedia resources, virtual classrooms, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent10: "Flexible Schedule:",
    answercontent10:
      " Online MCOM Courses provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus Course.",
    questioncontent11: "Faculty Support: ",
    answercontent11:
      " Experienced faculty members with expertise in commerce, business, finance, and economics facilitate the online courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or online chat platforms to address students&#39; questions and concerns.",
    questioncontent12: "Interactive Learning Tools:",
    answercontent12:
      " Online MCOM Courses incorporate interactive learning tools and technologies to enhance the learning experience. These may include case studies, simulations, quizzes, assignments, and multimedia presentations, allowing students to apply theoretical concepts to real-world scenarios and develop practical skills.",
    questioncontent13: "Capstone Project or Internship:",
    answercontent13:
      " Many online MCOM Courses culminate in a capstone project or internship experience, where students can apply their knowledge and skills to solve real-world problems, gain practical experience, or contribute to ongoing projects in business organizations. These practical experiences help students integrate classroom learning with practical application and prepare them for entry into the workforce.",
    specialization1: "Banking",
    specialization2: "Human Resource",
    specialization3: "Finance",
    specialization4: "Marketing",
    courseRequirementchild: "",
    growthandcareer:
      "Growth and Career Opportunities for Master of Business Administration (MCOM) Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "MCOM graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " MCOM graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, MCOM graduates are well-positioned to start their ventures. The Course equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the MCOM Course makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "MCOM serves as a stepping stone for advanced education. Graduates can pursue Master of Business Administration (MBA) Courses or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, MCOM graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the Course instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "MCOM Courses often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. MCOM graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Master of Business Administration provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, MCOM graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
    whyonline: "WHY CHOOSE Online?",
    whyonline1:
      "The Online Course gives you the flexibility to study from the location& time of your choice. This means online learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, Online lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The online learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing online degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An online degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus Courses. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of Online Master of Commerce (MCOM) Courses",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: Online Courses eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: Online Courses often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus Courses.",
    benefits4:
      "Career Advancement: Graduates of online MCOM Courses are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  {
    id: 3,
    title: "Online MA",
    coursename: "Online Master of Arts (MA) Course",
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "1-2 Years",
    coursepageimg: require("../../assets/ma.jpg"),
    admissionprocedure: require("../../assets/admissionprocedure.jpeg"),

    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle: "Online Master of Arts (MA) Course Overview",
    courseDesc:
      "The Online Master of Arts (MA) Course offers students a wide range of academic disciplines to explore, allowing them to tailor their education to their interests and career goals. Through a flexible and accessible online platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The MA Course equips graduates with critical thinking skills, cultural awareness, communication abilities, and a strong foundation in their chosen field of study.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through online education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Business processing outsourcing units",
    scopeandcareer2: "Broadcasting & media",
    scopeandcareer3: "Advertising & PR Agencies",
    scopeandcareer4: "Journalism",
    scopeandcareer5: "Community services",
    courseRequirementchild: "",
    questioncontent1: "Core Curriculum:",
    answercontent1:
      "The MA Course typically includes a core curriculum covering foundational courses in humanities, social sciences, and liberal arts. These courses provide students with a broad understanding of various academic disciplines and foster interdisciplinary thinking.",
    questioncontent2: "Major Concentration:",
    answercontent2:
      "Students have the opportunity to choose a major concentration or area of specialization based on their interests and career aspirations. Popular majors include English literature, history, psychology, sociology, political science, economics, anthropology, philosophy, and communication studies.",
    questioncontent3: "Elective Courses:",
    answercontent3:
      " In addition to major requirements, students can select elective courses from a diverse range of subjects to complement their studies and broaden their academic horizons. Elective options may include foreign languages, fine arts, environmental studies, gender studies, global studies, and more.",
    questioncontent4: "Online Learning Platform:",
    answercontent4:
      "MA Courses utilize advanced online learning platforms that offer interactive lectures, multimedia resources, virtual classrooms, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent5: "Flexible Schedule:",
    answercontent5:
      "Online MA Courses provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus Course.",
    questioncontent6: "Faculty Support:",
    answercontent6:
      "Experienced faculty members with expertise in their respective fields facilitate the online courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or online chat platforms to address students&#39; questions and concerns.",
    questioncontent7: "Interactive Learning Tools:",
    answercontent7:
      " Online MA Courses incorporate interactive learning tools and technologies to enhance the learning experience. These may include virtual discussions, multimedia presentations, case studies, simulations, quizzes, and collaborative projects, allowing students to engage actively with course materials and apply theoretical concepts to real-world scenarios.",
    questioncontent8: "Capstone Project or Thesis: ",
    answercontent8:
      "Many online MA Courses culminate in a capstone project, thesis, or research paper where students can demonstrate their understanding of the subject matter and conduct independent research under the guidance of faculty mentors. This culminating experience allows students to showcase their analytical abilities, critical thinking skills, and academic achievements.",
    specialization1: "Banking",
    specialization2: "Human Resource",
    specialization3: "Finance",
    specialization4: "Marketing",
    courseRequirementchild: "",
    growthandcareer:
      "Growth and Career Opportunities for Master of Business Administration (MA) Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "MA graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " MA graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, MA graduates are well-positioned to start their ventures. The Course equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the MA Course makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "MA serves as a stepping stone for advanced education. Graduates can pursue Master of Business Administration (MBA) Courses or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, MA graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the Course instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "MA Courses often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. MA graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Master of Business Administration provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, MA graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
    whyonline: "WHY CHOOSE Online?",
    whyonline1:
      "The Online Course gives you the flexibility to study from the location& time of your choice. This means online learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, Online lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The online learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing online degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An online degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus Courses. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of Online MA Courses",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: Online Courses eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: Online Courses often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus Courses.",
    benefits4:
      "Career Advancement: Graduates of online MA Courses are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  // {
  //   id: 4,
  //   title: "Online MA Honours",
  //   coursename: "Online Master of Arts Honours(MA Honours) Course",
  //   univers: "University",
  //   universCount: "10",
  //   duration: "Duration",
  //   durationYear: "1-2 Years",
  //   eligibility: "Eligibility",
  //   qualif: "12th Passout",
  //   courseTitle: "Online Master of Arts Honours(MA Honours) Course Overview",
  //   courseDesc:
  //     "The Online Master of Arts Honours(MA Honours) Course offers students a wide range of academic disciplines to explore, allowing them to tailor their education to their interests and career goals. Through a flexible and accessible online platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The MA Course equips graduates with critical thinking skills, cultural awareness, communication abilities, and a strong foundation in their chosen field of study.",
  //   componentsTitle: "Key Components",
  //   enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
  //   enlightendesc:
  //     "Through online education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
  //   scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
  //   scopeandcareer1: "Business processing outsourcing units",
  //   scopeandcareer2: "Broadcasting & media",
  //   scopeandcareer3: "Advertising & PR Agencies",
  //   scopeandcareer4: "Journalism",
  //   scopeandcareer5: "Community services",
  //   courseRequirementchild: "",
  //   questioncontent1: "Core Curriculum:",
  //   answercontent1:
  //     "The MA Course typically includes a core curriculum covering foundational courses in humanities, social sciences, and liberal arts. These courses provide students with a broad understanding of various academic disciplines and foster interdisciplinary thinking.",
  //   questioncontent2: "Major Concentration:",
  //   answercontent2:
  //     "Students have the opportunity to choose a major concentration or area of specialization based on their interests and career aspirations. Popular majors include English literature, history, psychology, sociology, political science, economics, anthropology, philosophy, and communication studies.",
  //   questioncontent3: "Elective Courses:",
  //   answercontent3:
  //     " In addition to major requirements, students can select elective courses from a diverse range of subjects to complement their studies and broaden their academic horizons. Elective options may include foreign languages, fine arts, environmental studies, gender studies, global studies, and more.",
  //   questioncontent4: "Online Learning Platform:",
  //   answercontent4:
  //     "MA Courses utilize advanced online learning platforms that offer interactive lectures, multimedia resources, virtual classrooms, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
  //   questioncontent5: "Flexible Schedule:",
  //   answercontent5:
  //     "Online MA Courses provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus Course.",
  //   questioncontent6: "Faculty Support:",
  //   answercontent6:
  //     "Experienced faculty members with expertise in their respective fields facilitate the online courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or online chat platforms to address students&#39; questions and concerns.",
  //   questioncontent7: "Interactive Learning Tools:",
  //   answercontent7:
  //     " Online MA Courses incorporate interactive learning tools and technologies to enhance the learning experience. These may include virtual discussions, multimedia presentations, case studies, simulations, quizzes, and collaborative projects, allowing students to engage actively with course materials and apply theoretical concepts to real-world scenarios.",
  //   questioncontent8: "Capstone Project or Thesis: ",
  //   answercontent8:
  //     "Many online MA Courses culminate in a capstone project, thesis, or research paper where students can demonstrate their understanding of the subject matter and conduct independent research under the guidance of faculty mentors. This culminating experience allows students to showcase their analytical abilities, critical thinking skills, and academic achievements.",
  //   specialization1: "Banking",
  //   specialization2: "Human Resource",
  //   specialization3: "Finance",
  //   specialization4: "Marketing",
  //   courseRequirementchild: "",
  //   growthandcareer:
  //     "Growth and Career Opportunities for Master of Business Administration (MA) Graduates:",
  //   growthandcareertitle1: "Diverse Career Paths:",
  //   growthandcareerdesc1:
  //     "MA graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
  //   growthandcareertitle2: "Management Roles:",
  //   growthandcareerdesc2:
  //     " MA graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
  //   growthandcareertitle3: "Entrepreneurship:",
  //   growthandcareerdesc3:
  //     "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, MA graduates are well-positioned to start their ventures. The Course equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
  //   growthandcareertitle4: "Specialized Roles:",
  //   growthandcareerdesc4:
  //     "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
  //   growthandcareertitle5: "Global Opportunities:",
  //   growthandcareerdesc5:
  //     "The global perspective gained through the MA Course makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
  //   growthandcareertitle6: "Advanced Education:",
  //   growthandcareerdesc6:
  //     "MA serves as a stepping stone for advanced education. Graduates can pursue Master of Business Administration (MBA) Courses or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
  //   growthandcareertitle7: "Leadership Roles:",
  //   growthandcareerdesc7:
  //     "With a focus on leadership development, MA graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the Course instills the skills necessary for effective leadership in diverse business settings.",
  //   growthandcareertitle8: "Networking Opportunities:",
  //   growthandcareerdesc8:
  //     "MA Courses often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
  //   growthandcareertitle9: "Continuous Learning:",
  //   growthandcareerdesc9:
  //     "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. MA graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
  //   growthandcareerdesc10:
  //     "The Master of Arts provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, MA graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",

  //   whyonline: "WHY CHOOSE Online?",
  //   whyonline1:
  //     "The Online Course gives you the flexibility to study from the location& time of your choice. This means online learning offers students the accessibility of time and place in education.",
  //   whyonline2:
  //     "Additionally, Online lectures can be recorded, archived, and shared for future reference.",
  //   whyonline3:
  //     "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
  //   whyonline4:
  //     "The online learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
  //   whyonline5:
  //     "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
  //   whyonline6:
  //     "One can do a job while pursuing online degrees; basically it is for working professionals or for students preparing for competitive examinations.",
  //   whyonline7:
  //     "An online degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus Courses. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
  //   benefits: "Benefits of Online MA Honours Courses",
  //   benefits1:
  //     "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
  //   benefits2:
  //     "Accessibility: Online Courses eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
  //   benefits3:
  //     "Cost-effectiveness: Online Courses often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus Courses.",
  //   benefits4:
  //     "Career Advancement: Graduates of online MA Honours Courses are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  // },
  {
    id: 4,
    partnersname: "VGU",
    title: "Online MCA",
    coursename: "Online Master of Computer Applications (MCA) Course Overview",
    univers: "University",
    universCount: "10",
    coursepageimg: require("../../assets/mca.jpg"),
    admissionprocedure: require("../../assets/admissionprocedure.jpeg"),

    duration: "Duration",
    durationYear: "1-2 Years",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle: "Online Master of Computer Applications (MCA) Course",
    courseDesc:
      "The Online Master of Computer Applications (MCA) Course is designed to provide students with a comprehensive understanding of computer science principles, Courseming languages, software development, and information technology concepts. Through a flexible and accessible online platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The Course equips graduates with the knowledge and skills necessary to excel in various roles in the field of computer applications and technology.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through online education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Computer Coursemer",
    scopeandcareer2: " Software  Developer",
    scopeandcareer3: "System Analyst",
    scopeandcareer4: "   Network Engineer",
    scopeandcareer5: " Software Architect",
    scopeandcareer5: " Software Tester",
    questioncontent1: "Core Computer Science Curriculum:",
    answercontent1:
      " The MCA Course typically includes a core curriculum covering fundamental computer science topics such as computer Courseming, data structures, algorithms, database management systems, computer networks, software engineering, and web technologies.",
    questioncontent2: "Courseming Languages:",
    answercontent2:
      " Students learn Courseming languages such as Java, C++, Python, and JavaScript, enabling them to develop software applications, algorithms, and solutions to real-world problems.Major Concentration",
    questioncontent3: "Software Development: ",
    answercontent3:
      " The Course focuses on software development methodologies, techniques, and tools used in the design, development, testing, and maintenance of software systems and applications.",
    questioncontent4: "Information Technology Concepts: ",
    answercontent4:
      "Students gain an understanding of information technology concepts such as cybersecurity, cloud computing, data analytics, artificial intelligence, machine learning, and Internet of Things (IoT).",
    questioncontent5: "Elective Specializations: ",
    answercontent5:
      "Some MCA Courses offer elective courses or specializations in specific areas of interest such as cybersecurity, data science, web development, mobile application development, game development, or digital marketing.",
    questioncontent6: "Online Learning Platform: ",
    answercontent6:
      "MCA Courses utilize advanced online learning platforms that offer interactive lectures, multimedia resources, virtual labs, coding exercises, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent7: "Flexible Schedule: ",
    answercontent7:
      "Online MCA Courses provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus Course.",
    questioncontent8: "Faculty Support: ",
    answercontent8:
      "Experienced faculty members with expertise in computer science and information technology facilitate the online courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or online chat platforms to address students; questions and concerns.",
    questioncontent8: "Capstone Project or Internship: ",
    answercontent8:
      " Many online MCA Courses culminate in a capstone project or internship experience, where students can apply their knowledge and skills to solve real-world problems, contribute to ongoing projects, or gain hands-on experience in a professional setting. These practical experiences help students integrate classroom learning with practical application and prepare them for entry into the workforce.",
    specialization1: "UX Design",
    specialization2: "Data Science",
    specialization3: "Robotics Process Automation",
    specialization4: "Cloud Technology & Information Security",
    specialization4: "Block Chain",
    specialization4: "Artificial Intelligence & Machine Learning",
    courseRequirementchild: "",
    growthandcareer:
      "Growth and Career Opportunities for Master of Computer Application (MCA) Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "MCA graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " MCA graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, MCA graduates are well-positioned to start their ventures. The Course equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the MCA Course makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "MCA serves as a stepping stone for advanced education. Graduates can pursue Master of Computer Application (MCA) Courses or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, MCA graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the Course instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "MCA Courses often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. MCA graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Master of Computer Application provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, MCA graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
    whyonline: "WHY CHOOSE Online?",
    whyonline1:
      "The Online Course gives you the flexibility to study from the location& time of your choice. This means online learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, Online lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The online learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing online degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An online degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus Courses. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of Online MCA Courses",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: Online Courses eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: Online Courses often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus Courses.",
    benefits4:
      "Career Advancement: Graduates of Online MCA Courses are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  {
    id: 5,
    title: "Online MSC",
    coursename: "Online Master of Science (MSC) Course",
    univers: "University",
    universCount: "10",
    duration: "Duration",
    coursepageimg: require("../../assets/msc.jpg"),
    admissionprocedure: require("../../assets/admissionprocedure.jpeg"),

    durationYear: "1-2 Years",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle: "Online Master of Science (MSC) Course Overview",
    courseDesc:
      "The Online Master of Science (MSC) Course is designed to provide students with a comprehensive understanding of computer science principles, Courseming languages, software development, and information technology concepts. Through a flexible and accessible online platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The Course equips graduates with the knowledge and skills necessary to excel in various roles in the field of Science and technology.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through online education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Computer Coursemer",
    scopeandcareer2: " Software  Developer",
    scopeandcareer3: "System Analyst",
    scopeandcareer4: "   Network Engineer",
    scopeandcareer5: " Software Architect",
    scopeandcareer5: " Software Tester",
    questioncontent1: "Core Curriculum:",
    answercontent1:
      " The MSc Course typically includes a core curriculum covering foundational concepts, theories, and methodologies in the chosen scientific discipline or field of study. This core curriculum provides students with a comprehensive understanding of the subject matter and prepares them for advanced coursework and research.",
    questioncontent2: "Specialization:",
    answercontent2:
      " Students have the option to choose a specialization or concentration based on their interests, career goals, or academic background. Common specializations include biology, chemistry, physics, mathematics, environmental science, computer science, data science, engineering, psychology, and more.",
    questioncontent3: "Advanced Coursework: ",
    answercontent3:
      " The Course offers advanced coursework in the chosen specialization, covering advanced topics, research methodologies, and theoretical frameworks relevant to the discipline. Courses may include seminars, workshops, laboratory sessions, independent studies, and interdisciplinary courses that explore the intersections between different fields of study.",
    questioncontent4: "Research Methods: ",
    answercontent4:
      "Students learn research methods and techniques used in the scientific discipline, including experimental design, data analysis, statistical methods, literature review, research ethics, and scholarly writing. These research skills prepare students to conduct original research, analyze data, and contribute to the body of knowledge in their field.",
    questioncontent5: "Thesis or Capstone Project: ",
    answercontent5:
      ": Many MSc Courses culminate in a thesis or capstone project where students can conduct original research, develop a scientific hypothesis, design experiments, collect and analyze data, and present their findings in a written document or scientific report. The thesis or capstone project allows students to demonstrate their mastery of the subject matter and contribute to the advancement of knowledge in their field.",
    questioncontent6: "Online Learning Platform: ",
    answercontent6:
      " MSc Courses utilize advanced online learning platforms that offer interactive lectures, multimedia resources, virtual laboratories, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent7: "Flexible Schedule: ",
    answercontent7:
      "Online MSc Courses provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus Course.",
    questioncontent8: "Faculty Support: ",
    answercontent8:
      "Experienced faculty members with expertise in the scientific discipline facilitate the online courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or online chat platforms to address students&#39; questions and concerns.",
    questioncontent8: "Interactive Learning Tools: ",
    answercontent8:
      " Online MSc Courses incorporate interactive learning tools and technologies to enhance the learning experience. These may include virtual laboratory simulations, multimedia presentations, case studies, data analysis software, and collaborative research platforms, allowing students to engage actively with course materials and develop practical skills.",
    questioncontent8: "Networking Opportunities:",
    answercontent8:
      " Online MSc Courses offer networking opportunities with fellow students, alumni, faculty members, and professionals in the field through virtual events, webinars, discussion forums, and social media platforms. These networking opportunities enable students to build connections, share ideas, collaborate on research projects, and explore career opportunities.",
    specialization1: "UX Design",
    specialization2: "Data Science",
    specialization3: "Robotics Process Automation",
    specialization4: "Cloud Technology & Information Security",
    specialization4: "Block Chain",
    specialization4: "Artificial Intelligence & Machine Learning",
    courseRequirementchild: "",
    growthandcareer:
      "Growth and Career Opportunities for Master of Science (MSC) Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "MSC graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " MSC graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, MSC graduates are well-positioned to start their ventures. The Course equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the MSC Course makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "MSC serves as a stepping stone for advanced education. Graduates can pursue Master of Science (MSC) Courses or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, MSC graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the Course instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "MSC Courses often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. MSC graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Master of Science provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, MSC graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
    whyonline: "WHY CHOOSE Online?",
    whyonline1:
      "The Online Course gives you the flexibility to study from the location& time of your choice. This means online learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, Online lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The online learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing online degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An online degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus Courses. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of Online MSC Courses",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: Online Courses eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: Online Courses often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus Courses.",
    benefits4:
      "Career Advancement: Graduates of Online MSC Courses are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  {
    id: 6,
    title: "MBA (ODL Distance)",
    coursename: "Master of Business Administration (ODL Distance) Course",
    coursepageimg: require("../../assets/mbaodl.png"),
    
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "1-2 Years",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle:
      "Master of Business Administration (ODL Distance) Course Overview",
    courseDesc:
      "The ODL Distance Master of Business Administration (MBA) Course is designed to provide students with a comprehensive understanding of commerce, business principles, accounting, finance, economics, and management. Through a flexible and accessible Distance platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The Course equips graduates with the knowledge and skills necessary to excel in various roles in the field of commerce, finance, and business.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through Distance education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Marketing Executive",
    scopeandcareer2: "Business development executive",
    scopeandcareer3: "Executive Assistant",
    scopeandcareer4: "Brand manager",
    scopeandcareer5: "Event manager",
    scopeandcareer6: "Account manager",
    scopeandcareer7: "Travel and tourism manager",
    scopeandcareer8: "Field sales executive",
    scopeandcareer9: "Financial analyst",
    scopeandcareer10: "Operation analyst",
    scopeandcareer11: "Human resource manager",
    questioncontent1: "Core Business Curriculum",
    answercontent1:
      "The Course typically includes a core curriculum covering essential business topics such as management, marketing, finance, accounting, economics, operations, and strategic planning. These courses provide students with a solid foundation in fundamental business concepts and principles.",
    questioncontent2: "Elective Specializations",
    answercontent2:
      "Students may have the opportunity to choose elective courses or specializations based on their interests and career goals. Elective options may include areas such as entrepreneurship, human resources, international business, digital marketing, or supply chain management, allowing students to tailor their learning experience to suit their aspirations.",
    questioncontent3: "Distance Learning Platform",
    answercontent3:
      " The MBA Course utilizes an advanced Distance learning platform that offers interactive lectures, multimedia resources, virtual classrooms, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent4: "Flexible Schedule",
    answercontent4:
      "Distance MBA Courses provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus Course.",
    questioncontent5: "Faculty Support",
    answercontent5:
      "Experienced faculty members with expertise in their respective fields facilitate the Distance courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or Distance chat platforms to address students' questions and concerns.",
    questioncontent6: "Interactive Learning Tools",
    answercontent6:
      "Distance MBA Courses incorporate interactive learning tools and technologies to enhance the learning experience. These may include virtual simulations, case studies, video lectures, quizzes, and multimedia presentations, allowing students to apply theoretical concepts to real-world scenarios and develop practical skills.",
    questioncontent7: "Capstone Projects or Internships",
    answercontent7:
      " Many Distance MBA Courses culminate in a capstone project or internship experience, where students can apply their knowledge and skills to solve real-world business challenges or gain hands-on experience in a professional setting. These practical experiences help students integrate classroom learning with practical application and prepare them for entry into the workforce.",
    specialization1: "General",
    specialization2: "Aviation",
    specialization3: "Enterprise risk",
    specialization4: "Digital marketing",
    specialization5: "Retail",
    specialization6: "Fintech",
    specialization7: "Travel & Tourism",
    specialization8: "International Finance",
    courseRequirementchild: "",
    growthandcareer:
      "Growth and Career Opportunities for Bachelor of Business Administration (MBA) Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "MBA graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " MBA graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, MBA graduates are well-positioned to start their ventures. The Course equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the MBA Course makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "MBA serves as a stepping stone for advanced education. Graduates can pursue Master of Business Administration (MBA) Courses or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, MBA graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the Course instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "MBA Courses often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. MBA graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Bachelor of Business Administration provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, MBA graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
    whyonline: "WHY CHOOSE ODL?",
    whyonline1:
      "The ODL Course gives you the flexibility to study from the location& time of your choice. This means odl learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, ODL lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The odl learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing odl degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An odl degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus Courses. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of odl MBA Courses",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: odl Courses eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: odl Courses often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus Courses.",
    benefits4:
      "Career Advancement: Graduates of odl MBA Courses are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  {
    id: 7,
    title: "MCOM (ODL Distance)",
    coursename: "MCOM (ODL Distance) Course",
    coursepageimg: require("../../assets/mcomodl.png"),
    
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "1-2 Years",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle: "MCOM (ODL Distance) Course Overview",
    courseDesc:
      "The ODL Distance Master of Commerce (MCOM) Course is designed to provide students with a comprehensive understanding of commerce, business principles, accounting, finance, economics, and management. Through a flexible and accessible Distance platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The Course equips graduates with the knowledge and skills necessary to excel in various roles in the field of commerce, finance, and business.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through Distance education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Diverse Opportunities",
    scopeandcareer2: "Industry Relevance",
    scopeandcareer3: "Specialized Roles",
    scopeandcareer4: "Entrepreneurship",
    scopeandcareer5: "Global Perspective",
    scopeandcareer6: "Networking Opportunities",
    scopeandcareer7: "Continuous Growth",
    scopeandcareer8: "Further Education",
    questioncontent1: "Core Commerce Curriculum:",
    answercontent1:
      "The MCOM Course typically includes a core curriculum covering fundamental commerce topics such as accounting, finance, economics, business law, taxation, marketing, management, and organizational behavior.",
    questioncontent2: "Accounting and Financial Management:",
    answercontent2:
      "Students learn accounting principles, financial statement analysis, budgeting, cost accounting, auditing, and financial management techniques used in business organizations.",
    questioncontent3: "Economics:",
    answercontent3:
      " The Course covers microeconomics and macroeconomics concepts, including supply and demand, market structures, consumer behavior, national income accounting, monetary policy, and international trade.",
    questioncontent4: "Business Law and Taxation:",
    answercontent4:
      "Students gain an understanding of business law principles, contract law, corporate law, commercial law, and taxation laws relevant to business operations.",
    questioncontent5: "Management and Organizational Behavior:",
    answercontent5:
      "The Course focuses on management principles, leadership styles, organizational behavior, human resource management, strategic management, and business ethics.",
    questioncontent6: "Marketing:",
    answercontent6:
      "Students learn marketing concepts, market research, consumer behavior, branding, advertising, promotion, pricing strategies, and digital marketing techniques.",
    questioncontent7: "Finance:",
    answercontent7:
      " The Course covers financial markets, investment analysis, portfolio management, risk management, financial derivatives, and corporate finance principles.",
    questioncontent8: "Elective Specializations:",
    answercontent8:
      " Some MCOM Courses offer elective courses or specializations in specific areas of interest such as financial accounting, management accounting, taxation, banking, insurance, international business, entrepreneurship, or e-commerce.",
    questioncontent9: "Distance Learning Platform:",
    answercontent9:
      " MCOM Courses utilize advanced Distance learning platforms that offer interactive lectures, multimedia resources, virtual classrooms, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent10: "Flexible Schedule:",
    answercontent10:
      " Distance MCOM Courses provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus Course.",
    questioncontent11: "Faculty Support: ",
    answercontent11:
      " Experienced faculty members with expertise in commerce, business, finance, and economics facilitate the Distance courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or Distance chat platforms to address students&#39; questions and concerns.",
    questioncontent12: "Interactive Learning Tools:",
    answercontent12:
      " Distance MCOM Courses incorporate interactive learning tools and technologies to enhance the learning experience. These may include case studies, simulations, quizzes, assignments, and multimedia presentations, allowing students to apply theoretical concepts to real-world scenarios and develop practical skills.",
    questioncontent13: "Capstone Project or Internship:",
    answercontent13:
      " Many Distance MCOM Courses culminate in a capstone project or internship experience, where students can apply their knowledge and skills to solve real-world problems, gain practical experience, or contribute to ongoing projects in business organizations. These practical experiences help students integrate classroom learning with practical application and prepare them for entry into the workforce.",
    specialization1: "Banking",
    specialization2: "Human Resource",
    specialization3: "Finance",
    specialization4: "Marketing",
    courseRequirementchild: "",
    growthandcareer:
      "Growth and Career Opportunities for Master of Business Administration (MCOM) Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "MCOM graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " MCOM graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, MCOM graduates are well-positioned to start their ventures. The Course equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the MCOM Course makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "MCOM serves as a stepping stone for advanced education. Graduates can pursue Master of Business Administration (MBA) Courses or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, MCOM graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the Course instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "MCOM Courses often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. MCOM graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Master of Business Administration provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, MCOM graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
    whyonline: "WHY CHOOSE ODL?",
    whyonline1:
      "The ODL Course gives you the flexibility to study from the location& time of your choice. This means odl learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, ODL lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The odl learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing odl degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An odl degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus Courses. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of odl Master of Commerce (MCOM) Courses",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: odl Courses eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: odl Courses often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus Courses.",
    benefits4:
      "Career Advancement: Graduates of odl MCOM Courses are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  {
    id: 8,
    title: "MA (ODL Distance)",
    coursename: "MA Course (ODL Distance)",
    coursepageimg: require("../../assets/maodl.png"),
    
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "1-2 Years",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle: "MCOM (ODL Distance) Course Overview",
    courseDesc:
      "The ODL Distance Master of Arts (MA) Course offers students a wide range of academic disciplines to explore, allowing them to tailor their education to their interests and career goals. Through a flexible and accessible odl platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The MA Course equips graduates with critical thinking skills, cultural awareness, communication abilities, and a strong foundation in their chosen field of study.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through odl education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Business processing outsourcing units",
    scopeandcareer2: "Broadcasting & media",
    scopeandcareer3: "Advertising & PR Agencies",
    scopeandcareer4: "Journalism",
    scopeandcareer5: "Community services",
    courseRequirementchild: "",
    questioncontent1: "Core Curriculum:",
    answercontent1:
      "The MA Course typically includes a core curriculum covering foundational courses in humanities, social sciences, and liberal arts. These courses provide students with a broad understanding of various academic disciplines and foster interdisciplinary thinking.",
    questioncontent2: "Major Concentration:",
    answercontent2:
      "Students have the opportunity to choose a major concentration or area of specialization based on their interests and career aspirations. Popular majors include English literature, history, psychology, sociology, political science, economics, anthropology, philosophy, and communication studies.",
    questioncontent3: "Elective Courses:",
    answercontent3:
      " In addition to major requirements, students can select elective courses from a diverse range of subjects to complement their studies and broaden their academic horizons. Elective options may include foreign languages, fine arts, environmental studies, gender studies, global studies, and more.",
    questioncontent4: "odl Learning Platform:",
    answercontent4:
      "MA Courses utilize advanced odl learning platforms that offer interactive lectures, multimedia resources, virtual classrooms, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent5: "Flexible Schedule:",
    answercontent5:
      "Distance MA Courses provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus Course.",
    questioncontent6: "Faculty Support:",
    answercontent6:
      "Experienced faculty members with expertise in their respective fields facilitate the odl courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or odl chat platforms to address students&#39; questions and concerns.",
    questioncontent7: "Interactive Learning Tools:",
    answercontent7:
      " Distance MA Courses incorporate interactive learning tools and technologies to enhance the learning experience. These may include virtual discussions, multimedia presentations, case studies, simulations, quizzes, and collaborative projects, allowing students to engage actively with course materials and apply theoretical concepts to real-world scenarios.",
    questioncontent8: "Capstone Project or Thesis: ",
    answercontent8:
      "Many Distance MA Courses culminate in a capstone project, thesis, or research paper where students can demonstrate their understanding of the subject matter and conduct independent research under the guidance of faculty mentors. This culminating experience allows students to showcase their analytical abilities, critical thinking skills, and academic achievements.",
    specialization1: "Banking",
    specialization2: "Human Resource",
    specialization3: "Finance",
    specialization4: "Marketing",
    courseRequirementchild: "",
    growthandcareer:
      "Growth and Career Opportunities for Master of Business Administration (MA) Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "MA graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " MA graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, MA graduates are well-positioned to start their ventures. The Course equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the MA Course makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "MA serves as a stepping stone for advanced education. Graduates can pursue Master of Business Administration (MBA) Courses or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, MA graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the Course instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "MA Courses often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. MA graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Master of Business Administration provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, MA graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
    whyonline: "WHY CHOOSE ODL?",
    whyonline1:
      "The ODL Course gives you the flexibility to study from the location& time of your choice. This means odl learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, ODL lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The odl learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing odl degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An odl degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus Courses. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of Distance MA Courses",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: odl Courses eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: odl Courses often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus Courses.",
    benefits4:
      "Career Advancement: Graduates of Distance MA Courses are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  // {
  //   id: 10,
  //   title: "ODL Distance MA Honours",
  //   coursename: "MA Honours (ODL Distance) Course",
  //   coursepageimg: require("../../assets/mba.jpg"),
  // //   
  // univers: "University",
  //   universCount: "10",
  //   duration: "Duration",
  //   durationYear: "1-2 Years",
  //   eligibility: "Eligibility",
  //   qualif: "12th Passout",
  //   courseTitle: "MA Honours (ODL Distance) Course Overview",
  //   courseDesc:
  //     "The ODL Distance Master of Arts (MA Honours) Course offers students a wide range of academic disciplines to explore, allowing them to tailor their education to their interests and career goals. Through a flexible and accessible odl platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The MA Course equips graduates with critical thinking skills, cultural awareness, communication abilities, and a strong foundation in their chosen field of study.",
  //   componentsTitle: "Key Components",
  //   enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
  //   enlightendesc:
  //     "Through odl education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
  //   scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
  //   scopeandcareer1: "Business processing outsourcing units",
  //   scopeandcareer2: "Broadcasting & media",
  //   scopeandcareer3: "Advertising & PR Agencies",
  //   scopeandcareer4: "Journalism",
  //   scopeandcareer5: "Community services",
  //   courseRequirementchild: "",
  //   questioncontent1: "Core Curriculum:",
  //   answercontent1:
  //     "The MA Honours Course typically includes a core curriculum covering foundational courses in humanities, social sciences, and liberal arts. These courses provide students with a broad understanding of various academic disciplines and foster interdisciplinary thinking.",
  //   questioncontent2: "Major Concentration:",
  //   answercontent2:
  //     "Students have the opportunity to choose a major concentration or area of specialization based on their interests and career aspirations. Popular majors include English literature, history, psychology, sociology, political science, economics, anthropology, philosophy, and communication studies.",
  //   questioncontent3: "Elective Courses:",
  //   answercontent3:
  //     " In addition to major requirements, students can select elective courses from a diverse range of subjects to complement their studies and broaden their academic horizons. Elective options may include foreign languages, fine arts, environmental studies, gender studies, global studies, and more.",
  //   questioncontent4: "odl Learning Platform:",
  //   answercontent4:
  //     "MA Honours Courses utilize advanced odl learning platforms that offer interactive lectures, multimedia resources, virtual classrooms, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
  //   questioncontent5: "Flexible Schedule:",
  //   answercontent5:
  //     "Distance MA HonoursCourses provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus Course.",
  //   questioncontent6: "Faculty Support:",
  //   answercontent6:
  //     "Experienced faculty members with expertise in their respective fields facilitate the odl courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or odl chat platforms to address students&#39; questions and concerns.",
  //   questioncontent7: "Interactive Learning Tools:",
  //   answercontent7:
  //     " Distance MA HonoursCourses incorporate interactive learning tools and technologies to enhance the learning experience. These may include virtual discussions, multimedia presentations, case studies, simulations, quizzes, and collaborative projects, allowing students to engage actively with course materials and apply theoretical concepts to real-world scenarios.",
  //   questioncontent8: "Capstone Project or Thesis: ",
  //   answercontent8:
  //     "Many Distance MA HonoursCourses culminate in a capstone project, thesis, or research paper where students can demonstrate their understanding of the subject matter and conduct independent research under the guidance of faculty mentors. This culminating experience allows students to showcase their analytical abilities, critical thinking skills, and academic achievements.",
  //   specialization1: "Banking",
  //   specialization2: "Human Resource",
  //   specialization3: "Finance",
  //   specialization4: "Marketing",
  //   courseRequirementchild: "",
  //   growthandcareer:
  //     "Growth and Career Opportunities for Master of Arts (MA Honours) Graduates:",
  //   growthandcareertitle1: "Diverse Career Paths:",
  //   growthandcareerdesc1:
  //     "MA graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
  //   growthandcareertitle2: "Management Roles:",
  //   growthandcareerdesc2:
  //     " MA graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
  //   growthandcareertitle3: "Entrepreneurship:",
  //   growthandcareerdesc3:
  //     "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, MA graduates are well-positioned to start their ventures. The Course equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
  //   growthandcareertitle4: "Specialized Roles:",
  //   growthandcareerdesc4:
  //     "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
  //   growthandcareertitle5: "Global Opportunities:",
  //   growthandcareerdesc5:
  //     "The global perspective gained through the MA Course makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
  //   growthandcareertitle6: "Advanced Education:",
  //   growthandcareerdesc6:
  //     "MA Honours serves as a stepping stone for advanced education. Graduates can pursue Master of Arts (MA Hons) Courses or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
  //   growthandcareertitle7: "Leadership Roles:",
  //   growthandcareerdesc7:
  //     "With a focus on leadership development, MA graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the Course instills the skills necessary for effective leadership in diverse business settings.",
  //   growthandcareertitle8: "Networking Opportunities:",
  //   growthandcareerdesc8:
  //     "MA Honours Courses often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
  //   growthandcareertitle9: "Continuous Learning:",
  //   growthandcareerdesc9:
  //     "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. MA graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
  //   growthandcareerdesc10:
  //     "The Master of Arts provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, MA graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
  //   whyonline: "WHY CHOOSE ODL?",
  //   whyonline1:
  //     "The ODL Course gives you the flexibility to study from the location& time of your choice. This means odl learning offers students the accessibility of time and place in education.",
  //   whyonline2:
  //     "Additionally, ODL lectures can be recorded, archived, and shared for future reference.",
  //   whyonline3:
  //     "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
  //   whyonline4:
  //     "The odl learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
  //   whyonline5:
  //     "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
  //   whyonline6:
  //     "One can do a job while pursuing odl degrees; basically it is for working professionals or for students preparing for competitive examinations.",
  //   whyonline7:
  //     "An odl degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus Courses. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
  //   benefits: "Benefits of Distance MA Honours Courses",
  //   benefits1:
  //     "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
  //   benefits2:
  //     "Accessibility: odl Courses eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
  //   benefits3:
  //     "Cost-effectiveness: odl Courses often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus Courses.",
  //   benefits4:
  //     "Career Advancement: Graduates of Distance MA HonoursCourses are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  // },
  {
    id: 9,
    title: "MSC (ODL Distance)",
    coursename: "MSC (ODL Distance) Course",
    coursepageimg: require("../../assets/mscodl.png"),
    
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "1-2 Years",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle: "MSC (ODL Distance) Course Overview",
    courseDesc:
      "The ODL Distance Master of Science (MSC) Course is designed to provide students with a comprehensive understanding of computer science principles, Courseming languages, software development, and information technology concepts. Through a flexible and accessible odl platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The Course equips graduates with the knowledge and skills necessary to excel in various roles in the field of Science and technology.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through odl education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Computer Coursemer",
    scopeandcareer2: " Software  Developer",
    scopeandcareer3: "System Analyst",
    scopeandcareer4: "   Network Engineer",
    scopeandcareer5: " Software Architect",
    scopeandcareer5: " Software Tester",
    questioncontent1: "Core Curriculum:",
    answercontent1:
      " The MSc Course typically includes a core curriculum covering foundational concepts, theories, and methodologies in the chosen scientific discipline or field of study. This core curriculum provides students with a comprehensive understanding of the subject matter and prepares them for advanced coursework and research.",
    questioncontent2: "Specialization:",
    answercontent2:
      " Students have the option to choose a specialization or concentration based on their interests, career goals, or academic background. Common specializations include biology, chemistry, physics, mathematics, environmental science, computer science, data science, engineering, psychology, and more.",
    questioncontent3: "Advanced Coursework: ",
    answercontent3:
      " The Course offers advanced coursework in the chosen specialization, covering advanced topics, research methodologies, and theoretical frameworks relevant to the discipline. Courses may include seminars, workshops, laboratory sessions, independent studies, and interdisciplinary courses that explore the intersections between different fields of study.",
    questioncontent4: "Research Methods: ",
    answercontent4:
      "Students learn research methods and techniques used in the scientific discipline, including experimental design, data analysis, statistical methods, literature review, research ethics, and scholarly writing. These research skills prepare students to conduct original research, analyze data, and contribute to the body of knowledge in their field.",
    questioncontent5: "Thesis or Capstone Project: ",
    answercontent5:
      ": Many MSc Courses culminate in a thesis or capstone project where students can conduct original research, develop a scientific hypothesis, design experiments, collect and analyze data, and present their findings in a written document or scientific report. The thesis or capstone project allows students to demonstrate their mastery of the subject matter and contribute to the advancement of knowledge in their field.",
    questioncontent6: "odl Learning Platform: ",
    answercontent6:
      " MSc Courses utilize advanced odl learning platforms that offer interactive lectures, multimedia resources, virtual laboratories, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent7: "Flexible Schedule: ",
    answercontent7:
      "Distance MSC Courses provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus Course.",
    questioncontent8: "Faculty Support: ",
    answercontent8:
      "Experienced faculty members with expertise in the scientific discipline facilitate the odl courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or odl chat platforms to address students&#39; questions and concerns.",
    questioncontent8: "Interactive Learning Tools: ",
    answercontent8:
      " Distance MSC Courses incorporate interactive learning tools and technologies to enhance the learning experience. These may include virtual laboratory simulations, multimedia presentations, case studies, data analysis software, and collaborative research platforms, allowing students to engage actively with course materials and develop practical skills.",
    questioncontent8: "Networking Opportunities:",
    answercontent8:
      " Distance MSC Courses offer networking opportunities with fellow students, alumni, faculty members, and professionals in the field through virtual events, webinars, discussion forums, and social media platforms. These networking opportunities enable students to build connections, share ideas, collaborate on research projects, and explore career opportunities.",
    specialization1: "UX Design",
    specialization2: "Data Science",
    specialization3: "Robotics Process Automation",
    specialization4: "Cloud Technology & Information Security",
    specialization4: "Block Chain",
    specialization4: "Artificial Intelligence & Machine Learning",
    courseRequirementchild: "",
    growthandcareer:
      "Growth and Career Opportunities for Master of Science (MSC) Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "MSC graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " MSC graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, MSC graduates are well-positioned to start their ventures. The Course equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the MSC Course makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "MSC serves as a stepping stone for advanced education. Graduates can pursue Master of Science (MSC) Courses or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, MSC graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the Course instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "MSC Courses often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. MSC graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Master of Science provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, MSC graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
    whyoODL: "WHY CHOOSE ODL?",
    whyonline1:
      "The ODL Course gives you the flexibility to study from the location& time of your choice. This means odl learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, ODL lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The odl learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing ODL degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An ODL degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus Courses. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of Distance MSC Courses",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: ODL Courses eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: ODL Courses often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus Courses.",
    benefits4:
      "Career Advancement: Graduates of Distance MSC Courses are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
  {
    id: 10,
    title: "MCA (ODL Distance)",
    coursename: "MCA (ODL Distance) Course",
    coursepageimg: require("../../assets/mcaodl.png"),
    
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "1-2 Years",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle: "MCA Course (ODL Distance) Overview",
    courseDesc:
      "The ODL Distance Master of Computer Applications (MCA) Course is designed to provide students with a comprehensive understanding of computer science principles, Courseming languages, software development, and information technology concepts. Through a flexible and accessible odl platform, students can pursue their education while balancing other commitments, such as work or family responsibilities. The Course equips graduates with the knowledge and skills necessary to excel in various roles in the field of computer applications and technology.",
    componentsTitle: "Key Components",
    enlightenTitle: "ENLIGHTEN YOUR LEARNING SKILLS",
    enlightendesc:
      "Through odl education, which also provides them with access to an inexhaustible supply of educational resources, students can develop their self-discipline and time management abilities. If they have the right tools and a reliable internet connection, students may easily gauge their own learning pace.",
    scopeandcareerProspects: "SCOPE & CAREER PROSPECTS",
    scopeandcareer1: "Computer Coursemer",
    scopeandcareer2: " Software  Developer",
    scopeandcareer3: "System Analyst",
    scopeandcareer4: "   Network Engineer",
    scopeandcareer5: " Software Architect",
    scopeandcareer5: " Software Tester",
    questioncontent1: "Core Computer Science Curriculum:",
    answercontent1:
      " The MCA Course typically includes a core curriculum covering fundamental computer science topics such as computer Courseming, data structures, algorithms, database management systems, computer networks, software engineering, and web technologies.",
    questioncontent2: "Courseming Languages:",
    answercontent2:
      " Students learn Courseming languages such as Java, C++, Python, and JavaScript, enabling them to develop software applications, algorithms, and solutions to real-world problems.Major Concentration",
    questioncontent3: "Software Development: ",
    answercontent3:
      " The Course focuses on software development methodologies, techniques, and tools used in the design, development, testing, and maintenance of software systems and applications.",
    questioncontent4: "Information Technology Concepts: ",
    answercontent4:
      "Students gain an understanding of information technology concepts such as cybersecurity, cloud computing, data analytics, artificial intelligence, machine learning, and Internet of Things (IoT).",
    questioncontent5: "Elective Specializations: ",
    answercontent5:
      "Some MCA Courses offer elective courses or specializations in specific areas of interest such as cybersecurity, data science, web development, mobile application development, game development, or digital marketing.",
    questioncontent6: "odl Learning Platform: ",
    answercontent6:
      "MCA Courses utilize advanced odl learning platforms that offer interactive lectures, multimedia resources, virtual labs, coding exercises, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection.",
    questioncontent7: "Flexible Schedule: ",
    answercontent7:
      "Distance MCA Courses provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus Course.",
    questioncontent8: "Faculty Support: ",
    answercontent8:
      "Experienced faculty members with expertise in computer science and information technology facilitate the odl courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or online chat platforms to address students; questions and concerns.",
    questioncontent8: "Capstone Project or Internship: ",
    answercontent8:
      " Many Distance MCA Courses culminate in a capstone project or internship experience, where students can apply their knowledge and skills to solve real-world problems, contribute to ongoing projects, or gain hands-on experience in a professional setting. These practical experiences help students integrate classroom learning with practical application and prepare them for entry into the workforce.",
    specialization1: "UX Design",
    specialization2: "Data Science",
    specialization3: "Robotics Process Automation",
    specialization4: "Cloud Technology & Information Security",
    specialization4: "Block Chain",
    specialization4: "Artificial Intelligence & Machine Learning",
    courseRequirementchild: "",
    growthandcareer:
      "Growth and Career Opportunities for Master of Computer Application (MCA) Graduates:",
    growthandcareertitle1: "Diverse Career Paths:",
    growthandcareerdesc1:
      "MCA graduates enjoy a wide array of career paths in various industries such as finance, marketing, human resources, operations, and entrepreneurship. The versatility of the degree allows for flexibility in choosing a career that aligns with individual interests and strengths.",
    growthandcareertitle2: "Management Roles:",
    growthandcareerdesc2:
      " MCA graduates are well-prepared for entry-level management positions. With a strong foundation in business principles, graduates can quickly progress to supervisory and managerial roles, overseeing teams and contributing to organizational success.",
    growthandcareertitle3: "Entrepreneurship:",
    growthandcareerdesc3:
      "Armed with a solid understanding of business fundamentals and an entrepreneurial mindset, MCA graduates are well-positioned to start their ventures. The Course equips them with the skills needed to identify opportunities, create business plans, and navigate the challenges of entrepreneurship.",
    growthandcareertitle4: "Specialized Roles:",
    growthandcareerdesc4:
      "Graduates can specialize in areas such as finance, marketing, human resources, or international business. Specialization allows for in-depth expertise in a specific field, opening doors to specialized roles and career advancement opportunities.",
    growthandcareertitle5: "Global Opportunities:",
    growthandcareerdesc5:
      "The global perspective gained through the MCA Course makes graduates attractive candidates for international roles. Many organizations seek professionals with a global mindset who can navigate the complexities of international business and contribute to the success of global operations.",
    growthandcareertitle6: "Advanced Education:",
    growthandcareerdesc6:
      "MCA serves as a stepping stone for advanced education. Graduates can pursue Master of Computer Application (MCA) Courses or other specialized master's degrees to further enhance their knowledge and credentials, potentially leading to executive-level positions.",
    growthandcareertitle7: "Leadership Roles:",
    growthandcareerdesc7:
      "With a focus on leadership development, MCA graduates are well-prepared to take on leadership roles within organizations. Whether as team leaders, department heads, or executives, the Course instills the skills necessary for effective leadership in diverse business settings.",
    growthandcareertitle8: "Networking Opportunities:",
    growthandcareerdesc8:
      "MCA Courses often provide networking opportunities through industry events, guest lectures, and internships. Networking can lead to valuable connections with professionals in the field, opening doors to career opportunities, mentorship, and collaborations.",
    growthandcareertitle9: "Continuous Learning:",
    growthandcareerdesc9:
      "The dynamic nature of business requires professionals to stay updated on industry trends and innovations. MCA graduates are equipped with a mindset of continuous learning, allowing them to adapt to changing business landscapes and remain valuable contributors to their organizations.",
    growthandcareerdesc10:
      "The Master of Computer Application provides a solid foundation for a fulfilling and dynamic career. Whether pursuing traditional corporate roles, entrepreneurial endeavors, or specialized paths, MCA graduates are well-prepared to navigate the evolving business landscape and seize diverse growth and career opportunities.",
    whyonline: "WHY CHOOSE ODL?",
    whyonline1:
      "The ODL Course gives you the flexibility to study from the location& time of your choice. This means ODL learning offers students the accessibility of time and place in education.",
    whyonline2:
      "Additionally, ODL lectures can be recorded, archived, and shared for future reference.",
    whyonline3:
      "Every student has a different learning journey and a different learning style. Some students are visual learners, while some students prefer to learn through audio. Similarly, some students thrive in the classroom, and other students are solo learners who get distracted by large groups.",
    whyonline4:
      "The ODL learning system, with its range of options and resources, can be personalized in many ways. It is the best way to create a perfect learning environment suited to the needs of each student.",
    whyonline5:
      "Only one has to put all his effort into coursework, learning, assignment and projects. These things will make him responsible towards the schedule and deadlines.",
    whyonline6:
      "One can do a job while pursuing ODL degrees; basically it is for working professionals or for students preparing for competitive examinations.",
    whyonline7:
      "An ODL degree is as good as Regular Degree if it is done from a good university recognized by the University Grants Commission (UGC) with NAAC A+grade. It will provide you with the same quality of education as on-campus Courses. You get to learn experienced faculty members and access to the latest resources and technology with extreme flexibility as you would in a traditional degree.",
    benefits: "Benefits of Distance MCA Courses",
    benefits1:
      "Flexibility: Students can access course materials and lectures at their convenience, allowing them to balance their studies with work, family commitments, or other obligations.",
    benefits2:
      "Accessibility: ODL Courses eliminate geographical barriers, enabling students from diverse backgrounds and locations to pursue higher education without the need to relocate.",
    benefits3:
      "Cost-effectiveness: ODL Courses often offer cost savings in terms of tuition fees, commuting expenses, and accommodation costs associated with traditional on-campus Courses.",
    benefits4:
      "Career Advancement: Graduates of Distance MCA Courses are well-equipped to pursue various career opportunities and advance in their chosen fields, with the flexibility to continue working while studying.",
  },
];
export default Sdata2;
