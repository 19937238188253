const Collegedata1 = [
  {
    id: 1,
    partnersname: "LPU Online",
    // partnerstitle: "22 Courses",
    partnersimg: require("../../assets/partner/lpu-logo.png"),

    collegename: "LPU Online",
    collegedesc: "Accredited with NAAC A++ grade",
    whyus: "Why LPU Online",
    coursenames1: "Online BCA",
    coursefees1: "1,44,000",
    coursenames2: "Online MCA",
    coursefees2: "1,12,000",
    coursenames3: "Online B.COM",
    coursefees3: "1,20,000",
    coursenames4: "Online M.COM",
    coursefees4: "80,000",
    coursenames5: "Online BA",
    coursefees5: "96,000",
    coursenames6: "Online MA",
    coursefees6: "64,000",
    coursenames7: "Online MSC",
    coursefees7: "64,000",
    coursenames8: "Online MBA",
    coursefees8: "1,44,000",
    coursenames9: "Online MBA DUAL",
    coursefees9: "1,44,000",
    names1: "University",
    fees1: "LPU",
    names2: "Exam Pattern",
    fees2: "Online",
    names3: "MCQ Questions",
    fees3: "40",
    names4: "Mark/ Question",
    fees4: "1",
    names5: "Subjective Questions",
    fees5: "4, To be attempted 3",
    names6: "Projects (Last Sem)",
    fees6: "100",
    names7: "Mark/Question",
    fees7: "10",
    names8: "Assignment Marks",
    fees8: "30",
    names9: "Passing Marks",
    fees9: "40%",
    endlogo1: require("../../assets/ugc.png"),
    endlogo2: require("../../assets/AICTE-Logo.png"),
    endlogo3: require("../../assets/Association_of_Indian_Universities_Logo.png.png"),
    endlogo4: require("../../assets/National_Institutional_Ranking_Framework_logo.png"),
    endlogo5: require("../../assets/wes.png"),
    endlogo6: require("../../assets/NAAC_LOGO.png"),
    endlogo7: require("../../assets/partner/sacs-short-logo.jpg"),
    specialfeatures1:
      "Globally recognized UGC-approved NAAC A+ accredited the online degrees offered by lovely professional university",
    specialfeatures2:
      "Flexi-payment options allow students to pay fees in hassle-free installments. No-cost EMIs, let students learning take the spotlight without the stress of financing.",
    specialfeatures3:
      "It opens door to grab better professional opportunities.",
    specialfeatures4:
      "The shortcomings of physical distances by providing 24X7 accessibility through its various useful features.",
    onlineadvantagetitle1: "ADVANTAGES",
    onlineadvantagedesc1:
      "Top Grade Curriculum & Professors: Industry oriented Curriculum designed by experts from academia and industry and taught by highly qualified professors. Best LMS with Rich Interactive Features. Empowered LMS with an advance mobile App & interesting new features for effective teaching-learning.",
    onlineadvantagedesc2:
      "Master Classes led by Industry experts: Specially organized Master classes and Guest Lectures by Industry experts to provide the latest Industry insights",
    onlineadvantagedesc3:
      "Placements & Other Career Support: Enhancing students’ capabilities as per the career requirements and giving them necessary placement assistance",
    onlineadvantagedesc4:
      "Full-fledged Degree from a Top Ranked University: Get credible and UGC Entitled degree from India’s top ranked University",
    onlineadvantagedesc5:
      "Live Lectures: Interactive live lectures offer a real time interaction between student and the faculty where you can clear your doubts and interact with your peers thus providing a dynamic and innovative classroom experience.",
    onlineadvantagedesc6:
      "PARTICIPATION IN UNIVERSITY EVENTS: LPU provides equal opportunities to Distance Education students through participation in university convocation, placement drives, cultural and sports events etc.",
    onlineadvantagedesc7:
      "Assignments and Projects: Online submission of Assignments and Projects is introduced to give you a hassle-free experience to students throughout the programme and at the same time it will let you assess your overall progress.",
    onlineadvantagedesc8:
      "OPPORTUNITY TO VISIT CAMPUS: LPU DE provides opportunity to its students to visit the campus and have firsthand experience of campus life. Campus visits and the opportunity to interact directly with university officials are always a hit among LPU Distance Education students.",
    univprev1:
      "Online Lovely Professional University (LPU), a pioneering institution in the realm of digital education. Situated in Jalandhar, Punjab, Lovely Professional University has been a trailblazer in providing quality education for over two decades. With Online LPU, the university extends its commitment to excellence in education to learners across the globe.",
    univprev2:
      "Online LPU offers a wide array of undergraduate, postgraduate programmes. Through online platform, students have access to the same rigorous curriculum, experienced faculty members, and modern teaching methods as their on-campus counterparts.",
    univprev3:
      "One of the key advantages of Online LPU is its flexibility, allowing students to pursue their studies at their own pace and convenience. Whether you're a working professional, a busy parent, or someone with other commitments, our online programs enable you to balance your education with your personal and professional life.",
    univprev4:
      "Accessibility is another hallmark of Online LPU. With our online platform, learners from all corners of the world can access quality education without the constraints of geography or distance. This inclusivity ensures that education is available to everyone, regardless of their location or background.",
    univprev5:
      "At Online LPU, students benefit from interactive learning experiences facilitated by engaging lectures, multimedia content, and collaborative discussions. Our virtual classrooms provide a dynamic environment where students can interact with faculty members and peers, enhancing their understanding of the subject matter.",
    univprev6:
      "Online LPU also offers a range of support services to ensure that students have a smooth and seamless learning experience. From academic advising to technical support, our dedicated team is committed to assisting students every step of the way.",
    univprev7:
      "By choosing Online LPU, students gain access to a future-ready education that prepares them for success in the digital age and beyond. With our emphasis on innovation, industry relevance, and holistic development, Online LPU empowers learners to achieve their academic and professional goals, all from the comfort of their own homes.",
    univprev8:
      "Join us at Online Lovely Professional University and embark on a transformative educational journey that will shape your future and unlock endless opportunities for growth and success.",
    whyus: "Why Choose Lovely Professional University?",
    whyusdesc1:
      "Excellence in Education: LPU is renowned for its commitment to academic excellence. With a diverse range of programs and a faculty comprising experienced educators and industry experts, LPU provides students with a world-class learning experience that prepares them for success in their chosen fields.",
    whyusdesc2:
      "State-of-the-Art Infrastructure: The university boasts modern infrastructure and facilities that create an ideal environment for learning and growth. From well-equipped classrooms and laboratories to sports facilities and student accommodation, LPU ensures that students have access to everything they need to excel academically and personally.",
    whyusdesc3:
      "Global Exposure: LPU offers various international collaborations, exchange programs, and study abroad opportunities, providing students with global exposure and perspectives. These initiatives help students broaden their horizons, enhance their intercultural competencies, and prepare them for success in a globalized world.",
    whyusdesc4:
      "Industry Partnerships and Placements: LPU has strong ties with leading companies and organizations, facilitating internships, placements, and industry collaborations for students. The university's robust placement cell works tirelessly to connect students with job opportunities and prepare them for the competitive job market.",
    whyusdesc5:
      "Innovation and Research: LPU encourages innovation and research among its students and faculty members. The university provides ample opportunities for research projects, collaborations, and publications, allowing students to explore their interests and contribute to the advancement of knowledge.",
    whyusdesc6:
      "Holistic Development: LPU places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Entrepreneurship Development: LPU fosters an entrepreneurial mindset among its students, providing support and resources for aspiring entrepreneurs to turn their ideas into successful ventures. The university's entrepreneurship cell organizes workshops, seminars, and mentorship programs to help students develop their entrepreneurial skills and launch their own businesses.",
    whyusdesc8:
      "Student-Centric Approach: Above all, LPU is committed to the success and well-being of its students. The university adopts a student-centric approach, prioritizing their needs, aspirations, and overall growth and development.",
    collegetitle: " ",
    elearningdesc1:
      "The learning material will be made available to you on the learning Management System (LMS) based on the four-quadrant approach, as per UGC (Credit Framework for online learning courses through SWAYAM) Regulation,2016.It includes a combination of the following e-learning content, namely:",
    elearningtitle1: "Video lectures",
    elearningtitle2: "E-Text materials",
    elearningtitle3: "Interactive material",
    elearningtitle4: " Virtual classroom",
    elearningtitle5: " Discussion forums",
    elearningdesc2:
      "Free Coursers on Coursera: Get exclusive access to 10,000+ courses on Coursera. Additionally.",
    elearningdesc3:
      "BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
  },
  {
    id: 2,
    partnersname: "Manipal University Online",
    // partnerstitle: "22 Courses",
    partnersimg: require("../../assets/partner/onlinemanipal.png"),
    collegename: "Online Manipal Jaipur",
    collegedesc: "Accredited with NAAC A+ grade",
    whyus: "Why Manipal Online",
    coursenames1: "Online BCA",
    coursefees1: "1,35,000",
    coursenames2: "Online MCA",
    coursefees2: "1,58,000",
    coursenames3: "Online B.COM",
    coursefees3: "99,000",
    coursenames4: "Online M.COM",
    coursefees4: "1,08,000",
    coursenames5: "Online MA-JMC",
    coursefees5: "1,40,000",
    coursenames6: "Online BBA ",
    coursefees6: "1,35,000",
    coursenames7: "Online MBA ",
    coursefees7: "1,75,000",
    endlogo1: require("../../assets/ugc.png"),
    endlogo2: require("../../assets/AICTE-Logo.png"),
    endlogo3: require("../../assets/National_Institutional_Ranking_Framework_logo.png"),
    endlogo4: require("../../assets/wes.png"),
    endlogo5: require("../../assets/NAAC_LOGO.png"),
    specialfeatures1:
      "Globally recognized UGC-approved NAAC A+ accredited the online degrees offered by Manipal University Jaipur are at par with on-campus degrees.",
    specialfeatures2:
      "It opens door to grab better professional opportunities.",
    specialfeatures3:
      "The free Coursera access and choose from over 10,000+ professional certifications and courses from leading global universities while completing your degree. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    specialfeatures4:
      "Flexi-payment options allow students to pay fees in hassle-free installments. No-cost EMIs, let students learning take the spotlight without the stress of financing.",
    specialfeatures5:
      "Exclusive scholarships designed for defense personnel, government employees, differently-disabled people & meritorious students.",
    onlineadvantagetitle1: "ONLINE MANIPAL ADVANTAGES",
    onlineadvantagedesc1:
      "UGC Entitled Degrees: Access UGC-entitled degrees from world-class universities that are NAAC accredited. Pursue online degrees that are at par with conventional on-campus degrees and accepted by governments, corporate organizations, and higher education institutions.",
    onlineadvantagedesc2:
      "Free Coursers access: Get exclusive access to 10,000+ courses on Coursera. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    onlineadvantagedesc3:
      "Industry webinars & simulations: Attend webinars by industry experts to gain industry-specific knowledge. Participate in hands-on workshops and get certified in emerging technologies like Metaverse, AI Modelling, Block chain, and more.",
    onlineadvantagedesc4:
      "Exhaustive e – content & virtual lab: Gain access to vast e-libraries with e-books.",
    onlineadvantagedesc5:
      "Convenient class schedule: Attend live classes & access recorded lectures on-the-go. Engage in live interactions with faculty members to get your doubts clarified and write online-proctored exams from the comfort of your homes by booking slots as per your convenience.",
    onlineadvantagedesc6:
      "Global classroom: Join learners from all cities , towns and countries to connect & network. Exchange ideas with a diverse peer group from various industries, domains, geographies, and experience levels.",
    onlineadvantagedesc7:
      "Attractive scholarships: Attractive scholarships for defense personnel, government employees, differently-abled people & meritorious students, and special scholarships for select female applicants through Online Manipal’s #Empower her initiative.",
    onlineadvantagedesc8:
      "Placement assistance: Increase your chances of getting a job with dedicated career and placement assistance services. Attend career-readiness sessions, resume building workshops & webinars by experts, and participate in virtual placement drives..",
    univprev1:
      "Manipal University Jaipur (MUJ) is a UGC-approved university With a legacy of excellence in higher education, Manipal University Jaipur has continually redefined the standards of academic brilliance. As a NAAC A+ accredited institution, Manipal University Jaipur has pioneered industry-focused education, inspiring learners across various disciplines to excel through a blend of robust theoretical knowledge and hands-on practical experience.",
    univprev2:
      "In 2021, a new chapter unfolded for Manipal University Jaipur when the University Grants Commission (UGC) granted permission to offer online degree programs. The inaugural cohort commenced their studies in April 2021, marking a significant milestone. Online degrees from Manipal University Jaipur are delivered by world-class faculty and have garnered widespread popularity among both freshers and working professionals from diverse regions and countries.",
    univprev3:
      "Online Manipal Jaipur, a distinguished institution offering high-quality education through its online platform. Located in Jaipur, Rajasthan, Online Manipal Jaipur is part of the prestigious Manipal Education Group, renowned for its commitment to academic excellence and innovation.",
    univprev4:
      "Online Manipal Jaipur provides a wide range of undergraduate, postgraduate, and doctoral programs across various disciplines, including Engineering, Management, Computer Applications, Arts, Science, and more. As an online platform, it caters to the diverse educational needs of students worldwide, offering accessible and flexible learning opportunities.",
    univprev5:
      "Recognizing the importance of flexibility in today's fast-paced world, Online Manipal Jaipur ensures that its online programs accommodate the busy schedules of working professionals, stay-at-home parents, and learners seeking to advance their education without disrupting their daily lives. With 24/7 access to course materials, interactive lectures, and online resources, students can pursue their studies at their own pace, from any location.",
    univprev6:
      "The faculty at Online Manipal Jaipur comprises experienced educators and industry experts who are dedicated to providing a high-quality education that is both relevant and practical. Through a blend of theoretical knowledge and hands-on learning experiences, students are equipped with the skills and knowledge needed to excel in their chosen field.",
    univprev7:
      "In addition to academic excellence, Online Manipal Jaipur emphasizes holistic development, fostering well-rounded individuals who are socially responsible and ethically sound. Through extracurricular activities, virtual events, and community engagement initiatives, students have the opportunity to develop leadership skills, build connections, and make a positive impact on society.      ",
    univprev8:
      "Whether students aim to advance their careers, switch industries, or pursue their passion for learning, Online Manipal Jaipur offers the flexibility, accessibility, and support needed to achieve their goals. Join Online Manipal Jaipur on a journey of discovery, growth, and transformation, and unlock your full potential in the digital age.",
    whyus: "Why Choose Manipal University Jaipur?",
    whyusdesc1:
      "UGC-Entitled Degrees: Access UGC-entitled degrees from world-class universities accredited by NAAC, ensuring that your online degree holds the same prestige as traditional on-campus degrees, recognized by governments, corporations, and higher education institutions.",
    whyusdesc2:
      "Attractive Scholarships: Benefit from attractive scholarships tailored for defense personnel, government employees, differently-abled individuals, meritorious students, and alumni of Manipal universities.",
    whyusdesc3:
      "Placement Assistance: Enhance your employability with dedicated career and placement assistance services, including career-readiness sessions, resume building workshops, webinars by experts, and virtual placement drives.",
    whyusdesc4:
      "Prestigious Alumni Network: Join a revered alumni network comprising over 150,000 professionals and business leaders, including luminaries such as Mr. Satya Nadella, Chef Vikas Khanna, and Dr. Devi Prasad Shetty",
    whyusdesc5:
      "Industry Webinars & Simulations: Stay updated with industry trends through webinars conducted by experts and participate in hands-on workshops to gain certification in emerging technologies.",
    whyusdesc6:
      "Holistic Development: Manipal places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Global Classroom: Engage with learners from diverse backgrounds across various industries, domains, geographies, and experience levels, fostering a rich learning environment.",
    whyusdesc8:
      "In-Person Campus Immersion: Experience an exclusive in-person event, Ekam, to connect with peers and faculty members of your online program, participate in interactive sessions, and create lasting memories.",
    collegetitle: "Online Manipal University ",
    elearningdesc1:
      "The learning material will be made available to you on the learning Management System (LMS) based on the four-quadrant approach, as per UGC (Credit Framework for online learning courses through SWAYAM) Regulation,2016.It includes a combination of the following e-learning content, namely:",
    elearningtitle1: "Video lectures",
    elearningtitle2: "E-Text materials",
    elearningtitle3: "Interactive material",
    elearningtitle4: "Virtual classroom",
    elearningtitle5: "Discussion forums",
    elearningdesc2:
      "Free Coursers on Coursera: Get exclusive access to 10,000+ courses on Coursera. Additionally.",
    elearningdesc3:
      "BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
  },
  {
    id: 3,
    partnersname: "GLA Online",
    // partnerstitle: "22 Courses",
    partnersimg: require("../../assets/partner/GLA-U-logo.jpg"),
    endlogo1: require("../../assets/ugc.png"),
    endlogo2: require("../../assets/AICTE-Logo.png"),
    endlogo3: require("../../assets/aiu.png"),
    endlogo4: require("../../assets/partner/naac a+.jpg"),
    collegename: "GLA University",
    collegedesc: "Accredited with NAAC A grade",
    whyus: "Why GLA Online",
    coursenames1: "Online BBA",
    coursefees1: "65,500",
    coursenames2: "Online MBA",
    coursefees2: "73,000",
    coursenames3: "Online B.COM",
    coursefees3: "49,000",
    coursenames4: "Online PG DIPLOMA",
    coursefees4: "31,000",
    coursenames5: "Online MBA Dual",
    coursefees5: "73,000",
    coursenames6: "Online B.COM Honours",
    coursefees6: "49,000",
    coursenames6: "Online BCA",
    coursefees6: "65,500",
    coursenames6: "Online MCA",
    coursefees6: "63,000",
    coursenames6: "Online Certificate Program",
    coursefees6: "15,000",
    specialfeatures1:
      "UGC recognized online degree from NAAC A+ accredited university",
    specialfeatures2:
      "Leading university Professors and industry leaders to mentor you.",
    specialfeatures3:
      "Internship /job opportunity with access to job portal with 300+hiring partners.",
    specialfeatures4:
      "Get your doubts clarified from your peers Teaching Assistants quickly.",
    // specialfeatures5:
    //   "Exclusive scholarships designed for defense personnel, government employees, differently-disabled people & meritorious students.",
    onlineadvantagetitle1: "ONLINE GLA ADVANTAGES",
    onlineadvantagedesc1:
      "Efficiency & Flexibility: Teachers will deliver lessons to students in a more versatile way using online learning. Teachers have to update themselves technologically to keep up to this new way of teaching through online mediums like videos, podcasts, etc. Teachers have seen more success as instructors by integrating these online mediums into their lectures in addition to school textbooks.",
    onlineadvantagedesc2:
      "Resume Building: Personalized guidance from industry experts to build the proper resume, cover letter and 30-second video introduction along with the industry best practices by highlighting achievements, relevant skills, and experiences for the target job",
    onlineadvantagedesc3:
      "Accessibility of time and place: Another benefit of online learning is that it allows individuals to take classes from anywhere in the World. It even lets schools to branch out to a wider range of students instead of being limited by geographic restrictions",
    onlineadvantagedesc4:
      "Improved Rate of Student Attendance: Here is a lesser possibility of students missing classes since online classes can be taken from anywhere in the world. Since you have a clear e-mail line to the teacher, you will get immediate answers to your questions. For fear of being ignorant, often students are unable to ask questions in class. Hopefully, the Internet would make the anxiety obsolete.",
    onlineadvantagedesc5:
      "Adapts to a Wide Range of Learning Methodologies: The way a person comprehends information varies from person to person. Some individuals prefer to learn through film, while others may prefer to comprehend information through class lectures. Similarly, some individuals thrive in a classroom environment, while other individuals might work well when they study by themselves and find a big classroom overwhelming in terms of asking questions or answering them. The online learning experience can be customized in a numerous ways owing to the multiple ways it has to offer.",
    onlineadvantagedesc6:
      "Careers and Placements: Our unique scientific approach to career management helps individuals introspect their values and drivers, define their career goals, develop a step-by-step plan, and access relevant services to achieve the goal. As part of the career-building process, we offer the below services.",
    onlineadvantagedesc7:
      "Attractive scholarships: Attractive scholarships for defense personnel, government employees, differently-abled people & meritorious students, and special scholarships for select female applicants through Online Manipal’s #Empower her initiative.",
    onlineadvantagedesc8:
      "Placement assistance: Increase your chances of getting a job with dedicated career and placement assistance services. Attend career-readiness sessions, resume building workshops & webinars by experts, and participate in virtual placement drives..",
    univprev1:
      "GLA University, Online, a leading institution offering quality education in the digital realm. Situated in Mathura, Uttar Pradesh, GLA University Online is an esteemed member of the GLA Group of Institutions, renowned for its commitment to academic excellence and student-centered learning.",
    univprev2:
      "Established in 1998, GLA University Online provides a wide array of undergraduate, postgraduate, and doctoral programs across various disciplines, including Engineering, Management, Pharmacy, Computer Applications, Education, Humanities, and Applied Sciences. As an online platform, it caters to the diverse educational needs of students worldwide, offering accessible and flexible learning opportunities.",
    univprev3:
      "At GLA University Online, students benefit from a team of dedicated faculty members who bring a wealth of expertise and industry experience to the virtual classroom. Through interactive lectures, engaging discussions, and practical learning experiences, students receive a high-quality education that prepares them for success in their chosen fields.",
    univprev4:
      "With a focus on holistic development, GLA University Online emphasizes the importance of nurturing well-rounded individuals. Through virtual extracurricular activities, online events, and community engagement initiatives, students have the opportunity to develop leadership skills, build meaningful connections, and make a positive impact on society.",
    univprev5:
      "Whether students are looking to advance their careers, switch industries, or simply pursue their passion for learning, GLA University Online provides the flexibility, accessibility, and support needed to achieve their goals. Join GLA University Online on a journey of discovery, growth, and transformation, and unlock your full potential in the digital age.",
    collegetitle: "Online GLA University ",
    whyus: "Why Choose Online GLA University?",
    whyusdesc1:
      "Quality Education: Online GLA University offers the same high-quality education as its on-campus programs. With a strong emphasis on academic excellence, students can expect a rigorous curriculum designed to meet industry standards and prepare them for future success.",
    whyusdesc2:
      "Flexibility: Online learning at GLA University provides unmatched flexibility, allowing students to study at their own pace and convenience. Whether you're a working professional, a busy parent, or someone with other commitments, our online programs enable you to balance your studies with your personal and professional life.",
    whyusdesc3:
      "Accessibility: With Online GLA University, education is accessible to learners from all corners of the country. Regardless of your location, you can access our world-class education without the need for relocation or travel, making it a convenient option for students nationwide.",
    whyusdesc4:
      "Variety of Programs: From undergraduate to postgraduate and even doctoral programs, Online GLA University offers a diverse range of courses across various disciplines. Whether you're interested in business, engineering, healthcare, or humanities, we have a program tailored to your interests and career goals.",
    whyusdesc5:
      "Experienced Faculty: Our online programs are taught by experienced faculty members who are experts in their respective fields. With their guidance and mentorship, students receive a comprehensive learning experience that combines theoretical knowledge with practical insights.",
    whyusdesc6:
      "Interactive Learning: Online GLA University fosters interactive learning through engaging lectures, multimedia content, and collaborative discussions. Our virtual classrooms provide a dynamic environment where students can interact with faculty and peers, enhancing their understanding of the subject matter.",
    whyusdesc7:
      "Career Advancement: Whether you're looking to advance in your current career or pursue new opportunities, Online GLA University equips you with the skills and credentials needed to succeed in the job market. Our career-focused programs and industry-relevant curriculum prepare you for real-world challenges and open doors to exciting career prospects.",
    whyusdesc8:
      "Support Services: From academic advising to technical support, Online GLA University offers a range of support services to ensure that students have a smooth and seamless learning experience. Our dedicated support team is always available to assist students with any questions or concerns they may have.",
    elearningdesc1:
      "The learning material will be made available to you on the learning Management System (LMS) based on the four-quadrant approach, as per UGC (Credit Framework for online learning courses through SWAYAM) Regulation,2016.It includes a combination of the following e-learning content, namely:",
    elearningtitle1: "Video lectures",
    elearningtitle2: "E-Text materials",
    elearningtitle3: "Interactive material",
    elearningtitle4: "Virtual classroom",
    elearningtitle5: "Discussion forums",
    elearningdesc2:
      "Free Coursers on Coursera: Get exclusive access to 10,000+ courses on Coursera. Additionally.",
    elearningdesc3:
      "BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
  },
  {
    id: 4,
    partnersname: "Jain University Online",
    // partnerstitle: "22 Courses",
    partnersimg: require("../../assets/partner/jain-logo.png"),
    endlogo1: require("../../assets/partner/ugcdeb.webp"),
    endlogo2: require("../../assets/AICTE-Logo.png"),
    endlogo3: require("../../assets/National_Institutional_Ranking_Framework_logo.png"),
    endlogo4: require("../../assets/partner/naac a++.png"),
    collegename: "Jain University Online",
    collegedesc: "Accredited with NAAC A++ grade",
    whyus: "Why Jain University",
    coursenames1: "Online B.COM",
    coursefees1: "1,20,000",
    coursenames2: "Online M.COM",
    coursefees2: "1,00,000",
    coursenames3: "Online MCA",
    coursefees3: "1,30,000",
    coursenames4: "Online BBA",
    coursefees4: "1,65,000",
    coursenames5: "Online MBA",
    coursefees5: "1,50,000",
    coursenames6: "Online MBA Dual",
    coursefees6: "1,70,000",
    specialfeatures1:
      "Globally recognized UGC-approved NAAC A+ accredited the online degrees offered by GLA University Jaipur are at par with on-campus degrees.",
    specialfeatures2:
      "It opens door to grab better professional opportunities.",
    specialfeatures3:
      "The free Coursera access and choose from over 10,000+ professional certifications and courses from leading global universities while completing your degree. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    specialfeatures4:
      "Flexi-payment options allow students to pay fees in hassle-free installments. No-cost EMIs, let students learning take the spotlight without the stress of financing.",
    specialfeatures5:
      "Exclusive scholarships designed for defense personnel, government employees, differently-disabled people & meritorious students.",
    onlineadvantagetitle1: "ONLINE GLA ADVANTAGES",
    onlineadvantagedesc1:
      "UGC Entitled Degrees: Access UGC-entitled degrees from world-class universities that are NAAC accredited. Pursue online degrees that are at par with conventional on-campus degrees and accepted by governments, corporate organizations, and higher education institutions.",
    onlineadvantagedesc2:
      "Free Coursers access: Get exclusive access to 10,000+ courses on Coursera. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    onlineadvantagedesc3:
      "Industry webinars & simulations: Attend webinars by industry experts to gain industry-specific knowledge. Participate in hands-on workshops and get certified in emerging technologies like Metaverse, AI Modelling, Block chain, and more.",
    onlineadvantagedesc4:
      "Exhaustive e – content & virtual lab: Gain access to vast e-libraries with e-books.",
    onlineadvantagedesc5:
      "Convenient class schedule: Attend live classes & access recorded lectures on-the-go. Engage in live interactions with faculty members to get your doubts clarified and write online-proctored exams from the comfort of your homes by booking slots as per your convenience.",
    onlineadvantagedesc6:
      "Global classroom: Join learners from all cities , towns and countries to connect & network. Exchange ideas with a diverse peer group from various industries, domains, geographies, and experience levels.",
    onlineadvantagedesc7:
      "Attractive scholarships: Attractive scholarships for defense personnel, government employees, differently-abled people & meritorious students, and special scholarships for select female applicants through Online Manipal’s #Empower her initiative.",
    onlineadvantagedesc8:
      "Placement assistance: Increase your chances of getting a job with dedicated career and placement assistance services. Attend career-readiness sessions, resume building workshops & webinars by experts, and participate in virtual placement drives..",
    univprev1:
      "Online Jain University, Bangalore, a prestigious institution dedicated to providing quality education through digital platforms. Situated in Bangalore, Karnataka, Jain University has been a beacon of academic excellence and innovation for decades. With Online Jain University, the institution extends its commitment to accessible and flexible education to learners worldwide.",
    univprev2:
      "Online Jain University offers a diverse range of undergraduate, postgraduate Programmes. Through our online platform, students have access to the same rigorous curriculum, experienced faculty members, and modern teaching methods as their on-campus counterparts.",
    univprev3:
      "One of the key advantages of Online Jain University is its flexibility, allowing students to pursue their studies at their own pace and convenience. Whether you're a working professional, a busy parent, or someone with other commitments, our online programs enable you to balance your education with your personal and professional life.",
    univprev4:
      "Accessibility is another hallmark of Online Jain University. With our online platform, learners from all corners of the world can access quality education without the constraints of geography or distance. This inclusivity ensures that education is available to everyone, regardless of their location or background.",
    univprev5:
      "At Online Jain University, students benefit from interactive learning experiences facilitated by engaging lectures, multimedia content, and collaborative discussions. Our virtual classrooms provide a dynamic environment where students can interact with faculty members and peers, enhancing their understanding of the subject matter.",
    univprev6:
      "Online Jain University also offers a range of support services to ensure that students have a smooth and seamless learning experience. From academic advising to technical support, our dedicated team is committed to assisting students every step of the way.",
    univprev7:
      "By choosing Online Jain University, students gain access to a future-ready education that prepares them for success in the digital age and beyond. With our emphasis on innovation, industry relevance, and holistic development, Online Jain University empowers learners to achieve their academic and professional goals, all from the comfort of their own homes.",
    univprev8:
      "Join us at Online Lovely Professional University and embark on a transformative educational journey that will shape your future and unlock endless opportunities for growth and success.",
    whyus: "Why Choose Jain Online?",
    whyusdesc1:
      "Academic Excellence: Jain Online is committed to delivering high-quality education that meets international standards. With a focus on academic rigor and innovation, students can expect to receive a comprehensive and enriching learning experience.",
    whyusdesc2:
      "Flexibility: Jain Online offers flexible learning options that cater to the diverse needs of students. Whether you're a working professional, a busy parent, or someone with other commitments, our online programs allow you to study at your own pace and convenience.",
    whyusdesc3:
      "Accessibility: With Jain Online, education is accessible to learners from all corners of the globe. Our online platform eliminates geographical barriers, allowing students to access quality education from anywhere in the world.",
    whyusdesc4:
      "Diverse Range of Programs: From undergraduate to postgraduate and even doctoral programs, Jain Online offers a wide array of courses across various disciplines. Whether you're interested in business, technology, arts, or sciences, we have a program tailored to your interests and career goals.",
    whyusdesc5:
      "Experienced Faculty: Our online programs are taught by experienced faculty members who are experts in their respective fields. With their guidance and mentorship, students receive a holistic learning experience that combines theoretical knowledge with practical insights.",
    whyusdesc6:
      "Interactive Learning Environment: Jain Online fosters interactive learning through engaging lectures, multimedia content, and collaborative discussions. Our virtual classrooms provide a dynamic environment where students can interact with faculty members and peers, enhancing their understanding of the subject matter.",
    whyusdesc7:
      "Support Services: From academic advising to technical support, Jain Online offers a range of support services to ensure that students have a smooth and seamless learning experience. Our dedicated team is committed to assisting students every step of the way.",
    whyusdesc8:
      "Career Opportunities: Jain Online equips students with the skills and knowledge needed to succeed in their chosen fields. Our career-focused programs and industry-relevant curriculum prepare students for real-world challenges and open doors to exciting career prospects.",
    collegetitle: "Online Jain University ",

    whyus: "Why Choose Jain Online?",
    whyusdesc1:
      "UGC-Entitled Degrees: Access UGC-entitled degrees from world-class universities accredited by NAAC, ensuring that your online degree holds the same prestige as traditional on-campus degrees, recognized by governments, corporations, and higher education institutions.",
    whyusdesc2:
      "Attractive Scholarships: Benefit from attractive scholarships tailored for defense personnel, government employees, differently-abled individuals, meritorious students, and alumni of Manipal universities.",
    whyusdesc3:
      "Placement Assistance: Enhance your employability with dedicated career and placement assistance services, including career-readiness sessions, resume building workshops, webinars by experts, and virtual placement drives.",
    whyusdesc4:
      "Prestigious Alumni Network: Join a revered alumni network comprising over 150,000 professionals and business leaders, including luminaries such as Mr. Satya Nadella, Chef Vikas Khanna, and Dr. Devi Prasad Shetty",
    whyusdesc5:
      "Industry Webinars & Simulations: Stay updated with industry trends through webinars conducted by experts and participate in hands-on workshops to gain certification in emerging technologies.",
    whyusdesc6:
      "Holistic Development: Manipal places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Global Classroom: Engage with learners from diverse backgrounds across various industries, domains, geographies, and experience levels, fostering a rich learning environment.",
    whyusdesc8:
      "In-Person Campus Immersion: Experience an exclusive in-person event, Ekam, to connect with peers and faculty members of your online program, participate in interactive sessions, and create lasting memories.",
    elearningdesc1:
      "The learning material will be made available to you on the learning Management System (LMS) based on the four-quadrant approach, as per UGC (Credit Framework for online learning courses through SWAYAM) Regulation,2016.It includes a combination of the following e-learning content, namely:",
    elearningtitle1: "Video lectures",
    elearningtitle2: "E-Text materials",
    elearningtitle3: "Interactive material",
    elearningtitle4: "Virtual classroom",
    elearningtitle5: "Discussion forums",
    elearningdesc2:
      "Free Coursers on Coursera: Get exclusive access to 10,000+ courses on Coursera. Additionally.",
    elearningdesc3:
      "BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
  },
  {
    id: 5,
    partnersname: "AMITY Online",
    // partnerstitle: "22 Courses",
    partnersimg: require("../../assets/partner/amity.png"),
    endlogo1: require("../../assets/partner/ugcdeb.webp"),
    endlogo2: require("../../assets/AICTE-Logo.png"),
    endlogo3: require("../../assets/aiu.png"),
    endlogo4: require("../../assets/National_Institutional_Ranking_Framework_logo.png"),
    endlogo5: require("../../assets/wes.png"),
    endlogo6: require("../../assets/partner/naac a+.jpg"),
    endlogo7: require("../../assets/partner/qs.png"),
    collegename: "Amity University Online",
    collegedesc: "Accredited with NAAC A++ grade",
    whyus: "Why Amity University",
    coursenames1: "Online B.COM",
    coursefees1: "1,20,000",
    coursenames2: "Online M.COM",
    coursefees2: "1,00,000",
    coursenames3: "Online MCA",
    coursefees3: "1,30,000",
    coursenames4: "Online BBA",
    coursefees4: "1,65,000",
    coursenames5: "Online MBA",
    coursefees5: "1,50,000",
    coursenames6: "Online MBA Dual",
    coursefees6: "1,70,000",
    specialfeatures1:
      "Globally recognized UGC-approved NAAC A+ accredited the online degrees offered by Amity University are at par with on-campus degrees.",
    specialfeatures2:
      "It opens door to grab better professional opportunities.",
    specialfeatures3:
      "The free Coursera access and choose from over 10,000+ professional certifications and courses from leading global universities while completing your degree. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    specialfeatures4:
      "Flexi-payment options allow students to pay fees in hassle-free installments. No-cost EMIs, let students learning take the spotlight without the stress of financing.",
    specialfeatures5:
      "Exclusive scholarships designed for defense personnel, government employees, differently-disabled people & meritorious students.",
    onlineadvantagetitle1: "ONLINE Amity University ADVANTAGES",
    onlineadvantagedesc1:
      "UGC Entitled Degrees: Access UGC-entitled degrees from world-class universities that are NAAC accredited. Pursue online degrees that are at par with conventional on-campus degrees and accepted by governments, corporate organizations, and higher education institutions.",
    onlineadvantagedesc2:
      "Free Coursers access: Get exclusive access to 10,000+ courses on Coursera. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    onlineadvantagedesc3:
      "Industry webinars & simulations: Attend webinars by industry experts to gain industry-specific knowledge. Participate in hands-on workshops and get certified in emerging technologies like Metaverse, AI Modelling, Block chain, and more.",
    onlineadvantagedesc4:
      "Exhaustive e – content & virtual lab: Gain access to vast e-libraries with e-books.",
    onlineadvantagedesc5:
      "Convenient class schedule: Attend live classes & access recorded lectures on-the-go. Engage in live interactions with faculty members to get your doubts clarified and write online-proctored exams from the comfort of your homes by booking slots as per your convenience.",
    onlineadvantagedesc6:
      "Global classroom: Join learners from all cities , towns and countries to connect & network. Exchange ideas with a diverse peer group from various industries, domains, geographies, and experience levels.",
    onlineadvantagedesc7:
      "Attractive scholarships: Attractive scholarships for defense personnel, government employees, differently-abled people & meritorious students, and special scholarships for select female applicants through Online Manipal’s #Empower her initiative.",
    onlineadvantagedesc8:
      "Placement assistance: Increase your chances of getting a job with dedicated career and placement assistance services. Attend career-readiness sessions, resume building workshops & webinars by experts, and participate in virtual placement drives..",
    univprev1:
      "Online Amity, a pioneering institution in the realm of digital education. Situated in Jalandhar, Punjab, Lovely Professional University has been a trailblazer in providing quality education for over two decades. With Online LPU, the university extends its commitment to excellence in education to learners across the globe.",
    univprev2:
      "Online Amity offers a wide array of undergraduate, postgraduate programmes. Through online platform, students have access to the same rigorous curriculum, experienced faculty members, and modern teaching methods as their on-campus counterparts.",
    univprev3:
      "One of the key advantages of Online Amity is its flexibility, allowing students to pursue their studies at their own pace and convenience. Whether you're a working professional, a busy parent, or someone with other commitments, our online programs enable you to balance your education with your personal and professional life.",
    univprev4:
      "Accessibility is another hallmark of Online Amity. With our online platform, learners from all corners of the world can access quality education without the constraints of geography or distance. This inclusivity ensures that education is available to everyone, regardless of their location or background.",
    univprev5:
      "At Online Amity, students benefit from interactive learning experiences facilitated by engaging lectures, multimedia content, and collaborative discussions. Our virtual classrooms provide a dynamic environment where students can interact with faculty members and peers, enhancing their understanding of the subject matter.",
    univprev6:
      "Online Amity also offers a range of support services to ensure that students have a smooth and seamless learning experience. From academic advising to technical support, our dedicated team is committed to assisting students every step of the way.",
    univprev7:
      "By choosing Online Amity, students gain access to a future-ready education that prepares them for success in the digital age and beyond. With our emphasis on innovation, industry relevance, and holistic development, Online Amity empowers learners to achieve their academic and professional goals, all from the comfort of their own homes.",
    univprev8:
      "Join us at Online Amity University and embark on a transformative educational journey that will shape your future and unlock endless opportunities for growth and success.",
    whyus: "Why Choose Amity?",
    whyusdesc1:
      "Excellence in Education: Amity is renowned for its commitment to academic excellence. With a diverse range of programs and a faculty comprising experienced educators and industry experts, Amity provides students with a world-class learning experience that prepares them for success in their chosen fields.",
    whyusdesc2:
      "State-of-the-Art Infrastructure: The university boasts modern infrastructure and facilities that create an ideal environment for learning and growth. From well-equipped classrooms and laboratories to sports facilities and student accommodation, Amity ensures that students have access to everything they need to excel academically and personally.",
    whyusdesc3:
      "Global Exposure: Amity offers various international collaborations, exchange programs, and study abroad opportunities, providing students with global exposure and perspectives. These initiatives help students broaden their horizons, enhance their intercultural competencies, and prepare them for success in a globalized world.",
    whyusdesc4:
      "Industry Partnerships and Placements: Amity has strong ties with leading companies and organizations, facilitating internships, placements, and industry collaborations for students. The university's robust placement cell works tirelessly to connect students with job opportunities and prepare them for the competitive job market.",
    whyusdesc5:
      "Innovation and Research: Amity encourages innovation and research among its students and faculty members. The university provides ample opportunities for research projects, collaborations, and publications, allowing students to explore their interests and contribute to the advancement of knowledge.",
    whyusdesc6:
      "Holistic Development: Amity places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Entrepreneurship Development: Amity fosters an entrepreneurial mindset among its students, providing support and resources for aspiring entrepreneurs to turn their ideas into successful ventures. The university's entrepreneurship cell organizes workshops, seminars, and mentorship programs to help students develop their entrepreneurial skills and launch their own businesses.",
    whyusdesc8:
      "Student-Centric Approach: Above all, Amity is committed to the success and well-being of its students. The university adopts a student-centric approach, prioritizing their needs, aspirations, and overall growth and development.",
    collegetitle: "Online Amity University ",
    whyus: "Why Choose Amity University?",
    whyusdesc1:
      "UGC-Entitled Degrees: Access UGC-entitled degrees from world-class universities accredited by NAAC, ensuring that your online degree holds the same prestige as traditional on-campus degrees, recognized by governments, corporations, and higher education institutions.",
    whyusdesc2:
      "Attractive Scholarships: Benefit from attractive scholarships tailored for defense personnel, government employees, differently-abled individuals, meritorious students, and alumni of Manipal universities.",
    whyusdesc3:
      "Placement Assistance: Enhance your employability with dedicated career and placement assistance services, including career-readiness sessions, resume building workshops, webinars by experts, and virtual placement drives.",
    whyusdesc4:
      "Prestigious Alumni Network: Join a revered alumni network comprising over 150,000 professionals and business leaders, including luminaries such as Mr. Satya Nadella, Chef Vikas Khanna, and Dr. Devi Prasad Shetty",
    whyusdesc5:
      "Industry Webinars & Simulations: Stay updated with industry trends through webinars conducted by experts and participate in hands-on workshops to gain certification in emerging technologies.",
    whyusdesc6:
      "Holistic Development: Amity places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Global Classroom: Engage with learners from diverse backgrounds across various industries, domains, geographies, and experience levels, fostering a rich learning environment.",
    whyusdesc8:
      "In-Person Campus Immersion: Experience an exclusive in-person event, Ekam, to connect with peers and faculty members of your online program, participate in interactive sessions, and create lasting memories.",
    elearningdesc1:
      "The learning material will be made available to you on the learning Management System (LMS) based on the four-quadrant approach, as per UGC (Credit Framework for online learning courses through SWAYAM) Regulation,2016.It includes a combination of the following e-learning content, namely:",
    elearningtitle1: "Video lectures",
    elearningtitle2: "E-Text materials",
    elearningtitle3: "Interactive material",
    elearningtitle4: "Virtual classroom",
    elearningtitle5: "Discussion forums",
    elearningdesc2:
      "Free Coursers on Coursera: Get exclusive access to 10,000+ courses on Coursera. Additionally.",
    elearningdesc3:
      "BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
  },
  {
    id: 6,
    partnersimg: require("../../assets/partner/DPU-COL Logo png.png"),
    partnersname: "DPU Online",
    endlogo1: require("../../assets/partner/ugcdeb.webp"),
    endlogo2: require("../../assets/AICTE-Logo.png"),
    endlogo3: require("../../assets/aiu.png"),
    endlogo4: require("../../assets/National_Institutional_Ranking_Framework_logo.png"),
    endlogo5: require("../../assets/wes.png"),
    endlogo6: require("../../assets/partner/naac a++.png"),
    endlogo7: require("../../assets/ISO-Logo.png"),
    collegename: "Dy Patil university Online",
    collegedesc: "Accredited with NAAC A++ grade",
    whyus: "Why Online Dy Patil university",
    coursenames1: "Online B.COM",
    coursefees1: "1,20,000",
    coursenames2: "Online M.COM",
    coursefees2: "1,00,000",
    coursenames3: "Online MCA",
    coursefees3: "1,30,000",
    coursenames4: "Online BBA",
    coursefees4: "1,65,000",
    coursenames5: "Online MBA",
    coursefees5: "1,50,000",
    coursenames6: "Online MBA Dual",
    coursefees6: "1,70,000",
    specialfeatures1:
      "Globally recognized UGC-approved NAAC A+ accredited the online degrees offered by Dy Patil University Jaipur are at par with on-campus degrees.",
    specialfeatures2:
      "It opens door to grab better professional opportunities.",
    specialfeatures3:
      "The free Coursera access and choose from over 10,000+ professional certifications and courses from leading global universities while completing your degree. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    specialfeatures4:
      "Flexi-payment options allow students to pay fees in hassle-free installments. No-cost EMIs, let students learning take the spotlight without the stress of financing.",
    specialfeatures5:
      "Exclusive scholarships designed for defense personnel, government employees, differently-disabled people & meritorious students.",
    onlineadvantagetitle1: "ONLINE Dy Patil ADVANTAGES",
    onlineadvantagedesc1:
      "UGC Entitled Degrees: Access UGC-entitled degrees from world-class universities that are NAAC accredited. Pursue online degrees that are at par with conventional on-campus degrees and accepted by governments, corporate organizations, and higher education institutions.",
    onlineadvantagedesc2:
      "Free Coursers access: Get exclusive access to 10,000+ courses on Coursera. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    onlineadvantagedesc3:
      "Industry webinars & simulations: Attend webinars by industry experts to gain industry-specific knowledge. Participate in hands-on workshops and get certified in emerging technologies like Metaverse, AI Modelling, Block chain, and more.",
    onlineadvantagedesc4:
      "Exhaustive e – content & virtual lab: Gain access to vast e-libraries with e-books.",
    onlineadvantagedesc5:
      "Convenient class schedule: Attend live classes & access recorded lectures on-the-go. Engage in live interactions with faculty members to get your doubts clarified and write online-proctored exams from the comfort of your homes by booking slots as per your convenience.",
    onlineadvantagedesc6:
      "Global classroom: Join learners from all cities , towns and countries to connect & network. Exchange ideas with a diverse peer group from various industries, domains, geographies, and experience levels.",
    onlineadvantagedesc7:
      "Attractive scholarships: Attractive scholarships for defense personnel, government employees, differently-abled people & meritorious students, and special scholarships for select female applicants through Online Dy Patil’s #Empower her initiative.",
    onlineadvantagedesc8:
      "Placement assistance: Increase your chances of getting a job with dedicated career and placement assistance services. Attend career-readiness sessions, resume building workshops & webinars by experts, and participate in virtual placement drives..",
    univprev1:
      "DY Patil University Online, a prestigious institution offering quality education through its online platform. Situated in Pune, Maharashtra, DY Patil University Online is part of the esteemed DY Patil Group, known for its commitment to academic excellence and innovation.",
    univprev2:
      "As an online platform, it caters to the educational needs of students worldwide, offering accessible and flexible learning opportunities.",
    univprev3:
      "Recognizing the importance of flexibility in today's fast-paced world, DY Patil University Online ensures that its programs accommodate the busy schedules of working professionals, stay-at-home parents, and learners seeking to advance their education without disrupting their daily lives. With 24/7 access to course materials, interactive lectures, and online resources, students can pursue their studies at their own pace, from any location.",
    univprev4:
      "Accessibility is another hallmark of DY Patil. With our online platform, learners from all corners of the world can access quality education without the constraints of geography or distance. This inclusivity ensures that education is available to everyone, regardless of their location or background.The faculty at DY Patil University Online comprises experienced educators and industry experts who are dedicated to providing a high-quality education that is both relevant and practical. Through a blend of theoretical knowledge and hands-on learning experiences, students are equipped with the skills and knowledge needed to excel in their chosen field.",
    univprev5:
      "In addition to academic excellence, DY Patil University Online emphasizes holistic development, fostering well-rounded individuals who are socially responsible and ethically sound. Through extracurricular activities, virtual events, and community engagement initiatives, students have the opportunity to develop leadership skills, build connections, and make a positive impact on society.",
    univprev6:
      "Whether students aim to advance their careers, switch industries, or pursue their passion for learning, DY Patil University Online offers the flexibility, accessibility, and support needed to achieve their goals. Join DY Patil University Online on a journey of discovery, growth, and transformation, and unlock your full potential in the digital age.",
    univprev7:
      "GROW WITH COMFORT LEARNING Pursue UGC & AICTE Approved Online Degree Programme 100% Placement Assistance 15 In-Demand Specializations and Customized Curriculum",
    // univprev8:
    //   "Join us at Online Lovely Professional University and embark on a transformative educational journey that will shape your future and unlock endless opportunities for growth and success.",
    whyus: "Why Choose Dy Patil University (DyPU)?",
    whyusdesc1:
      "Excellence in Education: DyPU is renowned for its commitment to academic excellence. With a diverse range of programs and a faculty comprising experienced educators and industry experts, DyPU provides students with a world-class learning experience that prepares them for success in their chosen fields.",
    whyusdesc2:
      "State-of-the-Art Infrastructure: The university boasts modern infrastructure and facilities that create an ideal environment for learning and growth. From well-equipped classrooms and laboratories to sports facilities and student accommodation, DyPU ensures that students have access to everything they need to excel academically and personally.",
    whyusdesc3:
      "Global Exposure: DyPU offers various international collaborations, exchange programs, and study abroad opportunities, providing students with global exposure and perspectives. These initiatives help students broaden their horizons, enhance their intercultural competencies, and prepare them for success in a globalized world.",
    whyusdesc4:
      "Industry Partnerships and Placements: DyPU has strong ties with leading companies and organizations, facilitating internships, placements, and industry collaborations for students. The university's robust placement cell works tirelessly to connect students with job opportunities and prepare them for the competitive job market.",
    whyusdesc5:
      "Innovation and Research: DyPU encourages innovation and research among its students and faculty members. The university provides ample opportunities for research projects, collaborations, and publications, allowing students to explore their interests and contribute to the advancement of knowledge.",
    whyusdesc6:
      "Holistic Development: DyPU places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Entrepreneurship Development: DyPU fosters an entrepreneurial mindset among its students, providing support and resources for aspiring entrepreneurs to turn their ideas into successful ventures. The university's entrepreneurship cell organizes workshops, seminars, and mentorship programs to help students develop their entrepreneurial skills and launch their own businesses.",
    whyusdesc8:
      "Student-Centric Approach: Above all, DyPU is committed to the success and well-being of its students. The university adopts a student-centric approach, prioritizing their needs, aspirations, and overall growth and development.",
    collegetitle: "Online Dy Patil University ",
    whyus: "Why Choose Dy Patil University?",
    whyusdesc1:
      "UGC-Entitled Degrees: Access UGC-entitled degrees from world-class universities accredited by NAAC, ensuring that your online degree holds the same prestige as traditional on-campus degrees, recognized by governments, corporations, and higher education institutions.",
    whyusdesc2:
      "Attractive Scholarships: Benefit from attractive scholarships tailored for defense personnel, government employees, differently-abled individuals, meritorious students, and alumni of Manipal universities.",
    whyusdesc3:
      "Placement Assistance: Enhance your employability with dedicated career and placement assistance services, including career-readiness sessions, resume building workshops, webinars by experts, and virtual placement drives.",
    whyusdesc4:
      "Prestigious Alumni Network: Join a revered alumni network comprising over 150,000 professionals and business leaders, including luminaries such as Mr. Satya Nadella, Chef Vikas Khanna, and Dr. Devi Prasad Shetty",
    whyusdesc5:
      "Industry Webinars & Simulations: Stay updated with industry trends through webinars conducted by experts and participate in hands-on workshops to gain certification in emerging technologies.",
    whyusdesc6:
      "Holistic Development: DY Patil places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Global Classroom: Engage with learners from diverse backgrounds across various industries, domains, geographies, and experience levels, fostering a rich learning environment.",
    whyusdesc8:
      "In-Person Campus Immersion: Experience an exclusive in-person event, Ekam, to connect with peers and faculty members of your online program, participate in interactive sessions, and create lasting memories.",
    elearningdesc1:
      "The learning material will be made available to you on the learning Management System (LMS) based on the four-quadrant approach, as per UGC (Credit Framework for online learning courses through SWAYAM) Regulation,2016.It includes a combination of the following e-learning content, namely:",
    elearningtitle1: "Video lectures",
    elearningtitle2: "E-Text materials",
    elearningtitle3: "Interactive material",
    elearningtitle4: "Virtual classroom",
    elearningtitle5: "Discussion forums",
    elearningdesc2:
      "Free Coursers on Coursera: Get exclusive access to 10,000+ courses on Coursera. Additionally.",
    elearningdesc3:
      "BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
  },
  {
    id: 7,
    partnersimg: require("../../assets/partner/suresh-gyan-vihar-logo.png"),
    partnersname: "SGVU ",

    collegename: "Suresh Gyan Vihar University (SGVU) Online",
    collegedesc: "Accredited with NAAC A++ grade",
    whyus: "Why Online SGVU",
    coursenames1: "Online B.COM",
    coursefees1: "1,20,000",
    coursenames2: "Online M.COM",
    coursefees2: "1,00,000",
    coursenames3: "Online MCA",
    coursefees3: "1,30,000",
    coursenames4: "Online BBA",
    coursefees4: "1,65,000",
    coursenames5: "Online MBA",
    coursefees5: "1,50,000",
    coursenames6: "Online MBA Dual",
    coursefees6: "1,70,000",
    specialfeatures1:
      "Globally recognized UGC-approved NAAC A+ accredited the online degrees offered by Manipal University Jaipur are at par with on-campus degrees.",
    specialfeatures2:
      "It opens door to grab better professional opportunities.",
    specialfeatures3:
      "The free Coursera access and choose from over 10,000+ professional certifications and courses from leading global universities while completing your degree. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    specialfeatures4:
      "Flexi-payment options allow students to pay fees in hassle-free installments. No-cost EMIs, let students learning take the spotlight without the stress of financing.",
    specialfeatures5:
      "Exclusive scholarships designed for defense personnel, government employees, differently-disabled people & meritorious students.",
    onlineadvantagetitle1: "ONLINE SGVU ADVANTAGES",
    onlineadvantagedesc1:
      "UGC Entitled Degrees: Access UGC-entitled degrees from world-class universities that are NAAC accredited. Pursue online degrees that are at par with conventional on-campus degrees and accepted by governments, corporate organizations, and higher education institutions.",
    onlineadvantagedesc2:
      "Free Coursers access: Get exclusive access to 10,000+ courses on Coursera. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    onlineadvantagedesc3:
      "Industry webinars & simulations: Attend webinars by industry experts to gain industry-specific knowledge. Participate in hands-on workshops and get certified in emerging technologies like Metaverse, AI Modelling, Block chain, and more.",
    onlineadvantagedesc4:
      "Exhaustive e – content & virtual lab: Gain access to vast e-libraries with e-books.",
    onlineadvantagedesc5:
      "Convenient class schedule: Attend live classes & access recorded lectures on-the-go. Engage in live interactions with faculty members to get your doubts clarified and write online-proctored exams from the comfort of your homes by booking slots as per your convenience.",
    onlineadvantagedesc6:
      "Global classroom: Join learners from all cities , towns and countries to connect & network. Exchange ideas with a diverse peer group from various industries, domains, geographies, and experience levels.",
    onlineadvantagedesc7:
      "Attractive scholarships: Attractive scholarships for defense personnel, government employees, differently-abled people & meritorious students, and special scholarships for select female applicants through Online Manipal’s #Empower her initiative.",
    onlineadvantagedesc8:
      "Placement assistance: Increase your chances of getting a job with dedicated career and placement assistance services. Attend career-readiness sessions, resume building workshops & webinars by experts, and participate in virtual placement drives..",
    univprev1:
      "Suresh Gyan Vihar University (SGVU) is a not-for-profit autonomous private university located in Jaipur, Rajasthan, India. In 2017, the university became the first private university in Rajasthan to be awarded an 'A' grade by National Assessment and Accreditation Council. The university was established through the Suresh Gyan Vihar University, Jaipur Act (Act no. 16 of 2008) of the government of Rajasthan. Its predecessor institution, Gyan Vihar College, Jaipur, had been in existence since 1999. Its parent institution, Sahitya Sadawart Samiti, was founded in 1938. Suresh Gyan Vihar University was also granted with NIRF ranking in the year 2021 in the 150-200 band.",
    univprev2:
      "The university has launched tie-ups with various industries in the past few years as an effort to introduce project-based learning. These include IBM, Harvard Business Publishing, Amazon, Google and Bombay Stock Exchange. IBM and Gyan Vihar have combined to start programs in B.Tech. artificial intelligence, Cloud Computing, Data Analytics and Internet of Things.",
    // univprev3:
    //   "The examination is conducted in online mode using LMS. These online examinations are Web proctored to ensure the integrity of the exam.",
    collegetitle: "Online SGVU ",
    whyus: "Why Choose SGVU?",
    whyusdesc1:
      "UGC-Entitled Degrees: Access UGC-entitled degrees from world-class universities accredited by NAAC, ensuring that your online degree holds the same prestige as traditional on-campus degrees, recognized by governments, corporations, and higher education institutions.",
    whyusdesc2:
      "Attractive Scholarships: Benefit from attractive scholarships tailored for defense personnel, government employees, differently-abled individuals, meritorious students, and alumni of Manipal universities.",
    whyusdesc3:
      "Placement Assistance: Enhance your employability with dedicated career and placement assistance services, including career-readiness sessions, resume building workshops, webinars by experts, and virtual placement drives.",
    whyusdesc4:
      "Prestigious Alumni Network: Join a revered alumni network comprising over 150,000 professionals and business leaders, including luminaries such as Mr. Satya Nadella, Chef Vikas Khanna, and Dr. Devi Prasad Shetty",
    whyusdesc5:
      "Industry Webinars & Simulations: Stay updated with industry trends through webinars conducted by experts and participate in hands-on workshops to gain certification in emerging technologies.",
    whyusdesc6:
      "Holistic Development: SGVU places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Global Classroom: Engage with learners from diverse backgrounds across various industries, domains, geographies, and experience levels, fostering a rich learning environment.",
    whyusdesc8:
      "In-Person Campus Immersion: Experience an exclusive in-person event, Ekam, to connect with peers and faculty members of your online program, participate in interactive sessions, and create lasting memories.",
    elearningdesc1:
      "The learning material will be made available to you on the learning Management System (LMS) based on the four-quadrant approach, as per UGC (Credit Framework for online learning courses through SWAYAM) Regulation,2016.It includes a combination of the following e-learning content, namely:",
    elearningtitle1: "Video lectures",
    elearningtitle2: "E-Text materials",
    elearningtitle3: "Interactive material",
    elearningtitle4: "Virtual classroom",
    elearningtitle5: "Discussion forums",
    elearningdesc2:
      "Free Coursers on Coursera: Get exclusive access to 10,000+ courses on Coursera. Additionally.",
    elearningdesc3:
      "BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
  },
  {
    id: 8,
    partnersimg: require("../../assets/partner/smu.png"),
    partnersname: "SMU Online",
    endlogo1: require("../../assets/ugc.png"),
    endlogo2: require("../../assets/National_Institutional_Ranking_Framework_logo.png"),
    endlogo3: require("../../assets/partner/naac a+.jpg"),
    collegename: "Sikkim Manipal University",
    collegedesc: "Accredited with NAAC A+ grade",
    whyus: "Why Sikkim Manipal University",
    coursenames1: "Online B.COM",
    coursefees1: "75,000",
    coursenames2: "Online M.COM",
    coursefees2: "98,000",
    coursenames3: "Online BA",
    coursefees3: "75,000",
    coursenames4: "Online MA",
    coursefees4: "75,000",
    // coursenames5: "Online MBA",
    // coursefees5: "1,50,000",
    // coursenames6: "Online MBA Dual",
    // coursefees6: "1,70,000",
    specialfeatures1:
      "Globally recognized UGC-approved NAAC A+ accredited the online degrees offered by Sikkim Manipal University Jaipur are at par with on-campus degrees.",
    specialfeatures2:
      "It opens door to grab better professional opportunities.",
    specialfeatures3:
      "The free Coursera access and choose from over 10,000+ professional certifications and courses from leading global universities while completing your degree. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    specialfeatures4:
      "Flexi-payment options allow students to pay fees in hassle-free installments. No-cost EMIs, let students learning take the spotlight without the stress of financing.",
    specialfeatures5:
      "Exclusive scholarships designed for defense personnel, government employees, differently-disabled people & meritorious students.",
    onlineadvantagetitle1: "Why Online Sikkim Manipal",
    onlineadvantagedesc1:
      "UGC Entitled Degrees: Access UGC-entitled degrees from world-class universities that are NAAC accredited. Pursue online degrees that are at par with conventional on-campus degrees and accepted by governments, corporate organizations, and higher education institutions.",
    onlineadvantagedesc2:
      "Free Coursers access: Get exclusive access to 10,000+ courses on Coursera. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    onlineadvantagedesc3:
      "Industry webinars & simulations: Attend webinars by industry experts to gain industry-specific knowledge. Participate in hands-on workshops and get certified in emerging technologies like Metaverse, AI Modelling, Block chain, and more.",
    onlineadvantagedesc4:
      "Exhaustive e – content & virtual lab: Gain access to vast e-libraries with e-books.",
    onlineadvantagedesc5:
      "Convenient class schedule: Attend live classes & access recorded lectures on-the-go. Engage in live interactions with faculty members to get your doubts clarified and write online-proctored exams from the comfort of your homes by booking slots as per your convenience.",
    onlineadvantagedesc6:
      "Global classroom: Join learners from all cities , towns and countries to connect & network. Exchange ideas with a diverse peer group from various industries, domains, geographies, and experience levels.",
    onlineadvantagedesc7:
      "Attractive scholarships: Attractive scholarships for defense personnel, government employees, differently-abled people & meritorious students, and special scholarships for select female applicants through Online Manipal’s #Empower her initiative.",
    onlineadvantagedesc8:
      "Placement assistance: Increase your chances of getting a job with dedicated career and placement assistance services. Attend career-readiness sessions, resume building workshops & webinars by experts, and participate in virtual placement drives..",
    univprev1:
      "Sikkim Manipal University (SMU) - Where Excellence Meets Innovation!",
    univprev2:
      "Established in 1995, Sikkim Manipal University (SMU) has been at the forefront of educational excellence, redefining the boundaries of learning and skill development. Its mission is simple yet profound: to empower individuals in Sikkim and beyond, contributing to the holistic development of the Northeast region and other parts of India.",
    univprev3:
      "At SMU, the focus is on interdisciplinary education to prepare students for the challenges of today's dynamic world. With a relentless commitment to continuous innovation and leadership, SMU is dedicated to delivering programs that not only meet but exceed the expectations of its learners.",
    univprev4:
      "Accessibility is another hallmark of Online SMU. With our online platform, learners from all corners of the world can access quality education without the constraints of geography or distance. This inclusivity ensures that education is available to everyone, regardless of their location or background.Sikkim Manipal University Online, a leading institution offering quality education through its online platform. Situated in Sikkim, India, Sikkim Manipal University Online is part of the renowned Manipal Education Group, known for its commitment to academic excellence and innovation.",
    univprev5:
      "Sikkim Manipal University Online provides a diverse range of undergraduate, postgraduate, and doctoral programs across various disciplines, including Engineering, Management, Computer Applications, Education, Humanities, and Applied Sciences. As an online platform, it caters to the educational needs of students worldwide, offering accessible and flexible learning opportunities.",
    univprev6:
      "Understanding the importance of flexibility in today's fast-paced world, Sikkim Manipal University Online ensures that its programs accommodate the busy schedules of working professionals, stay-at-home parents, and learners seeking to advance their education without disrupting their daily lives. With 24/7 access to course materials, interactive lectures, and online resources, students can pursue their studies at their own pace, from any location.",
    univprev7:
      "The faculty at Sikkim Manipal University Online comprises experienced educators and industry experts who are dedicated to providing a high-quality education that is both relevant and practical. Through a blend of theoretical knowledge and hands-on learning experiences, students are equipped with the skills and knowledge needed to excel in their chosen field.",
    univprev8:
      "In addition to academic excellence, Sikkim Manipal University Online emphasizes holistic development, fostering well-rounded individuals who are socially responsible and ethically sound. Through extracurricular activities, virtual events, and community engagement initiatives, students have the opportunity to develop leadership skills, build connections, and make a positive impact on society.",
    whyus: "Why Choose SMU for Your Online Learning Journey?",
    whyusdesc1:
      "Legacy of Excellence: With over 27 years of experience in delivering quality education, SMU boasts a rich legacy of academic brilliance and student success.",
    whyusdesc2:
      "NAAC A+ Accreditation: SMU's commitment to quality is evident in its NAAC A+ accreditation, ensuring that its programs meet the highest standards of academic excellence.",
    whyusdesc3:
      "UGC-Entitled Degrees: When students choose SMU, they're not just earning a degree – they're earning a credential recognized and respected by governments, corporations, and institutions worldwide.",
    whyusdesc4:
      "Flexible Learning Options: With SMU's online learning platform, students have the flexibility to pursue their education on their own terms. They can access their courses anytime, anywhere, and tailor their learning experience to fit their busy schedule.",
    whyusdesc5:
      "Interactive Learning Environment: Students engage with SMU's dedicated faculty and fellow learners through live sessions, discussions, and collaborative projects. The interactive learning platform ensures that students are not just passive observers but active participants in their education.",
    whyusdesc6:
      "Hands-On Experience: Students gain practical skills and experience through virtual labs, simulations, and real-world projects. At SMU, learning by doing is emphasized, and online programs are designed to provide students with the hands-on experience they need to succeed in their chosen field.",
    whyusdesc7:
      "Global Perspective: Students interact with learners from diverse backgrounds, gaining insights into different cultures, perspectives, and industries. SMU's global classroom fosters collaboration and networking opportunities that extend far beyond the virtual classroom walls.",
    whyusdesc8:
      "Career Support Services: SMU's dedicated career support team is here to help students every step of the way, from personalized career guidance to resume assistance and networking opportunities. SMU is committed to helping students achieve their career goals and aspirations. Join Sikkim Manipal University and embark on a transformative online learning journey that opens doors to endless possibilities. With SMU, the future of education is bright and promising.",
    collegetitle: "Online Sikkim Manipal University ",
    whyus: "Why Choose SMU for Your Online Learning Journey?",
    whyusdesc1:
      "UGC-Entitled Degrees: Access UGC-entitled degrees from world-class universities accredited by NAAC, ensuring that your online degree holds the same prestige as traditional on-campus degrees, recognized by governments, corporations, and higher education institutions.",
    whyusdesc2:
      "Attractive Scholarships: Benefit from attractive scholarships tailored for defense personnel, government employees, differently-abled individuals, meritorious students, and alumni of sikkim Manipal universities.",
    whyusdesc3:
      "Placement Assistance: Enhance your employability with dedicated career and placement assistance services, including career-readiness sessions, resume building workshops, webinars by experts, and virtual placement drives.",
    whyusdesc4:
      "Prestigious Alumni Network: Join a revered alumni network comprising over 150,000 professionals and business leaders, including luminaries such as Mr. Satya Nadella, Chef Vikas Khanna, and Dr. Devi Prasad Shetty",
    whyusdesc5:
      "Industry Webinars & Simulations: Stay updated with industry trends through webinars conducted by experts and participate in hands-on workshops to gain certification in emerging technologies.",
    whyusdesc6:
      "Holistic Development: SMU places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Global Classroom: Engage with learners from diverse backgrounds across various industries, domains, geographies, and experience levels, fostering a rich learning environment.",
    whyusdesc8:
      "In-Person Campus Immersion: Experience an exclusive in-person event, Ekam, to connect with peers and faculty members of your online program, participate in interactive sessions, and create lasting memories.",
    elearningdesc1:
      "The learning material will be made available to you on the learning Management System (LMS) based on the four-quadrant approach, as per UGC (Credit Framework for online learning courses through SWAYAM) Regulation,2016.It includes a combination of the following e-learning content, namely:",
    elearningtitle1: "Video lectures",
    elearningtitle2: "E-Text materials",
    elearningtitle3: "Interactive material",
    elearningtitle4: "Virtual classroom",
    elearningtitle5: "Discussion forums",
    elearningdesc2:
      "Free Coursers on Coursera: Get exclusive access to 10,000+ courses on Coursera. Additionally.",
    elearningdesc3:
      "BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
  },
  {
    id: 9,
    partnersimg: require("../../assets/partner/chandigarh-university-logo.png"),
    partnersname: "CU Online",
    endlogo1: require("../../assets/partner/ugcdeb.webp"),
    endlogo2: require("../../assets/AICTE-Logo.png"),
    endlogo3: require("../../assets/aiu.png"),
    endlogo4: require("../../assets/National_Institutional_Ranking_Framework_logo.png"),
    endlogo5: require("../../assets/wes.png"),
    endlogo6: require("../../assets/partner/naac a+.jpg"),
    endlogo7: require("../../assets/partner/qs.png"),
    endlogo8: require("../../assets/hbpe.jpg"),
    endlogo9: require("../../assets/ACCA.jpg"),
    collegename: "JECRC University",
    collegedesc: "Accredited with NAAC A++ grade",
    whyus: "Why JECRC University",
    coursenames1: "Online B.COM",
    coursefees1: "1,20,000",
    coursenames2: "Online M.COM",
    coursefees2: "1,00,000",
    coursenames3: "Online MCA",
    coursefees3: "1,30,000",
    coursenames4: "Online BBA",
    coursefees4: "1,65,000",
    coursenames5: "Online MBA",
    coursefees5: "1,50,000",
    coursenames6: "Online MBA Dual",
    coursefees6: "1,70,000",
    specialfeatures1:
      "Globally recognized UGC-approved NAAC A+ accredited the online degrees offered by JECRC University are at par with on-campus degrees.",
    specialfeatures2:
      "It opens door to grab better professional opportunities.",
    specialfeatures3:
      "The free Coursera access and choose from over 10,000+ professional certifications and courses from leading global universities while completing your degree. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    specialfeatures4:
      "Flexi-payment options allow students to pay fees in hassle-free installments. No-cost EMIs, let students learning take the spotlight without the stress of financing.",
    specialfeatures5:
      "Exclusive scholarships designed for defense personnel, government employees, differently-disabled people & meritorious students.",
    onlineadvantagetitle1: "ONLINE JECRC ADVANTAGES",
    onlineadvantagedesc1:
      "UGC Entitled Degrees: Access UGC-entitled degrees from world-class universities that are NAAC accredited. Pursue online degrees that are at par with conventional on-campus degrees and accepted by governments, corporate organizations, and higher education institutions.",
    onlineadvantagedesc2:
      "Free Coursers access: Get exclusive access to 10,000+ courses on Coursera. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    onlineadvantagedesc3:
      "Industry webinars & simulations: Attend webinars by industry experts to gain industry-specific knowledge. Participate in hands-on workshops and get certified in emerging technologies like Metaverse, AI Modelling, Block chain, and more.",
    onlineadvantagedesc4:
      "Exhaustive e – content & virtual lab: Gain access to vast e-libraries with e-books.",
    onlineadvantagedesc5:
      "Convenient class schedule: Attend live classes & access recorded lectures on-the-go. Engage in live interactions with faculty members to get your doubts clarified and write online-proctored exams from the comfort of your homes by booking slots as per your convenience.",
    onlineadvantagedesc6:
      "Global classroom: Join learners from all cities , towns and countries to connect & network. Exchange ideas with a diverse peer group from various industries, domains, geographies, and experience levels.",
    onlineadvantagedesc7:
      "Attractive scholarships: Attractive scholarships for defense personnel, government employees, differently-abled people & meritorious students, and special scholarships for select female applicants through Online Sikkim Manipal’s #Empower her initiative.",
    onlineadvantagedesc8:
      "Placement assistance: Increase your chances of getting a job with dedicated career and placement assistance services. Attend career-readiness sessions, resume building workshops & webinars by experts, and participate in virtual placement drives..",
    univprev1:
      "Online Chandigarh University, a leading institution in the realm of digital education. Located in Chandigarh, Punjab, Chandigarh University has established itself as a hub of academic excellence and innovation. With Online Chandigarh University, the institution extends its commitment to accessible and flexible education to learners worldwide.",
    univprev2:
      "Online Chandigarh University offers a diverse range of undergraduate, postgraduate. Through our online platform, students have access to the same rigorous curriculum, experienced faculty members, and modern teaching methods as their on-campus counterparts.",
    univprev3:
      "One of the key advantages of Online Chandigarh University is its flexibility, allowing students to pursue their studies at their own pace and convenience. Whether you're a working professional, a busy parent, or someone with other commitments, our online programs enable you to balance your education with your personal and professional life.",
    univprev4:
      "Accessibility is another hallmark of Online Chandigarh University. With our online platform, learners from all corners of the world can access quality education without the constraints of geography or distance. This inclusivity ensures that education is available to everyone, regardless of their location or background.",
    univprev5:
      "At Online Chandigarh University, students benefit from interactive learning experiences facilitated by engaging lectures, multimedia content, and collaborative discussions. Our virtual classrooms provide a dynamic environment where students can interact with faculty members and peers, enhancing their understanding of the subject matter.",
    univprev6:
      "Online Chandigarh University also offers a range of support services to ensure that students have a smooth and seamless learning experience. From academic advising to technical support, our dedicated team is committed to assisting students every step of the way.",
    univprev7:
      "By choosing Online Chandigarh University, students gain access to a future-ready education that prepares them for success in the digital age and beyond. With our emphasis on innovation, industry relevance, and holistic development, Online Chandigarh University empowers learners to achieve their academic and professional goals, all from the comfort of their own homes.",
    univprev8:
      "Join Online Chandigarh University and embark on a transformative educational journey that will shape your future and unlock endless opportunities for growth and success.",
    whyus: "Why Choose Lovely Professional University (CU)?",
    whyusdesc1:
      "Excellence in Education: CU is renowned for its commitment to academic excellence. With a diverse range of programs and a faculty comprising experienced educators and industry experts, CU provides students with a world-class learning experience that prepares them for success in their chosen fields.",
    whyusdesc2:
      "State-of-the-Art Infrastructure: The university boasts modern infrastructure and facilities that create an ideal environment for learning and growth. From well-equipped classrooms and laboratories to sports facilities and student accommodation, CU ensures that students have access to everything they need to excel academically and personally.",
    whyusdesc3:
      "Global Exposure: CU offers various international collaborations, exchange programs, and study abroad opportunities, providing students with global exposure and perspectives. These initiatives help students broaden their horizons, enhance their intercultural competencies, and prepare them for success in a globalized world.",
    whyusdesc4:
      "Industry Partnerships and Placements: CU has strong ties with leading companies and organizations, facilitating internships, placements, and industry collaborations for students. The university's robust placement cell works tirelessly to connect students with job opportunities and prepare them for the competitive job market.",
    whyusdesc5:
      "Innovation and Research: CU encourages innovation and research among its students and faculty members. The university provides ample opportunities for research projects, collaborations, and publications, allowing students to explore their interests and contribute to the advancement of knowledge.",
    whyusdesc6:
      "Holistic Development: CU places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Entrepreneurship Development: CU fosters an entrepreneurial mindset among its students, providing support and resources for aspiring entrepreneurs to turn their ideas into successful ventures. The university's entrepreneurship cell organizes workshops, seminars, and mentorship programs to help students develop their entrepreneurial skills and launch their own businesses.",
    whyusdesc8:
      "Student-Centric Approach: Above all, CU is committed to the success and well-being of its students. The university adopts a student-centric approach, prioritizing their needs, aspirations, and overall growth and development.",
    collegetitle: "Online Chandigarh University? ",
    whyus: "Why Choose Online Chandigarh University?",
    whyusdesc1:
      "Academic Excellence: Online Chandigarh University upholds the same standards of academic excellence as its on-campus programs. With a focus on quality education and innovative teaching methods, students can expect to receive a comprehensive and enriching learning experience.",
    whyusdesc2:
      "Flexibility: Online learning at Chandigarh University offers unmatched flexibility, allowing students to study at their own pace and convenience. Whether you're a working professional, a busy parent, or someone with other commitments, our online programs enable you to balance your education with your personal and professional life.",
    whyusdesc3:
      "Accessibility: With Online Chandigarh University, education is accessible to learners from all corners of the world. Our online platform eliminates geographical barriers, allowing students to access quality education from anywhere, at any time.",
    whyusdesc4:
      "Diverse Range of Programs: From undergraduate to postgraduate and doctoral programs, Online Chandigarh University offers a wide array of courses across various disciplines. Whether you're interested in engineering, management, computer science, or humanities, we have a program tailored to your interests and career goals.",
    whyusdesc5:
      "Experienced Faculty: Our online programs are taught by experienced faculty members who are experts in their respective fields. With their guidance and mentorship, students receive a holistic learning experience that combines theoretical knowledge with practical insights.",
    whyusdesc6:
      "Interactive Learning Environment: Online Chandigarh University fosters interactive learning through engaging lectures, multimedia content, and collaborative discussions. Our virtual classrooms provide a dynamic environment where students can interact with faculty members and peers, enhancing their understanding of the subject matter.",
    whyusdesc7:
      "Support Services: From academic advising to technical support, Online Chandigarh University offers a range of support services to ensure that students have a smooth and seamless learning experience. Our dedicated team is committed to assisting students every step of the way.",
    whyusdesc8:
      "Career Opportunities: Online Chandigarh University equips students with the skills and knowledge needed to succeed in their chosen fields. Our career-focused programs and industry-relevant curriculum prepare students for real-world challenges and open doors to exciting career prospects.",
    elearningdesc1:
      "The learning material will be made available to you on the learning Management System (LMS) based on the four-quadrant approach, as per UGC (Credit Framework for online learning courses through SWAYAM) Regulation,2016.It includes a combination of the following e-learning content, namely:",
    elearningtitle1: "Video lectures",
    elearningtitle2: "E-Text materials",
    elearningtitle3: "Interactive material",
    elearningtitle4: "Virtual classroom",
    elearningtitle5: "Discussion forums",
    elearningdesc2:
      "Free Coursers on Coursera: Get exclusive access to 10,000+ courses on Coursera. Additionally.",
    elearningdesc3:
      "BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
  },
  {
    id: 10,
    partnersimg: require("../../assets/partner/vgu_logo.jpg"),
    partnersname: "VGU Online",
    endlogo1: require("../../assets/ugc.png"),
    endlogo2: require("../../assets/AICTE-Logo.png"),
    endlogo3: require("../../assets/aiu.png"),
    endlogo4: require("../../assets/partner/naac a+.jpg"),

    collegename: "Vivekananda Global University Online",
    collegedesc: "Accredited with NAAC A++ grade",
    whyus: "Why Vivekananda Global University Online",
    coursenames1: "Online B.COM",
    coursefees1: "1,20,000",
    coursenames2: "Online M.COM",
    coursefees2: "1,00,000",
    coursenames3: "Online MCA",
    coursefees3: "1,30,000",
    coursenames4: "Online BBA",
    coursefees4: "1,65,000",
    coursenames5: "Online MBA",
    coursefees5: "1,50,000",
    coursenames6: "Online MBA Dual",
    coursefees6: "1,70,000",
    specialfeatures1: "Online Programs offered by Online VGU.",
    specialfeatures2:
      "Universally accepted Degree from UGC recognized & NAAC A+ Accredited University.",
    specialfeatures3:
      "Best in Class Online Learning with Modern tools and learning methodology.",
    specialfeatures4:
      "Industry oriented Skill enhancing curriculum with highly qualified professors.",
    specialfeatures5:
      "100% Placement support and proper guidance to crack the Interviews.",
    onlineadvantagetitle1: "ONLINE VGU ADVANTAGES",
    onlineadvantagedesc1:
      "High-quality Education And Research: As a student of VGU, you’ll have an excellent learning eperience. VGU offers quality higher education qualifications that are respected by employers and academics worldwide. Quality research underlines an innovative learning and teaching environment, with the faculty leading from the front in terms of internationally acclaimed research. Moreover, such cutting edge infrastructure that drives the research culture is available at few other places in the country.",
    onlineadvantagedesc2:
      "A Strong Focus On Graduate Employability: VGU has a concrete relationship with some of the world’s leading companies. More than 130 companies, including the likes of Amazon, Wipro, JP Morgan Chase, Dell, Hewlett-Packard (hp), Accenture, Infosys, Intel, Samsung, Mahindra, Bosch, TCS and so forth, hire from VGU every year. All recruiters so far have been very happy with the attitude and skills of students, which can be partly credited to the teaching faculty, partly to the highly active innovations and incubation hub, which gives a platform for students to play around with ideas.",
    onlineadvantagedesc3:
      "A Wide Range of Extracurricular Activities: Students of VGU stand head and shoulders above their peers because of the campus life that grooms them, improves soft skills and instills a sense of social responsibility. A highly active chapter of IEEE raises the caliber of students, strengthens ambition, explores managerial skills, nurtures technical interests, accelerates career goals and contributes productive and socially committed engineers to the society. The ISTE chapter of VGU is a vibrant body that organizes fresher’s orientation programs, industrial visits, career guidance courses, mock tests of CAT, GATE etc. The Cultural and Theatre Association of VGU works with the mission to promote cultural, educational and arts based activities and to develop skills and abilities in the fields of music, dance and theatre. ",
    onlineadvantagedesc4:
      "An International Outlook and Community: VGU is proud of its international outlook and the variety of nationalities that can be found on the campus, including academic staff from across the globe. As an international student, you will be a valued member of our university community and you will find outstanding support.",
    onlineadvantagedesc5:
      "Stunning Surroundings and Historic Sites: The awe-inspiring natural landscapes of Rajasthan makes the student experience very special. Historic forts, breath taking palaces and their architecture, local crafts like gem cutting, the Old City's Jewel Bazaar and the never ending festivals make the VGU student experience all the more special.",
    // onlineadvantagedesc6:
    //   "Global classroom: Join learners from all cities , towns and countries to connect & network. Exchange ideas with a diverse peer group from various industries, domains, geographies, and experience levels.",
    // onlineadvantagedesc7:
    //   "Attractive scholarships: Attractive scholarships for defense personnel, government employees, differently-abled people & meritorious students, and special scholarships for select female applicants through Online Manipal’s #Empower her initiative.",
    // onlineadvantagedesc8:
    //   "Placement assistance: Increase your chances of getting a job with dedicated career and placement assistance services. Attend career-readiness sessions, resume building workshops & webinars by experts, and participate in virtual placement drives..",
    univprev1:
      "Online VIVEKANANDA GLOBAL University, Jaipur, a premier institution committed to providing quality education through digital platforms. Located in the vibrant city of Jaipur, Rajasthan, VGU University has established itself as a center of academic excellence and innovation. With Online VGU University, the institution extends its mission of accessible and flexible education to learners worldwide.",
    univprev2:
      "Online VGU University offers a diverse range of undergraduate, and postgraduate programmes. Through online platform, students have access to the same rigorous curriculum, esteemed faculty members, and modern teaching methodologies as their on-campus counterparts.",
    univprev3:
      "Flexibility is a key advantage of Online VGU University, enabling students to pursue their studies at their own pace and convenience. Whether you're a working professional, a busy parent, or someone with other commitments, our online programs empower you to balance your education with your personal and professional life.",
    univprev4:
      "Accessibility is another hallmark of Online VGU University. Our online platform transcends geographical boundaries, allowing learners from diverse backgrounds and locations to access quality education. Regardless of where you are, you can embark on your academic journey with VGU University from the comfort of your own home.",
    univprev5:
      "At Online VGU University, students benefit from interactive learning experiences facilitated by engaging lectures, multimedia content, and collaborative discussions. Our virtual classrooms provide a dynamic environment where students can interact with faculty members and peers, fostering a rich learning experience.",
    univprev6:
      "We understand that support is essential for success in online learning. That's why Online VGU University offers comprehensive support services, including academic guidance, technical assistance, and counseling. Our dedicated team is committed to ensuring that students have a seamless and enriching learning experience.",
    univprev7:
      "By choosing Online VGU University, students gain access to a future-ready education that equips them with the skills and knowledge needed to thrive in today's rapidly evolving world. With our emphasis on innovation, industry relevance, and holistic development, Online VGU University empowers learners to achieve their academic and professional aspirations.",
    univprev8:
      "Join us at Online VGU University and embark on a transformative educational journey that will shape your future and open doors to endless opportunities for growth and success.",
    collegetitle: "Online Manipal University ",
    whyus: "Why Choose Vivekananda Global University Online?",
    whyusdesc1:
      "High-quality Education And Research: As a student of VGU, you’ll have an excellent learning eperience. VGU offers quality higher education qualifications that are respected by employers and academics worldwide. Quality research underlines an innovative learning and teaching environment, with the faculty leading from the front in terms of internationally acclaimed research. Moreover, such cutting edge infrastructure that drives the research culture is available at few other places in the country.",
    whyusdesc2:
      "A Strong Focus On Graduate Employability: VGU has a concrete relationship with some of the world’s leading companies. More than 130 companies, including the likes of Amazon, Wipro, JP Morgan Chase, Dell, Hewlett-Packard (hp), Accenture, Infosys, Intel, Samsung, Mahindra, Bosch, TCS and so forth, hire from VGU every year. All recruiters so far have been very happy with the attitude and skills of students, which can be partly credited to the teaching faculty, partly to the highly active innovations and incubation hub, which gives a platform for students to play around with ideas.",
    whyusdesc3:
      "A Wide Range of Extracurricular Activities: Students of VGU stand head and shoulders above their peers because of the campus life that grooms them, improves soft skills and instills a sense of social responsibility. A highly active chapter of IEEE raises the caliber of students, strengthens ambition, explores managerial skills, nurtures technical interests, accelerates career goals and contributes productive and socially committed engineers to the society. The ISTE chapter of VGU is a vibrant body that organizes fresher’s orientation programs, industrial visits, career guidance courses, mock tests of CAT, GATE etc. The Cultural and Theatre Association of VGU works with the mission to promote cultural, educational and arts based activities and to develop skills and abilities in the fields of music, dance and theatre.",
    whyusdesc4:
      "An International Outlook and Community: VGU is proud of its international outlook and the variety of nationalities that can be found on the campus, including academic staff from across the globe. As an international student, you will be a valued member of our university community and you will find outstanding support.",
    whyusdesc5:
      "Stunning Surroundings and Historic Sites: The awe-inspiring natural landscapes of Rajasthan makes the student experience very special. Historic forts, breath taking palaces and their architecture, local crafts like gem cutting, the Old City's Jewel Bazaar and the never ending festivals make the VGU student experience all the more special.",
    whyusdesc6:
      "Holistic Development: SGVU places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Global Classroom: Engage with learners from diverse backgrounds across various industries, domains, geographies, and experience levels, fostering a rich learning environment.",
    whyusdesc8:
      "In-Person Campus Immersion: Experience an exclusive in-person event, Ekam, to connect with peers and faculty members of your online program, participate in interactive sessions, and create lasting memories.",
    elearningdesc1:
      "The learning material will be made available to you on the learning Management System (LMS) based on the four-quadrant approach, as per UGC (Credit Framework for online learning courses through SWAYAM) Regulation,2016.It includes a combination of the following e-learning content, namely:",
    elearningtitle1: "Video lectures",
    elearningtitle2: "E-Text materials",
    elearningtitle3: "Interactive material",
    elearningtitle4: "Virtual classroom",
    elearningtitle5: "Discussion forums",
    elearningdesc2:
      "Free Coursers on Coursera: Get exclusive access to 10,000+ courses on Coursera. Additionally.",
    elearningdesc3:
      "BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
  },
  {
    id: 11,
    partnersimg: require("../../assets/partner/parullogo.png"),
    partnersname: "Parul Online",
    endlogo1: require("../../assets/ugc.png"),
    endlogo2: require("../../assets/AICTE-Logo.png"),
    endlogo3: require("../../assets/aiu.png"),
    endlogo4: require("../../assets/National_Institutional_Ranking_Framework_logo.png"),
    endlogo5: require("../../assets/partner/naac a++.png"),

    collegename: "Parul University Online",
    collegedesc: "Accredited with NAAC A++ grade",
    whyus: "Why Online Parul",
    coursenames1: "Online B.COM",
    coursefees1: "1,20,000",
    coursenames2: "Online M.COM",
    coursefees2: "1,00,000",
    coursenames3: "Online MCA",
    coursefees3: "1,30,000",
    coursenames4: "Online BBA",
    coursefees4: "1,65,000",
    coursenames5: "Online MBA",
    coursefees5: "1,50,000",
    coursenames6: "Online MBA Dual",
    coursefees6: "1,70,000",
    specialfeatures1:
      "Globally recognized UGC-approved NAAC A++ accredited the online degrees offered by Parul University.",
    specialfeatures2:
      "It opens door to grab better professional opportunities.",
    specialfeatures3:
      "The shortcomings of physical distances by providing 24X7 accessibility through its various useful features.",
    specialfeatures4:
      "Effective Training And Support For Careers In The Armed.",
    specialfeatures5:
      "Coaching For UPSC & Other Competitive Examinations For Civil Service",
    onlineadvantagetitle1: "ONLINE PARUL ADVANTAGES",
    onlineadvantagedesc1:
      "Mentorship: Our students have access to a team of experienced mentors who provide guidance and support throughout their MBA journey.",
    onlineadvantagedesc2:
      "100% Placement Support: We have a dedicated career services team that helps students identify career Opportunities and develop the skills needed to succeed in today's job market.",
    onlineadvantagedesc3:
      "Practical Learning: Our curriculum is designed to provide practical, hands-on learning experiences that help Students apply their knowledge in real-world settings.",
    onlineadvantagedesc4:
      "Top Notch Professors: Our platform is user-friendly, intuitive, and designed to provide proactive digital Support.",
    onlineadvantagedesc5:
      "Digital Library: Our extensive digital library provides students with access to books, journals, and other Resources to support their academic research and learning.",
    onlineadvantagedesc6:
      "Credible: Our MBA program is accredited by leading global organizations and is renowned For its academic excellence.",
    onlineadvantagedesc7:
      "Adorable: We over competitive tuition fees and a range of financial aid options to ensure that Our program is accessible to all.",
    onlineadvantagedesc8:
      "Industry-Based Curriculum: Our curriculum is tailored to meet the current and future demands of the job market, preparing students with The necessary skills and knowledge to succeed in their chosen careers.",
    univprev1:
      "A Parul University provides Online MBA course. The institute has been ranked 8 under the Management category in the survey conducted by Business Standard School in 2021.Parul University in the era of online education; students get an opportunity of Global Business exposure. This University lies in the western India. This university is established in the state in Gujarat. It is the best private university. This university provides most enriching quality learning experience through cutting-edge research, innovative entrepreneurship, global exposure and the most lucrative career opportunities. The vision of this university to providing their students most enriching quality learning experience through cutting-edge research, innovative entrepreneurship, global exposure and the most lucrative career opportunities. The mission of this university to make this world a better place by developing the very good quality of working professional.",
    univprev2:
      "Parul University’s Online MBA program is designed to facilitate seamless career transformation for students with a unique exposure to in-demand national and global competencies. With significant systemic changes and the creation of excellent learning opportunities for everyone, online education is quickly becoming the biggest revolution in modern education. In order to keep up with this growth, Parul University Online Learning o‑ers a 2-year Online Master of Business Administration program that aims to instill in students the skill sets they need to understand the breadth of di‑erent fields related to business and management.",
    univprev3:
      "The examination is conducted in online mode using LMS. These online examinations are Web proctored to ensure the integrity of the exam.",
    collegetitle: "Online Manipal University ",
    whyus: "Why Choose Manipal University Jaipur?",
    whyusdesc1:
      "UGC-Entitled Degrees: Access UGC-entitled degrees from world-class universities accredited by NAAC, ensuring that your online degree holds the same prestige as traditional on-campus degrees, recognized by governments, corporations, and higher education institutions.",
    whyusdesc2:
      "Attractive Scholarships: Benefit from attractive scholarships tailored for defense personnel, government employees, differently-abled individuals, meritorious students, and alumni of Manipal universities.",
    whyusdesc3:
      "Placement Assistance: Enhance your employability with dedicated career and placement assistance services, including career-readiness sessions, resume building workshops, webinars by experts, and virtual placement drives.",
    whyusdesc4:
      "Prestigious Alumni Network: Join a revered alumni network comprising over 150,000 professionals and business leaders, including luminaries such as Mr. Satya Nadella, Chef Vikas Khanna, and Dr. Devi Prasad Shetty",
    whyusdesc5:
      "Industry Webinars & Simulations: Stay updated with industry trends through webinars conducted by experts and participate in hands-on workshops to gain certification in emerging technologies.",
    whyusdesc6:
      "Holistic Development: Parul places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Global Classroom: Engage with learners from diverse backgrounds across various industries, domains, geographies, and experience levels, fostering a rich learning environment.",
    whyusdesc8:
      "In-Person Campus Immersion: Experience an exclusive in-person event, Ekam, to connect with peers and faculty members of your online program, participate in interactive sessions, and create lasting memories.",
    elearningdesc1:
      "The learning material will be made available to you on the learning Management System (LMS) based on the four-quadrant approach, as per UGC (Credit Framework for online learning courses through SWAYAM) Regulation,2016.It includes a combination of the following e-learning content, namely:",
    elearningtitle1: "Video lectures",
    elearningtitle2: "E-Text materials",
    elearningtitle3: "Interactive material",
    elearningtitle4: "Virtual classroom",
    elearningtitle5: "Discussion forums",
    elearningdesc2:
      "Free Coursers on Coursera: Get exclusive access to 10,000+ courses on Coursera. Additionally.",
    elearningdesc3:
      "BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
  },
  {
    id: 12,
    partnersimg: require("../../assets/partner/maharishi.png"),
    partnersname: "MAHARISI Online",

    collegename: "Maharishi Markandeshwar University Online",
    collegedesc: "Accredited with NAAC A++ grade",
    whyus: "Why Online Maharishi Markandeshwar University",
    coursenames1: "Online B.COM",
    coursefees1: "1,20,000",
    coursenames2: "Online M.COM",
    coursefees2: "1,00,000",
    coursenames3: "Online MCA",
    coursefees3: "1,30,000",
    coursenames4: "Online BBA",
    coursefees4: "1,65,000",
    coursenames5: "Online MBA",
    coursefees5: "1,50,000",
    coursenames6: "Online MBA Dual",
    coursefees6: "1,70,000",
    specialfeatures1:
      "Globally recognized UGC-approved NAAC A+ accredited the online degrees offered by Manipal University Jaipur are at par with on-campus degrees.",
    specialfeatures2:
      "It opens door to grab better professional opportunities.",
    specialfeatures3:
      "The free Coursera access and choose from over 10,000+ professional certifications and courses from leading global universities while completing your degree. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    specialfeatures4:
      "Flexi-payment options allow students to pay fees in hassle-free installments. No-cost EMIs, let students learning take the spotlight without the stress of financing.",
    specialfeatures5:
      "Exclusive scholarships designed for defense personnel, government employees, differently-disabled people & meritorious students.",
    onlineadvantagetitle1: "ONLINE MANIPAL ADVANTAGES",
    onlineadvantagedesc1:
      "UGC Entitled Degrees: Access UGC-entitled degrees from world-class universities that are NAAC accredited. Pursue online degrees that are at par with conventional on-campus degrees and accepted by governments, corporate organizations, and higher education institutions.",
    onlineadvantagedesc2:
      "Free Coursers access: Get exclusive access to 10,000+ courses on Coursera. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    onlineadvantagedesc3:
      "Industry webinars & simulations: Attend webinars by industry experts to gain industry-specific knowledge. Participate in hands-on workshops and get certified in emerging technologies like Metaverse, AI Modelling, Block chain, and more.",
    onlineadvantagedesc4:
      "Exhaustive e – content & virtual lab: Gain access to vast e-libraries with e-books.",
    onlineadvantagedesc5:
      "Convenient class schedule: Attend live classes & access recorded lectures on-the-go. Engage in live interactions with faculty members to get your doubts clarified and write online-proctored exams from the comfort of your homes by booking slots as per your convenience.",
    onlineadvantagedesc6:
      "Global classroom: Join learners from all cities , towns and countries to connect & network. Exchange ideas with a diverse peer group from various industries, domains, geographies, and experience levels.",
    onlineadvantagedesc7:
      "Attractive scholarships: Attractive scholarships for defense personnel, government employees, differently-abled people & meritorious students, and special scholarships for select female applicants through Online Manipal’s #Empower her initiative.",
    onlineadvantagedesc8:
      "Placement assistance: Increase your chances of getting a job with dedicated career and placement assistance services. Attend career-readiness sessions, resume building workshops & webinars by experts, and participate in virtual placement drives..",
    univprev1:
      "Online Maharishi Markandeshwar, a pioneering venture in digital education brought to you by Maharishi Markandeshwar University (MMU). Situated in Ambala, Haryana, Maharishi Markandeshwar University has been at the forefront of providing quality education and fostering academic excellence for over two decades.",
    univprev2:
      "Online Maharishi Markandeshwar offers a wide range of undergraduate, postgraduate programmes. With a commitment to innovation and accessibility, MMU has extended its academic offerings to the digital realm, providing learners with flexible and convenient access to quality education.",
    univprev3:
      "At Online Maharishi Markandeshwar, students benefit from the same rigorous academic standards, experienced faculty members, and industry-relevant curriculum as their on-campus counterparts. Through interactive online lectures, engaging course materials, and virtual learning platforms, students receive a dynamic and immersive educational experience tailored to meet the demands of today's digital age.",
    univprev4:
      "One of the key advantages of Online Maharishi Markandeshwar is its flexibility, allowing students to pursue their studies at their own pace and convenience. Whether you're a working professional, a stay-at-home parent, or someone looking to advance their career, our online programs offer the flexibility you need to balance your education with your other commitments.",
    univprev5:
      "In addition to flexibility, Online Maharishi Markandeshwar emphasizes accessibility, enabling learners from across India and around the world to access quality education without the constraints of geography or distance. Through our online platform, students can engage with course materials, participate in discussions, and interact with faculty members from the comfort of their own homes.",
    univprev6:
      "As part of Maharishi Markandeshwar University, Online Maharishi Markandeshwar upholds the same values of academic excellence, innovation, and holistic development. We are committed to nurturing well-rounded individuals who are not only academically proficient but also socially responsible, ethically sound, and equipped with the skills and knowledge needed to thrive in today's globalized world.",
    univprev7:
      "Join us at Online Maharishi Markandeshwar and embark on a transformative journey towards a brighter future. With our commitment to quality education, innovation, and accessibility, we are shaping the leaders and innovators of tomorrow, one digital classroom at a time.",
    collegetitle: "Online Maharishi Markandeshwar University ",
    whyus: "Why Choose Maharishi Markandeshwar University?",
    whyusdesc1:
      "Academic Excellence: Maharishi Markandeshwar University (MMU) is renowned for its commitment to academic excellence. With a legacy spanning over two decades, MMU has established itself as a center of learning that upholds rigorous academic standards and delivers quality education across various disciplines.",
    whyusdesc2:
      "Industry-Relevant Curriculum: MMU offers programs that are carefully crafted to meet the demands of the industry. The curriculum is designed in collaboration with industry experts, ensuring that students acquire the skills and knowledge needed to excel in their chosen fields.",
    whyusdesc3:
      "State-of-the-Art Infrastructure: The university boasts modern infrastructure and facilities that provide students with a conducive learning environment. From well-equipped laboratories and libraries to sports facilities and student accommodation, MMU offers everything students need to thrive academically and personally",
    whyusdesc4:
      "Experienced Faculty: At MMU, students learn from a team of experienced faculty members who are experts in their respective fields. The faculty members are dedicated to nurturing talent, inspiring creativity, and guiding students towards academic and professional success.",
    whyusdesc5:
      "Research Opportunities: MMU encourages research and innovation among its students and faculty members. The university provides ample opportunities for research projects, collaborations, and publications, allowing students to explore their interests and contribute to the advancement of knowledge.",
    whyusdesc6:
      "Holistic Development: MMU places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Global Exposure: MMU offers various international collaborations, exchange programs, and study abroad opportunities, providing students with global exposure and perspectives. These initiatives help students broaden their horizons, enhance their intercultural competencies, and prepare them for success in a globalized world.",
    whyusdesc8:
      "Placement Assistance: MMU has a dedicated placement cell that works tirelessly to connect students with job opportunities and internships in leading companies and organizations. The university has a strong track record of placements, with many students securing lucrative job offers even before graduation.",
    elearningdesc1:
      "Alumni Network: MMU boasts a vast alumni network comprising successful professionals and entrepreneurs who have made their mark in various fields. The alumni network provides valuable mentorship, networking, and career guidance opportunities for current students and graduates alike.",
    elearningtitle1: "Video lectures",
    elearningtitle2: "E-Text materials",
    elearningtitle3: "Interactive material",
    elearningtitle4: "Virtual classroom",
    elearningtitle5: "Discussion forums",
    elearningdesc2:
      "Free Coursers on Coursera: Get exclusive access to 10,000+ courses on Coursera. Additionally.",
    elearningdesc3:
      "BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
  },
  {
    id: 13,
    partnersimg: require("../../assets/partner/jnu-logo1.png"),
    partnersname: "JNU",

    collegename: "Jaipur National University Online",
    collegedesc: "Accredited with NAAC A++ grade",
    whyus: "Why Jaipur National University",
    coursenames1: "Online B.COM",
    coursefees1: "1,20,000",
    coursenames2: "Online M.COM",
    coursefees2: "1,00,000",
    coursenames3: "Online MCA",
    coursefees3: "1,30,000",
    coursenames4: "Online BBA",
    coursefees4: "1,65,000",
    coursenames5: "Online MBA",
    coursefees5: "1,50,000",
    coursenames6: "Online MBA Dual",
    coursefees6: "1,70,000",
    specialfeatures1:
      "Globally recognized UGC-approved NAAC A+ accredited the online degrees offered by Manipal University Jaipur are at par with on-campus degrees.",
    specialfeatures2:
      "It opens door to grab better professional opportunities.",
    specialfeatures3:
      "The free Coursera access and choose from over 10,000+ professional certifications and courses from leading global universities while completing your degree. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    specialfeatures4:
      "Flexi-payment options allow students to pay fees in hassle-free installments. No-cost EMIs, let students learning take the spotlight without the stress of financing.",
    specialfeatures5:
      "Exclusive scholarships designed for defense personnel, government employees, differently-disabled people & meritorious students.",
    onlineadvantagetitle1: "ONLINE Jaipur National University ADVANTAGES",
    onlineadvantagedesc1:
      "UGC Entitled Degrees: Access UGC-entitled degrees from world-class universities that are NAAC accredited. Pursue online degrees that are at par with conventional on-campus degrees and accepted by governments, corporate organizations, and higher education institutions.",
    onlineadvantagedesc2:
      "Free Coursers access: Get exclusive access to 10,000+ courses on Coursera. Additionally, BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
    onlineadvantagedesc3:
      "Industry webinars & simulations: Attend webinars by industry experts to gain industry-specific knowledge. Participate in hands-on workshops and get certified in emerging technologies like Metaverse, AI Modelling, Block chain, and more.",
    onlineadvantagedesc4:
      "Exhaustive e – content & virtual lab: Gain access to vast e-libraries with e-books.",
    onlineadvantagedesc5:
      "Convenient class schedule: Attend live classes & access recorded lectures on-the-go. Engage in live interactions with faculty members to get your doubts clarified and write online-proctored exams from the comfort of your homes by booking slots as per your convenience.",
    onlineadvantagedesc6:
      "Global classroom: Join learners from all cities , towns and countries to connect & network. Exchange ideas with a diverse peer group from various industries, domains, geographies, and experience levels.",
    onlineadvantagedesc7:
      "Attractive scholarships: Attractive scholarships for defense personnel, government employees, differently-abled people & meritorious students, and special scholarships for select female applicants through Online Manipal’s #Empower her initiative.",
    onlineadvantagedesc8:
      "Placement assistance: Increase your chances of getting a job with dedicated career and placement assistance services. Attend career-readiness sessions, resume building workshops & webinars by experts, and participate in virtual placement drives..",
    univprev1:
      "Online Jaipur National University, a pioneering institution in the realm of digital education. Situated in Jalandhar, Punjab, Lovely Professional University has been a trailblazer in providing quality education for over two decades. With Online , the university extends its commitment to excellence in education to learners across the globe.",
    univprev2:
      "Online Jaipur National University offers a wide array of undergraduate, postgraduate programmes. Through online platform, students have access to the same rigorous curriculum, experienced faculty members, and modern teaching methods as their on-campus counterparts.",
    univprev3:
      "One of the key advantages of Online Jaipur National University is its flexibility, allowing students to pursue their studies at their own pace and convenience. Whether you're a working professional, a busy parent, or someone with other commitments, our online programs enable you to balance your education with your personal and professional life.",
    univprev4:
      "Accessibility is another hallmark of Online Jaipur National University. With our online platform, learners from all corners of the world can access quality education without the constraints of geography or distance. This inclusivity ensures that education is available to everyone, regardless of their location or background.",
    univprev5:
      "At Online Jaipur National University, students benefit from interactive learning experiences facilitated by engaging lectures, multimedia content, and collaborative discussions. Our virtual classrooms provide a dynamic environment where students can interact with faculty members and peers, enhancing their understanding of the subject matter.",
    univprev6:
      "Online Jaipur National University also offers a range of support services to ensure that students have a smooth and seamless learning experience. From academic advising to technical support, our dedicated team is committed to assisting students every step of the way.",
    univprev7:
      "By choosing Online Jaipur National University, students gain access to a future-ready education that prepares them for success in the digital age and beyond. With our emphasis on innovation, industry relevance, and holistic development, Online Jaipur National University empowers learners to achieve their academic and professional goals, all from the comfort of their own homes.",
    univprev8:
      "Join us at Online Lovely Professional University and embark on a transformative educational journey that will shape your future and unlock endless opportunities for growth and success.",
    whyus: "Why Choose Jaipur National University?",
    whyusdesc1:
      "Excellence in Education: Jaipur National University is renowned for its commitment to academic excellence. With a diverse range of programs and a faculty comprising experienced educators and industry experts, Jaipur National University provides students with a world-class learning experience that prepares them for success in their chosen fields.",
    whyusdesc2:
      "State-of-the-Art Infrastructure: The university boasts modern infrastructure and facilities that create an ideal environment for learning and growth. From well-equipped classrooms and laboratories to sports facilities and student accommodation, Jaipur National University ensures that students have access to everything they need to excel academically and personally.",
    whyusdesc3:
      "Global Exposure: Jaipur National University offers various international collaborations, exchange programs, and study abroad opportunities, providing students with global exposure and perspectives. These initiatives help students broaden their horizons, enhance their intercultural competencies, and prepare them for success in a globalized world.",
    whyusdesc4:
      "Industry Partnerships and Placements: Jaipur National University has strong ties with leading companies and organizations, facilitating internships, placements, and industry collaborations for students. The university's robust placement cell works tirelessly to connect students with job opportunities and prepare them for the competitive job market.",
    whyusdesc5:
      "Innovation and Research: Jaipur National University encourages innovation and research among its students and faculty members. The university provides ample opportunities for research projects, collaborations, and publications, allowing students to explore their interests and contribute to the advancement of knowledge.",
    whyusdesc6:
      "Holistic Development: Jaipur National University places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Entrepreneurship Development: Jaipur National University fosters an entrepreneurial mindset among its students, providing support and resources for aspiring entrepreneurs to turn their ideas into successful ventures. The university's entrepreneurship cell organizes workshops, seminars, and mentorship programs to help students develop their entrepreneurial skills and launch their own businesses.",
    whyusdesc8:
      "Student-Centric Approach: Above all, Jaipur National University is committed to the success and well-being of its students. The university adopts a student-centric approach, prioritizing their needs, aspirations, and overall growth and development.",
    collegetitle: "Online Manipal University ",
    whyus: "Why Choose Manipal University Jaipur?",
    whyusdesc1:
      "UGC-Entitled Degrees: Access UGC-entitled degrees from world-class universities accredited by NAAC, ensuring that your online degree holds the same prestige as traditional on-campus degrees, recognized by governments, corporations, and higher education institutions.",
    whyusdesc2:
      "Attractive Scholarships: Benefit from attractive scholarships tailored for defense personnel, government employees, differently-abled individuals, meritorious students, and alumni of Manipal universities.",
    whyusdesc3:
      "Placement Assistance: Enhance your employability with dedicated career and placement assistance services, including career-readiness sessions, resume building workshops, webinars by experts, and virtual placement drives.",
    whyusdesc4:
      "Prestigious Alumni Network: Join a revered alumni network comprising over 150,000 professionals and business leaders, including luminaries such as Mr. Satya Nadella, Chef Vikas Khanna, and Dr. Devi Prasad Shetty",
    whyusdesc5:
      "Industry Webinars & Simulations: Stay updated with industry trends through webinars conducted by experts and participate in hands-on workshops to gain certification in emerging technologies.",
    whyusdesc6:
      "Holistic Development: Jaipur National University places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Global Classroom: Engage with learners from diverse backgrounds across various industries, domains, geographies, and experience levels, fostering a rich learning environment.",
    whyusdesc8:
      "In-Person Campus Immersion: Experience an exclusive in-person event, Ekam, to connect with peers and faculty members of your online program, participate in interactive sessions, and create lasting memories.",
    elearningdesc1:
      "The learning material will be made available to you on the learning Management System (LMS) based on the four-quadrant approach, as per UGC (Credit Framework for online learning courses through SWAYAM) Regulation,2016.It includes a combination of the following e-learning content, namely:",
    elearningtitle1: "Video lectures",
    elearningtitle2: "E-Text materials",
    elearningtitle3: "Interactive material",
    elearningtitle4: "Virtual classroom",
    elearningtitle5: "Discussion forums",
    elearningdesc2:
      "Free Coursers on Coursera: Get exclusive access to 10,000+ courses on Coursera. Additionally.",
    elearningdesc3:
      "BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
  },
  {
    id: 14,
    partnersimg: require("../../assets/partner/jecrc--university1.png"),
    partnersname: "JECRC",

    collegename: "JECRC Online",
    collegedesc: "Accredited with NAAC A++ grade",
    whyus: "Why Online JECRC",
    coursenames1: "Online B.COM",
    coursefees1: "1,20,000",
    coursenames2: "Online M.COM",
    coursefees2: "1,00,000",
    coursenames3: "Online MCA",
    coursefees3: "1,30,000",
    coursenames4: "Online BBA",
    coursefees4: "1,65,000",
    coursenames5: "Online MBA",
    coursefees5: "1,50,000",
    coursenames6: "Online MBA Dual",
    coursefees6: "1,70,000",
    specialfeatures1:
      "UGC- WES recognized degrees .Earn an UGC- WES recognized online degree from NAAC A+ accredited university.",
    specialfeatures2:
      "Leading university Professors and industry leaders to mentor you.",
    specialfeatures3:
      "Cost effective with EMI option and scholarships available",
    specialfeatures4:
      "Access to ILETS coaching and Credit Articulation to colleges in US/Australia/Canada/UK",
    specialfeatures5:
      "Internship /job opportunity with access to job portal with 300+hiring partners.",
    onlineadvantagetitle1: "ADVANTAGE OF JECRC",
    onlineadvantagedesc1:
      "Efficiency & Flexibly: Teachers will deliver lessons to students in a more versatile way using online learning. Teachers have to      update themselves technologically to keep up to this new way of teaching through online mediums like videos, podcasts, etc. Teachers have seen more success as instructors by integrating these online mediums into their lectures in addition to school textbooks.",
    onlineadvantagedesc2:
      "Accessibility of time and place: Another benefit of online learning is that it allows individuals to take classes from anywhere in the World. It even lets schools to branch out to a wider range of students instead of being limited by geographic restrictions.",
    onlineadvantagedesc3:
      "Cost-effectiveness: Reduced financial risks are another benefit of online learning. Contrary to physical/in-person education, online courses are available to wider strata of students. This is due to the fact that online scud. Improved rate of student attendance There is a lesser possibility of students missing classes since online classes can be taken from anywhere in the world. Your learning pooling reduces the expends. Significantly, hostel fees.",
    onlineadvantagedesc4:
      " Improved Rate of Student Attendance: Here is a lesser possibility of students missing classes since online classes can be taken from anywhere in the world. Since you have a clear e-mail line to the teacher, you will get immediate answers to your questions. For fear of being ignorant, often students are unable to ask questions in class. Hopefully, the Internet would make the anxiety obsolete.",
    onlineadvantagedesc5:
      "Adapts to a Wide Range of Learning Methodologies: The way a person comprehends information varies from person to person. Some individuals prefer to learn through film, while others may prefer to comprehend information through class lectures. Similarly, some individuals thrive in a classroom environment, while other individuals might work well when they study by themselves and find a big classroom overwhelming in terms of asking questions or answering them. The online learning experience can be customized in a numerous ways owing to the multiple ways it has to offer.",
    onlineadvantagedesc6:
      "Global classroom: Join learners from all cities , towns and countries to connect & network. Exchange ideas with a diverse peer group from various industries, domains, geographies, and experience levels.",
    onlineadvantagedesc7:
      "Attractive scholarships: Attractive scholarships for defense personnel, government employees, differently-abled people & meritorious students, and special scholarships for select female applicants through Online Manipal’s #Empower her initiative.",
    onlineadvantagedesc8:
      "Placement assistance: Increase your chances of getting a job with dedicated career and placement assistance services. Attend career-readiness sessions, resume building workshops & webinars by experts, and participate in virtual placement drives..",
    univprev1:
      "JECRC has set the trend in providing quality education to global students and has become known for its new-age academics. We are ranked 2nd among all the private universities in India by QS Asia University Rankings 2023 and have earned an A+ grade from India's biggest independent accreditation agency, NAAC (National Assessment and Accreditation Council). Now, students can study at a globally recognized university and receive scholarships and funding from a wide range of agencies.",
    univprev2:
      "Facilitate globally recognized standards of lifelong learning and build pedagogies structured on diversity that will lead to the enhancement of the intellectual capacities of students.",
    univprev3:
      "Our futuristic online learning platform at JECRC University  provides students with added convenience, flexibility, and a safety net to pursue tertiary education at a distance.",
    collegetitle: "Online JECRC University ",
    whyus: "Why Choose JECRC University Jaipur?",
    whyusdesc1:
      "UGC-Entitled Degrees: Access UGC-entitled degrees from world-class universities accredited by NAAC, ensuring that your online degree holds the same prestige as traditional on-campus degrees, recognized by governments, corporations, and higher education institutions.",
    whyusdesc2:
      "Attractive Scholarships: Benefit from attractive scholarships tailored for defense personnel, government employees, differently-abled individuals, meritorious students, and alumni of Manipal universities.",
    whyusdesc3:
      "Placement Assistance: Enhance your employability with dedicated career and placement assistance services, including career-readiness sessions, resume building workshops, webinars by experts, and virtual placement drives.",
    whyusdesc4:
      "Prestigious Alumni Network: Join a revered alumni network comprising over 150,000 professionals and business leaders, including luminaries such as Mr. Satya Nadella, Chef Vikas Khanna, and Dr. Devi Prasad Shetty",
    whyusdesc5:
      "Industry Webinars & Simulations: Stay updated with industry trends through webinars conducted by experts and participate in hands-on workshops to gain certification in emerging technologies.",
    whyusdesc6:
      "Holistic Development: JECRC University places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Global Classroom: Engage with learners from diverse backgrounds across various industries, domains, geographies, and experience levels, fostering a rich learning environment.",
    whyusdesc8:
      "In-Person Campus Immersion: Experience an exclusive in-person event, Ekam, to connect with peers and faculty members of your online program, participate in interactive sessions, and create lasting memories.",
    elearningdesc1:
      "The learning material will be made available to you on the learning Management System (LMS) based on the four-quadrant approach, as per UGC (Credit Framework for online learning courses through SWAYAM) Regulation,2016.It includes a combination of the following e-learning content, namely:",
    elearningtitle1: "Video lectures",
    elearningtitle2: "E-Text materials",
    elearningtitle3: "Interactive material",
    elearningtitle4: "Virtual classroom",
    elearningtitle5: "Discussion forums",
    elearningdesc2:
      "Free Coursers on Coursera: Get exclusive access to 10,000+ courses on Coursera. Additionally.",
    elearningdesc3:
      "BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
  },
  {
    id: 15,
    partnersimg: require("../../assets/partner/uttaranchal-logo.png"),
    partnersname: "UTTRANCHAL Online",

    collegename: "UTTRANCHAL Online",
    collegedesc: "Accredited with NAAC A++ grade",
    whyus: "Why Online UTTRANCHAL",
    coursenames1: "Online B.COM",
    coursefees1: "1,20,000",
    coursenames2: "Online M.COM",
    coursefees2: "1,00,000",
    coursenames3: "Online MCA",
    coursefees3: "1,30,000",
    coursenames4: "Online BBA",
    coursefees4: "1,65,000",
    coursenames5: "Online MBA",
    coursefees5: "1,50,000",
    coursenames6: "Online MBA Dual",
    coursefees6: "1,70,000",
    specialfeatures1:
      "UGC- WES recognized degrees .Earn an UGC- WES recognized online degree from NAAC A+ accredited university.",
    specialfeatures2:
      "Leading university Professors and industry leaders to mentor you.",
    specialfeatures3:
      "Cost effective with EMI option and scholarships available",
    specialfeatures4:
      "Access to ILETS coaching and Credit Articulation to colleges in US/Australia/Canada/UK",
    specialfeatures5:
      "Internship /job opportunity with access to job portal with 300+hiring partners.",
    onlineadvantagetitle1: "ADVANTAGE OF UTTRANCHAL",
    onlineadvantagedesc1:
      "Efficiency & Flexibly: Teachers will deliver lessons to students in a more versatile way using online learning. Teachers have to      update themselves technologically to keep up to this new way of teaching through online mediums like videos, podcasts, etc. Teachers have seen more success as instructors by integrating these online mediums into their lectures in addition to school textbooks.",
    onlineadvantagedesc2:
      "Accessibility of time and place: Another benefit of online learning is that it allows individuals to take classes from anywhere in the World. It even lets schools to branch out to a wider range of students instead of being limited by geographic restrictions.",
    onlineadvantagedesc3:
      "Cost-effectiveness: Reduced financial risks are another benefit of online learning. Contrary to physical/in-person education, online courses are available to wider strata of students. This is due to the fact that online scud. Improved rate of student attendance There is a lesser possibility of students missing classes since online classes can be taken from anywhere in the world. Your learning pooling reduces the expends. Significantly, hostel fees.",
    onlineadvantagedesc4:
      " Improved Rate of Student Attendance: Here is a lesser possibility of students missing classes since online classes can be taken from anywhere in the world. Since you have a clear e-mail line to the teacher, you will get immediate answers to your questions. For fear of being ignorant, often students are unable to ask questions in class. Hopefully, the Internet would make the anxiety obsolete.",
    onlineadvantagedesc5:
      "Adapts to a Wide Range of Learning Methodologies: The way a person comprehends information varies from person to person. Some individuals prefer to learn through film, while others may prefer to comprehend information through class lectures. Similarly, some individuals thrive in a classroom environment, while other individuals might work well when they study by themselves and find a big classroom overwhelming in terms of asking questions or answering them. The online learning experience can be customized in a numerous ways owing to the multiple ways it has to offer.",
    onlineadvantagedesc6:
      "Global classroom: Join learners from all cities , towns and countries to connect & network. Exchange ideas with a diverse peer group from various industries, domains, geographies, and experience levels.",
    onlineadvantagedesc7:
      "Attractive scholarships: Attractive scholarships for defense personnel, government employees, differently-abled people & meritorious students, and special scholarships for select female applicants through Online Manipal’s #Empower her initiative.",
    onlineadvantagedesc8:
      "Placement assistance: Increase your chances of getting a job with dedicated career and placement assistance services. Attend career-readiness sessions, resume building workshops & webinars by experts, and participate in virtual placement drives..",
    univprev1:
      "UTTRANCHAL has set the trend in providing quality education to global students and has become known for its new-age academics. We are ranked 2nd among all the private universities in India by QS Asia University Rankings 2023 and have earned an A+ grade from India's biggest independent accreditation agency, NAAC (National Assessment and Accreditation Council). Now, students can study at a globally recognized university and receive scholarships and funding from a wide range of agencies.",
    univprev2:
      "Facilitate globally recognized standards of lifelong learning and build pedagogies structured on diversity that will lead to the enhancement of the intellectual capacities of students.",
    univprev3:
      "Our futuristic online learning platform at UTTRANCHAL University  provides students with added convenience, flexibility, and a safety net to pursue tertiary education at a distance.",
    collegetitle: "Online UTTRANCHAL University ",
    whyus: "Why Choose UTTRANCHAL University Jaipur?",
    whyusdesc1:
      "UGC-Entitled Degrees: Access UGC-entitled degrees from world-class universities accredited by NAAC, ensuring that your online degree holds the same prestige as traditional on-campus degrees, recognized by governments, corporations, and higher education institutions.",
    whyusdesc2:
      "Attractive Scholarships: Benefit from attractive scholarships tailored for defense personnel, government employees, differently-abled individuals, meritorious students, and alumni of Manipal universities.",
    whyusdesc3:
      "Placement Assistance: Enhance your employability with dedicated career and placement assistance services, including career-readiness sessions, resume building workshops, webinars by experts, and virtual placement drives.",
    whyusdesc4:
      "Prestigious Alumni Network: Join a revered alumni network comprising over 150,000 professionals and business leaders, including luminaries such as Mr. Satya Nadella, Chef Vikas Khanna, and Dr. Devi Prasad Shetty",
    whyusdesc5:
      "Industry Webinars & Simulations: Stay updated with industry trends through webinars conducted by experts and participate in hands-on workshops to gain certification in emerging technologies.",
    whyusdesc6:
      "Holistic Development: UTTRANCHAL University places a strong emphasis on holistic development, nurturing well-rounded individuals who possess not only academic prowess but also strong ethical values, leadership skills, and a sense of social responsibility.",
    whyusdesc7:
      "Global Classroom: Engage with learners from diverse backgrounds across various industries, domains, geographies, and experience levels, fostering a rich learning environment.",
    whyusdesc8:
      "In-Person Campus Immersion: Experience an exclusive in-person event, Ekam, to connect with peers and faculty members of your online program, participate in interactive sessions, and create lasting memories.",
    elearningdesc1:
      "The learning material will be made available to you on the learning Management System (LMS) based on the four-quadrant approach, as per UGC (Credit Framework for online learning courses through SWAYAM) Regulation,2016.It includes a combination of the following e-learning content, namely:",
    elearningtitle1: "Video lectures",
    elearningtitle2: "E-Text materials",
    elearningtitle3: "Interactive material",
    elearningtitle4: "Virtual classroom",
    elearningtitle5: "Discussion forums",
    elearningdesc2:
      "Free Coursers on Coursera: Get exclusive access to 10,000+ courses on Coursera. Additionally.",
    elearningdesc3:
      "BCA and MCA learners will be given free access to Google Cloud Computing Foundations curriculum.",
  },
];
const headlogoData = [
  {
    id: "1",
    headlogo: require("../../assets/ugc.png"),
  },
  {
    id: "2",
    headlogo: require("../../assets/National_Institutional_Ranking_Framework_logo.png"),
  },
  {
    id: "3",
    headlogo: require("../../assets/AICTE-Logo.png"),
  },
];
const carouselImageData = [
  {
    image: require("../../assets/course1.jpg"),
  },
  {
    image: require("../../assets/course2.jpg"),
  },
  {
    image: require("../../assets/caps.jpg"),
  },
  {
    image: require("../../assets/course4.jpg"),
  },
  {
    image: require("../../assets/course5.jpg"),
  },
];
const coursecarouselData = [
  {
    id: "1",
    coursesimage: require("../../assets/wes.png"),
    coursestitle: "JECRC University ONLINE",
    coursesname: "Online BCA",
    coursesdescription: "Right choice for Education",
    coursescardbutton: "Read More",
  },
  {
    id: "2",
    coursesimage: require("../../assets/wes.png"),
    coursestitle: "LPU ONLINE",
    coursesname: "Online BCA",
    coursesdescription: "Right choice for Education",
    coursescardbutton: "Read More",
  },
  {
    id: "3",
    coursesimage: require("../../assets/wes.png"),
    coursestitle: "LPU ONLINE",
    coursesname: "Online BCA",
    coursesdescription: "Right choice for Education",
    coursescardbutton: "Read More",
  },
  {
    id: "4",
    coursesimage: require("../../assets/wes.png"),
    coursestitle: "LPU ONLINE",
    coursesname: "Online BCA",
    coursesdescription: "Right choice for Education",
    coursescardbutton: "Read More",
  },
  {
    id: "5",
    coursesimage: require("../../assets/wes.png"),
    coursestitle: "LPU ONLINE",
    coursesname: "Online BCA",
    coursesdescription: "Right choice for Education",
    coursescardbutton: "Read More",
  },
  {
    id: "6",
    coursesimage: require("../../assets/wes.png"),
    coursestitle: "LPU ONLINE",
    coursesname: "Online BCA",
    coursesdescription: "Right choice for Education",
    coursescardbutton: "Read More",
  },
  {
    id: "7",
    coursesimage: require("../../assets/wes.png"),
    coursestitle: "LPU ONLINE",
    coursesname: "Online BCA",
    coursesdescription: "Right choice for Education",
    coursescardbutton: "Read More",
  },
];
export default Collegedata1;
export { headlogoData, coursecarouselData, carouselImageData };
