const Sdata3 = [
  {
    id: 1,
    title: "Digital Marketing",
    coursepageimg: require("../../assets/digitalmarketing.png"),
    coursename:
      "Online Postgraduate Diploma in Digital Marketing Course Overview",
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "1-2 Years",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle:
      "Online Postgraduate Diploma in Digital Marketing Course Overview",
    courseDesc:
      "The Online Postgraduate Diploma in Digital Marketing program offers comprehensive training in the strategic and practical aspects of digital marketing. Through a flexible online platform, students gain the knowledge and skills needed to excel in the rapidly evolving digital landscape. This program equips graduates with the expertise required to develop effective digital marketing strategies, leverage various online platforms and channels, and analyze data to optimize marketing campaigns.",
    componentsTitle: "Key Components",
    questioncontent1: "Foundations of Digital Marketing:",
    answercontent1:
      "Introduction to digital marketing principles and concepts. Understanding the digital marketing landscape and its evolution. Exploring the role of digital marketing in contemporary business environments.",
    questioncontent2: "Digital Marketing Strategy:",
    answercontent2:
      " Developing digital marketing strategies aligned with organizational goals. Conducting market research and competitor analysis. Creating comprehensive digital marketing plans and campaigns.",
    questioncontent3: "Search Engine Optimization (SEO):",
    answercontent3:
      " Understanding the fundamentals of SEO. Keyword research, on-page optimization, and off-page optimization techniques. Monitoring and improving website visibility in search engine results pages (SERPs).",
    questioncontent4: "Pay-Per-Click (PPC) Advertising: ",
    answercontent4:
      "Introduction to PPC advertising platforms (e.g., Google Ads, Bing Ads). Creating and optimizing PPC campaigns. Bid management, ad copywriting, and conversion tracking.",
    questioncontent5: "Socia-- l M Media Marketing (SMM): ",
    answercontent5:
      "Utilizing social media platforms for marketing purposes. Developing social media strategies and content calendars. Engaging with audiences, building communities, and managing social media advertising campaigns.",
    questioncontent6: "Email Marketing: ",
    answercontent6:
      "Understanding the role of email marketing in the digital marketing mix. Building and segmenting email lists. Designing effective email campaigns, including automation and personalization.",
    questioncontent7: "Content Marketing: ",
    answercontent7:
      "Creating compelling content for different digital channels. Developing content marketing strategies. Leveraging content to drive engagement, generate leads, and build brand authority.",
    questioncontent8: "Digital Analytics: ",
    answercontent8:
      "Introduction to digital analytics tools (e.g., Google Analytics, Adobe Analytics). Collecting, analyzing, and interpreting data to measure marketing performance. Using data insights to optimize digital marketing strategies and campaigns.",
    questioncontent9: "Mobile Marketing: ",
    answercontent9:
      "Understanding the mobile marketing landscape. Developing mobile-responsive websites and mobile apps. Implementing mobile advertising and location-based marketing strategies.",
    questioncontent10: "Emerging Trends in Digital Marketing:",
    answercontent10:
      "Exploring emerging trends and technologies in digital marketing (e.g., AI, voice search, AR/VR). Assessing the impact of technological innovations on digital marketing strategies.",
    questioncontent10: "Legal and Ethical Considerations: ",
    answercontent10:
      "Understanding legal and ethical issues in digital marketing. Ensuring compliance with regulations such as GDPR and CCPA. Maintaining ethical standards in digital marketing practices. ",
    questioncontent10: "Practical Projects and Case Studies: ",
    answercontent10:
      "Applying theoretical knowledge to real-world digital marketing scenarios. Developing digital marketing campaigns and strategies for diverse industries. Analyzing case studies and learning from successful digital marketing campaigns.",
    questioncontent10: "Professional Development and Career Opportunities: ",
    answercontent10:
      "Exploring career paths and opportunities in digital marketing. Building a professional portfolio and personal brand. Enhancing communication and networking skills within the digital marketing community.",

    benefits:
      "Benefits of Online Postgraduate Diploma in Digital Marketing Programs:",
    benefits1:
      "Flexibility: Study at your own pace and convenience, balancing education with other commitments.",
    benefits2:
      "Accessibility: Access course materials and lectures from anywhere with an internet connection.",
    benefits3:
      "Practical Skills: Gain hands-on experience through practical projects and real-world case studies.",
    benefits4:
      "Industry-Relevant Curriculum: Learn from industry experts and stay updated on the latest trends and technologies in digital marketing.",
    benefits4:
      "Career Advancement: Develop skills that are in high demand and pursue diverse career opportunities in the digital marketing field.",
  },
  {
    id: 2,
    title: "Hospital and Healthcare",
    coursepageimg: require("../../assets/hospitalandhealthcare.png"),
    coursename:
      "Online Certificate Course in Hospital and Healthcare Management Overview",
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "1-2 Years",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle:
      "Online Certificate Course in Hospital and Healthcare Management Overview",
    courseDesc:
      "The Online Certificate Course in Hospital and Healthcare Management offers participants a comprehensive understanding of key concepts, principles, and practices in the healthcare industry. This program is designed to equip students with practical skills and knowledge necessary to effectively manage healthcare facilities and navigate the complexities of healthcare administration. Through a flexible online platform, participants learn about essential healthcare management topics, preparing them for career opportunities in hospital administration, healthcare management, consulting, and related fields.",
    componentsTitle: "Key Components",
    questioncontent1: "Introduction to Healthcare Management:",
    answercontent1:
      "Overview of the healthcare industry, including its structure, stakeholders, and regulatory environment. Understanding the role of healthcare managers and administrators in healthcare delivery.",
    questioncontent2: "Healthcare Leadership and Governance:",
    answercontent2:
      " Principles of healthcare leadership, including strategic planning, decision-making, and team management. Understanding healthcare governance structures and their impact on organizational performance.",
    questioncontent3: "Healthcare Operations Management:",
    answercontent3:
      "Basics of healthcare operations management, including resource allocation, scheduling, and workflow optimization. Improving efficiency and productivity in healthcare delivery.",
    questioncontent4: "Healthcare Quality Improvement:",
    answercontent4:
      "Introduction to quality improvement methodologies in healthcare, such as Six Sigma, Lean Management, and Total Quality Management (TQM). Ensuring patient safety, improving healthcare outcomes, and enhancing the patient experience.",
    questioncontent5: "Healt-- h Mcare Financial Management: ",
    answercontent5:
      "Basics of healthcare finance, including budgeting, revenue cycle management, and cost control. Understanding healthcare reimbursement systems and financial decision-making in healthcare organizations.",
    questioncontent6: "Healthcare Information Systems:",
    answercontent6:
      "Overview of healthcare information systems (HIS) and electronic health records (EHR). Utilizing health information technology (HIT) to improve clinical workflows, data management, and decision support.",
    questioncontent7: "Healthcare Policy and Regulation:",
    answercontent7:
      "Understanding healthcare policy frameworks, government regulations, and compliance requirements. Analyzing healthcare policy issues and their implications for healthcare management and administration.",
    questioncontent8: "Human Resources Management in Healthcare: ",
    answercontent8:
      "Recruitment, training, and retention of healthcare professionals. Performance management, employee relations, and workforce development in healthcare settings.",
    questioncontent9: "Ethical and Legal Issues in Healthcare: ",
    answercontent9:
      "Understanding ethical considerations and legal principles in healthcare delivery. Ensuring compliance with healthcare regulations, patient rights, and confidentiality laws.",
    questioncontent10: "Strategic Planning in Healthcare: ",
    answercontent10:
      "Developing strategic plans for healthcare organizations, setting goals, and defining strategies. Aligning organizational objectives with external trends, market forces, and stakeholder expectations.",
    questioncontent10: "Healthcare Marketing and Communication:",
    answercontent10:
      "Marketing strategies for healthcare organizations, including branding, advertising, and patient engagement. Effective communication strategies for healthcare professionals, patients, and stakeholders.",
    questioncontent10: "Emerging Trends in Healthcare Management:",
    answercontent10:
      "Exploring emerging technologies, trends, and innovations in healthcare management. Assessing the impact of digital transformation, telemedicine, and personalized medicine on healthcare delivery.",

    benefits: "Benefits of Online Certificate Course in Digital Marketing:",
    benefits1:
      "Flexibility: Study at your own pace and convenience, fitting education around your schedule.",
    benefits2:
      "Accessibility: Access course materials and lectures from anywhere with an internet connection.",
    benefits3:
      "Practical Skills: Gain hands-on experience through practical projects, simulations, and real-world examples.",
    benefits4:
      "Industry-Relevant Curriculum: Learn from experienced instructors and industry professionals, ensuring the curriculum is up-to-date and relevant.",
    benefits4:
      "Career Advancement: Acquire skills sought after by employers in the healthcare industry,, opening up career opportunities and advancement prospects.",
  },
  {
    id: 3,
    title: "Business Analytics",
    coursepageimg: require("../../assets/businessanalytics.png"),
    coursename:
      "Online Postgraduate Diploma in Business Analytics Course Overview",
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "1-2 Years",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle:
      "Online Postgraduate Diploma in Business Analytics Course Overview",
    courseDesc:
      "The Online Postgraduate Diploma in Business Analytics program is designed to equip students with the skills and knowledge required to analyze data effectively and make data-driven decisions in various business contexts. Through a flexible online platform, students learn how to extract insights from data, develop predictive models, and communicate findings to stakeholders. This program prepares graduates for roles in business analytics, data science, and business intelligence across diverse industries.",
    componentsTitle: "Key Components",
    questioncontent1: "Foundations of Business Analytics:",
    answercontent1:
      "Introduction to business analytics concepts, methodologies, and applications. Understanding the role of data analytics in driving business performance and competitive advantage.",
    questioncontent2: "Data Management and Preparation:",
    answercontent2:
      " Data collection, integration, and preprocessing techniques. Data cleansing, transformation, and quality assurance processes.",
    questioncontent3: "Statistical Analysis and Modeling:",
    answercontent3:
      "Exploratory data analysis (EDA) techniques for understanding data distributions and relationships. Descriptive and inferential statistical methods for analyzing business data. Predictive modeling techniques, including regression analysis, time series forecasting, and classification algorithms.",
    questioncontent4: "Machine Learning and Data Mining:",
    answercontent4:
      "Introduction to machine learning algorithms and techniques. Supervised and unsupervised learning approaches for classification, clustering, and pattern recognition. Data mining techniques for discovering hidden patterns and trends in large datasets.",
    questioncontent5: "Big D-- a Mta Analytics:",
    answercontent5:
      "Understanding big data technologies and platforms, such as Hadoop and Spark. Processing and analyzing large volumes of structured and unstructured data. Implementing scalable analytics solutions for big data environments.",
    questioncontent6: "Data Visualization and Interpretation:",
    answercontent6:
      "Principles of data visualization and visual analytics. Tools and techniques for creating effective data visualizations and dashboards. Communicating data insights to non-technical stakeholders through visual storytelling.",
    questioncontent7: "Business Intelligence and Reporting:",
    answercontent7:
      "Developing business intelligence (BI) solutions for data-driven decision-making. Designing and implementing reporting systems and dashboards. Leveraging BI tools such as Tableau, Power BI, and QlikView for data analysis and visualization.",
    questioncontent8: "Advanced Analytics Applications:",
    answercontent8:
      "Applying analytics techniques to solve specific business problems and challenges. Case studies and real-world examples of analytics applications in marketing, finance, operations, and other functional areas.",
    questioncontent9: "Data Ethics and Privacy:",
    answercontent9:
      "Understanding ethical considerations and privacy concerns in data analytics. Compliance with data protection regulations and industry standards (e.g., GDPR, HIPAA).",
    questioncontent10: "Project Management and Implementation: ",
    answercontent10:
      "Planning, executing, and managing analytics projects from inception to completion. Collaboration with cross-functional teams and stakeholders throughout the project lifecycle.",
    questioncontent10: "Business Strategy and Decision Support: ",
    answercontent10:
      "Using data analytics to support strategic decision-making and business planning. Integrating analytics insights into business processes, operations, and performance measurement.",
    questioncontent10: "Emerging Trends in Business Analytics:",
    answercontent10:
      "Exploring emerging technologies and trends in business analytics (e.g., AI, machine learning, IoT). Assessing the implications of digital transformation on business analytics practices.",

    benefits:
      "Benefits of Online Postgraduate Diploma in Business Analytics Programs:",
    benefits1:
      "Flexibility: Study at your own pace and convenience, allowing you to balance education with work and other commitments.",
    benefits2:
      "Practical Skills: Gain hands-on experience through real-world projects, case studies, and interactive exercises.",
    benefits5:
      "Accessibility: Access course materials and lectures from anywhere with an internet connection, eliminating the need for relocation or travel.",
    benefits3:
      "Practical Skills: Gain hands-on experience in data analysis and visualization using Microsoft Excel.",
    benefits4:
      "Industry-Relevant Curriculum: Learn from experienced faculty and industry practitioners, ensuring that the curriculum remains current and relevant to industry needs.",
    benefits6:
      "Career Advancement: Develop the skills and expertise needed to pursue rewarding careers in business analytics, data science, and related fields across diverse industries.",
  },
  {
    id: 4,
    title: "Hospital Administration",
    coursepageimg: require("../../assets/hospitaladmisistration.png"),
    coursename:
      "Online Postgraduate Diploma in Hospital Administration Course Overview",
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "3-6 Months",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle:
      "Online Postgraduate Diploma in Hospital Administration Course Overview",
    courseDesc:
      "The Online Postgraduate Diploma in Hospital Administration program is designed to equip students with the knowledge and skills necessary to effectively manage healthcare facilities and navigate the complexities of the healthcare industry. Through a flexible online platform, students gain insights into various aspects of hospital administration, including healthcare management principles, healthcare finance, quality management, healthcare policy, and leadership. This program prepares graduates for leadership roles in hospitals, healthcare organizations, consulting firms, government agencies, and other healthcare-related settings.",
    componentsTitle: "Key Components",
    questioncontent1: "Introduction to Hospital Administration:",
    answercontent1:
      "Overview of hospital administration principles, roles, and responsibilities. Understanding the organizational structure and functions of healthcare facilities.",
    questioncontent2: "Healthcare Management Principles:",
    answercontent2:
      " Principles of healthcare management, including strategic planning, organizational behavior, and leadership. Developing and implementing healthcare policies, procedures, and protocols.",
    questioncontent3: "Healthcare Finance and Budgeting:",
    answercontent3:
      " Understanding healthcare financing mechanisms, including reimbursement models, insurance systems, and government funding. Budgeting, financial analysis, and cost control strategies in healthcare organizations.",
    questioncontent4: "Healthcare Quality Management: ",
    answercontent4:
      "Quality improvement methodologies, including Six Sigma, Lean Management, and Total Quality Management (TQM). Patient safety initiatives, risk management, and accreditation processes in healthcare settings.",
    questioncontent5: "Healt-- h Mcare Information Systems: ",
    answercontent5:
      "Introduction to healthcare information systems (HIS) and electronic health records (EHR). Utilizing health information technology (HIT) to improve clinical workflows, patient care, and administrative processes.",
    questioncontent6: "Healthcare Policy and Regulations:",
    answercontent6:
      "Understanding healthcare policy frameworks, government regulations, and compliance requirements. Analyzing healthcare policy issues, including access to care, healthcare disparities, and healthcare reform initiatives.",
    questioncontent7: "Healthcare Marketing and Communication: ",
    answercontent7:
      "Marketing strategies for healthcare organizations, including branding, advertising, and public relations. Effective communication strategies for healthcare professionals, patients, and stakeholders.",
    questioncontent8: "Ethical and Legal Issues in Healthcare:",
    answercontent8:
      "Ethical considerations in healthcare delivery, including patient rights, confidentiality, and informed consent. Legal principles and regulations governing healthcare, including liability, malpractice, and healthcare ethics.",
    questioncontent9: "Human Resource Management in Healthcare:",
    answercontent9:
      "Recruitment, training, and retention of healthcare professionals. Performance management, employee relations, and workforce development in healthcare settings.",
    questioncontent10: "Strategic Planning and Change Management: ",
    answercontent10:
      "Developing strategic plans for healthcare organizations, including goal-setting and performance measurement. Change management principles and techniques for implementing organizational changes in healthcare settings.",
    questioncontent11: "Healthcare Operations Management:",
    answercontent11:
      "Operational processes in healthcare delivery, including scheduling, resource allocation, and capacity planning. Supply chain management, inventory control, and logistics in healthcare organizations.",
    questioncontent12: "Emerging Trends in Healthcare:",
    answercontent12:
      "Exploring emerging trends and innovations in healthcare, such as telemedicine, digital health, and personalized medicine. Assessing the impact of technological advancements on healthcare delivery and management.",

    benefits:
      "Benefits of Online Postgraduate Diploma in Hospital Administration Programs:",
    benefits1:
      "Flexibility: Study at your own pace and convenience, allowing you to balance education with work and other commitments.",
    benefits2:
      "Accessibility: Access course materials and lectures from anywhere with an internet connection, eliminating the need for relocation or travel.",
    benefits3:
      "Practical Skills: Gain hands-on experience through case studies, simulations, and real-world projects in hospital administration and healthcare management.",
    benefits4:
      "Industry-Relevant Curriculum: Learn from experienced faculty and industry practitioners, ensuring that the curriculum remains current and aligned with industry trends and practices.",
    benefits4:
      "Career Advancement: Develop the skills and expertise needed to pursue rewarding careers in hospital administration, healthcare management, consulting, and related fields.",
  },
  {
    id: 5,
    title: "Banking and Finance",
    coursepageimg: require("../../assets/bankingfinance.png"),
    coursename:
      "Online Postgraduate Diploma in Banking and Financial Management Course Overview",
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "1-2 Years",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle:
      "Online Postgraduate Diploma in Banking and Financial Management Course Overview",
    courseDesc:
      "The Online Postgraduate Diploma in Banking and Financial Management program is designed to provide students with a comprehensive understanding of banking operations, financial markets, and investment management. Through a flexible online platform, students gain insights into various aspects of banking and finance, including banking regulations, financial analysis, risk management, and portfolio management. This program prepares graduates for careers in banking, financial services, investment management, and related fields.",
    componentsTitle: "Key Components",
    questioncontent1: "Introduction to Banking and Financial Services:",
    answercontent1:
      "Overview of the banking industry, financial markets, and financial institutions. Understanding the role of banks in the economy and their functions in providing financial services.",
    questioncontent2: "Banking Operations and Services:",
    answercontent2:
      "Study of banking operations, including deposit-taking, lending, and investment activities. Understanding banking products and services, such as loans, mortgages, and investment accounts.",
    questioncontent3: "Financial Markets and Instruments:",
    answercontent3:
      "Analysis of financial markets, including money markets, capital markets, and derivatives markets. Understanding financial instruments, such as stocks, bonds, options, futures, and swaps.",
    questioncontent4: "Banking Regulations and Compliance: ",
    answercontent4:
      "Overview of banking regulations and compliance requirements governing the banking industry. Understanding regulatory bodies, laws, and regulations related to banking operations and financial services.",
    questioncontent5: "Finan-- c Mial Analysis and Reporting: ",
    answercontent5:
      "Techniques for analyzing financial statements and evaluating the financial performance of banks and financial institutions. Financial ratio analysis, trend analysis, and benchmarking in banking and financial management.",
    questioncontent6: "Risk Management in Banking:",
    answercontent6:
      "Introduction to risk management principles and practices in banking. Identifying, measuring, and managing various types of financial risks, including credit risk, market risk, and operational risk.",
    questioncontent7: "Investment Management:",
    answercontent7:
      "Principles of investment management, including portfolio construction, asset allocation, and investment strategies. Evaluating investment opportunities, conducting investment research, and managing investment portfolios.",
    questioncontent8: "Corporate Finance:",
    answercontent8:
      "Understanding corporate finance principles and concepts, including capital budgeting, cost of capital, and capital structure. Financial decision-making in corporate settings, such as investment analysis, financing decisions, and dividend policy.",
    questioncontent9: "Financial Planning and Wealth Management:",
    answercontent9:
      "Techniques for financial planning, retirement planning, and wealth accumulation. Wealth management strategies for high-net-worth individuals and families.",
    questioncontent10: "International Banking and Finance:",
    answercontent10:
      "Overview of international banking operations and global financial markets. Understanding foreign exchange markets, international trade finance, and cross-border investment.",
    questioncontent11: "Financial Technology (Fintech) and Innovation: ",
    answercontent11:
      "Exploring financial technology (Fintech) innovations and their impact on banking and financial services. Analyzing Fintech trends, such as digital banking, blockchain technology, and peer-to-peer lending.",
    questioncontent12:
      "Ethics and Professional Standards in Banking and Finance:",
    answercontent12:
      "Understanding ethical considerations and professional standards in banking and financial management. Compliance with industry regulations, codes of conduct, and best practices.",

    benefits: "Benefits of Online Certificate Course in Digital Marketing:",
    benefits1:
      "Flexibility: Study at your own pace and convenience, allowing you to balance education with work and other commitments.",
    benefits2:
      "Accessibility: Access course materials and lectures from anywhere with an internet connection, eliminating the need for relocation or travel.",
    benefits3:
      "Practical Skills: Gain hands-on experience through case studies, simulations, and real-world projects in banking and financial management.",
    benefits4:
      "Industry-Relevant Curriculum: Learn from experienced faculty and industry practitioners, ensuring that the curriculum remains current and aligned with industry trends and practices.",
    benefits4:
      "Career Advancement: Develop the skills and expertise needed to pursue rewarding careers in banking, financial services, investment management, and related fields.",
  },
];
export default Sdata3;
