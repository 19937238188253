import React from "react";
import "./OnlineCoursepage.css"
export const OnlineCoursepage = () => {
  return (
    <div>
         <p className="text-center">Explore Online learning courses in India</p>
      <table>
        <tr>
         
        </tr>
        <tr>
          <td>MBA Course Online</td>
          <td>UG courses online India</td>
        </tr>
        <tr>
          <td>Online BBA Degree</td>
          <td>MCA Degree Online</td>
        </tr>
        <tr>
          <td>Online Degree India</td>
          <td>Online Courses in India</td>
        </tr>
        <tr>
          <td>Online Universities in India</td>
          <td>Best MBA Colleges Online</td>
        </tr>
        <tr>
          <td>MBA Programs Online</td>
          <td>Online-Courses-in-India</td>
        </tr>

        <tr>
          <td>Online UG PG Courses in India</td>
          <td>Masters Degree Program Distance</td>
        </tr>
        <tr>
         
        </tr>
      </table>
    </div>
  );
};
