const FaqData1 = [
    {
        question: "What is Online Learning  ?",
        answer:
            "Online Learning is that mode of learning that happens over the internet, using technological tools. Online Learning gives you the perks of attending the University remotely through online mode. It includes video lectures and live lectures, supplemented with reading material, presentations, case studies, assignments, assessments, and so on. Teaching-Learning process and the evaluation is carried out through Online Learning Management System."
    },
    {
        question: "What are the Online Programs ?",
        answer:
            "We are providing both Bachelor and Master's Degree Programs like BA/B.Com/BJMC/, MBA and MA/M.SC/M.Com/MJMC"
    },
    {
        question: "Who can enroll in Under-Graduate course ?",
        answer:
            "Students who are high school graduate (12th class pass) Looking to join the industry immediately. Flexible hours and access to opportunities to work will be provided to them. Focused on industry certifications with alternate career path will get credits for industry certification programmes integrated with the undergraduate degree. UG students pursuing degree at a college/university and want to avail a dual degree online for learning specialization and skills for 22nd century. Students looking to become a freelancer/entrepreneur in a chosen field. Special supervision will be provided for freelancing and entrepreneurship opportunity. Students having financial difficulty in providing for their higher education studies but have the skillset to make a meaningful, prosperous life for themselves. Financial aid, loans and work-based payment formats are available."
    },
    {
        question: "Who can enroll in Post graduate course ?",
        answer:
            "Students who are undergraduate in their field: - Looking to upgrade their profile with an industry led dynamic degree programme from a reputed university. Looking to shift their career into a new age 22nd century skill based industry that has a better potential and is future proof. Looking to pursue a competitive test prep or alternate career options that demands a post-graduation will get credits for industry certification programmes integrated with the undergraduate degrees. PG students continuing their degree at a college/university and want to avail a dual degree online for learning specialization and skills for 22nd century. Students looking to become a freelancer/entrepreneur in a chosen field. Special guidance will be provided for freelancing and entrepreneurship opportunity. Students having financial difficulty in providing for their higher education studies but have the skillset to make a meaningful, prosperous life for themselves. Financial aid, loans and work-based payment formats are available."
    }, {
        question: "Will the programme have live lectures and webinars?",
        answer:
            "Online degree programmes focuses on self-paced learning driven by video lectures and self-learning materials such as Recorded sessions, presentations, assessments, e-books, quizzes, and assignments. This is augmented by live online lectures (webinars) where students can interact directly with the faculty."
    }, {
        question: "Will my qualification acquired through Online mode be valued? Will it make me eligible for jobs and higher studies?",
        answer:
            "Yes, University Degree in Online mode is treated at par with regular mode Degree. You will be eligible for State and Central Government jobs, Private jobs, Higher Education like Ph.D. Education or jobs abroad."
    }, {
        question: "What placement assistance after completion of online degree?",
        answer:
            " We will help all their students online degree programs find a suitable career and support their efforts towards getting there. Our placement assistance programs are designed to bridge the talent gap impacting various industries and job markets."
    }
]
export default FaqData1;