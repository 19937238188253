import React from "react";
import Carousel from "./Carousel";
import "./Home.css";
import { ExplorePrograms } from "../Programs/ExplorePrograms";
import { Team } from "./Team";

import { Testimonial } from "./Testimonial";
import { Features } from "./Features";
import { Partners } from "../../PartnersPage/Partners";
import { ComparePage } from "../ComparePage/ComparePage";

export const Home = () => {
  return (
    <>
      <Carousel />
      <ExplorePrograms />
      <Features />
      {/* <Team /> */}
      {/* <Course /> */}
      {/* <ComparePage /> */}
      <Partners />
      <Testimonial />
      
      {/* {model} */}
    </>
  );
};
