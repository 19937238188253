const Accordata1 = [
    {
        question: "Is LPU Approved by UGC?",
        answer:
            "Yes LPU has been approved by UGC DEB to offer degree course in online mode."
    },
    {
        question: "Elective Specializations",
        answer:
            "Students may have the opportunity to choose elective courses or specializations based on their interests and career goals. Elective options may include areas such as entrepreneurship, human resources, international business, digital marketing, or supply chain management, allowing students to tailor their learning experience to suit their aspirations."
    },
    {
        question: "Online Learning Platform",
        answer:
            " The BBA program utilizes an advanced online learning platform that offers interactive lectures, multimedia resources, virtual classrooms, discussion forums, and collaborative projects. Through this platform, students can access course materials, engage with instructors and peers, participate in group discussions, and submit assignments conveniently from anywhere with an internet connection."
    },
    {
        question: "Flexible Schedule",
        answer:
            "Online BBA programs provide flexibility in terms of scheduling, allowing students to study at their own pace and manage their coursework around their existing commitments. This flexibility enables working professionals, busy individuals, or those residing in remote areas to pursue higher education without the constraints of a traditional on-campus program."
    }, {
        question: "Faculty Support",
        answer:
            "Experienced faculty members with expertise in their respective fields facilitate the online courses, providing guidance, feedback, and mentorship to students throughout their academic journey. Faculty members are often accessible through virtual office hours, email communication, or online chat platforms to address students' questions and concerns."
    }, {
        question: "Interactive Learning Tools",
        answer:
            "Online BBA programs incorporate interactive learning tools and technologies to enhance the learning experience. These may include virtual simulations, case studies, video lectures, quizzes, and multimedia presentations, allowing students to apply theoretical concepts to real-world scenarios and develop practical skills."
    }, {
        question: "Capstone Projects or Internships",
        answer:
            " Many online BBA programs culminate in a capstone project or internship experience, where students can apply their knowledge and skills to solve real-world business challenges or gain hands-on experience in a professional setting. These practical experiences help students integrate classroom learning with practical application and prepare them for entry into the workforce."
    }
];

export default Accordata1;
