import React, { useState, useEffect } from "react";
import { City, Country, State } from "country-state-city";
// import { Link } from "react-router-dom"
// import Accordion from "../components/Home/Accordion";
import { Link } from "react-router-dom";
import EmptyFile from "../components/Empty/EmptyFile";
import imgIcon from "../assets/check.png";
import imgIcon1 from "../assets/male.png";
import imgIcon2 from "../assets/female.png";
import imgIcon3 from "../assets/locke.png";
import imgform from "../assets/flat-university-concept.jpg";

import { useParams } from "react-router-dom";
import HeadTitle from "../components/HeadTitle/HeadTitle";
import "./singlepage.css";
import Sdata2 from "../components/Data/SData2";
import Selector from "../components/Common/Selector";

const PgCourse = () => {
  let countryData = Country.getAllCountries();
  const [stateData, setStateData] = useState();
  const [cityData, setCityData] = useState();

  const [country, setCountry] = useState(countryData[0]);
  const [state, setState] = useState();
  const [city, setCity] = useState();

  useEffect(() => {
    setStateData(State.getStatesOfCountry(country?.isoCode));
  }, [country]);

  useEffect(() => {
    setCityData(City.getCitiesOfState(country?.isoCode, state?.isoCode));
  }, [state]);

  useEffect(() => {
    stateData && setState(stateData[0]);
  }, [stateData]);

  useEffect(() => {
    cityData && setCity(cityData[0]);
  }, [cityData]);

  const { id } = useParams();
  const [item2, setItem2] = useState(null);
  const [enquiry, setEnquiry] = useState({
    fullname: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    gender: "",
    specialization: "",
  });
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setEnquiry({
      ...enquiry,
      [name]: value,
    });
  };
  const formSubmit = (e) => {
    e.preventDefault();
    console.log(enquiry);
  };
  useEffect(() => {
    let item2 = Sdata2.find((item2) => item2.id === parseInt(id));
    if (item2) {
      setItem2(item2);
    }
  }, [id]);

  return (
    <>
      <HeadTitle />

      {item2 ? (
        <div className="main-container">
          <div className="course-content">
            <div className="form-about">
              <div className="alert bg-skyblue formcontent-alert" role="alert">
                <h4 className="text-dark">Best Universites at Affordable Fees</h4>
              </div>
              <div className="alert-box bg-skyblue" role="alert-box">
                <h6 className="text-dark">
                  {" "}
                  We help you with real time data of Universities to help you
                  decide
                </h6>
              </div>

              <div className="alert-box bg-skyblue" role="alert-box">
                <h6 className="text-dark">
                  {" "}
                  We provide assitance from our dedicated and certified experts
                </h6>
              </div>
              <div className="signup-thumb">
                <img src={imgform} />
              </div>
            </div>
            <div className="form-area">
              <form
                id="contact-form"
                onSubmit={formSubmit}
                method="post"
                role="form"
              >
                <div className="signup-text text-center">
                  <div className="alert bg-skyblue" role="alert">
                    <h4 className="text-dark">
                      Compare and Select Best University for your Online and ODL
                      Distance Courses
                    </h4>
                  </div>

                  <div className="alert">
                    <h5 className="textprimary">
                      <span className="alert-img">
                        <img src={imgIcon} /> Get Approved University
                        <img src={imgIcon} />
                        100+ Placement Assistance
                      </span>
                    </h5>
                  </div>
                </div>

                <div className="signup-form-wrapper">
                  <input type="hidden" id="program" name="program" value="" />
                  <div className="signup-input-wrapper">
                    <input
                      type="text"
                      placeholder="Full Name"
                      id="name"
                      name="fullname"
                      className="input-border infoPlaceholder"
                      value={enquiry.fullname}
                      required
                      onChange={handleInput}
                      autoComplete="off"
                    />
                  </div>

                  <div className="signup-input-wrapper">
                    <input
                      type="text"
                      placeholder="Email"
                      id="email"
                      name="email"
                      className="input-border infoPlaceholder"
                      value={enquiry.email}
                      required
                      onChange={handleInput}
                      autoComplete="off"
                    />
                  </div>

                  <div className="signup-input-wrapper">
                    <input
                      type="text"
                      placeholder="Mobile Number"
                      id="phone"
                      name="phone"
                      className="input-border infoPlaceholder"
                      value={enquiry.phone}
                      required
                      onChange={handleInput}
                      autoComplete="off"
                    />
                  </div>
                  <div className="enquiry-input-wrapper">
                   
                   <Selector
                     data={countryData}
                     selected={country}
                     setSelected={setCountry}
                     className="enquiry-txt input-border"
                     name="state"
                     id="state"
                     value={enquiry.state}
                     required
                     onChange={handleInput}
                     autoComplete="off"
                   />
                 </div>
                 {state && (
                   <div className="enquiry-input-wrapper">
                    
                     <Selector
                       data={stateData}
                       selected={state}
                       setSelected={setState}
                       className="enquiry-txt input-border"
                       name="city"
                       id="city"
                       value={enquiry.city}
                       required
                       onChange={handleInput}
                       autoComplete="off"
                     />

                   </div>
                 )}
                 {city && (
                   <div className="enquiry-input-wrapper">
                    
                     <Selector
                       data={cityData}
                       selected={city}
                       setSelected={setCity}
                       className="enquiry-txt input-border"
                       name="city"
                       id="city"
                       value={enquiry.city}
                       required
                       onChange={handleInput}
                       autoComplete="off" />
                   </div>
                 )}

                  <div className="signup-action">
                    <div className="course-sidebar-list ">
                      <div className="input-border radio txt">
                        <input
                          className="signup-checkbo input-border infoPlaceholder"
                          type="radio"
                          id="sing-up"
                          name="gender"
                          value={enquiry.gender}
                          required
                          onChange={handleInput}
                          autoComplete="off"
                        />
                        <label className="sign-check " htmlFor="sing-up">
                          <span className="text-dark">
                            {" "}
                            <img
                              src={imgIcon1}
                              alt="male"
                              className="img-fluid"
                            />
                            Male
                          </span>
                        </label>
                      </div>

                      <div className="input-border radio txt">
                        <input
                          className="signup-checkbo input-border infoPlaceholder"
                          type="radio"
                          id="sing-up"
                          name="gender"
                          value={enquiry.gender}
                          required
                          onChange={handleInput}
                          autoComplete="off"
                        />
                        <label className="sign-check" htmlFor="sing-up">
                          <span className="text-dark">
                            <img
                              src={imgIcon2}
                              alt="female"
                              className="img-fluid"
                            />
                            Female
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <select
                    className="form-control  txt input-border infoPlaceholder"
                    name="course_name"
                    id="course_name"
                    value={enquiry.specialization}
                    required
                    onChange={handleInput}
                    autoComplete="off"
                  >
                    <option>Select Specialization</option>
                    <option>Not decided yet</option>

                    <option value="General">General</option>
                    <option value="HRM">HRM</option>
                    <option value="IT">IT</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Finance">Finance</option>
                    <option value="Agri Business">Agri Business</option>
                    <option value="Risk Management">Risk Management</option>
                    <option value="International Business">
                      International Business
                    </option>
                    <option value="Retail">Retail</option>
                    <option value="Operations">Operations</option>
                    <option value="Data Science">Data Science</option>
                    <option value="Business Analytics & Intelligence">
                      Business Analytics & Intelligence
                    </option>
                    <option value="Digital Marketing">Digital Marketing</option>
                    <option value="Branding & Advertising Management">
                      Branding & Advertising Management
                    </option>
                    <option value="Entreprenuership Management">
                      Entreprenuership Management
                    </option>
                    <option value="Foreign Trade & Global Business Management">
                      Foreign Trade & Global Business Management
                    </option>
                    <option value="Ecommerce  Marketing Management">
                      Ecommerce Marketing Management
                    </option>
                    <option value="Foreign Trade & Global Business Management">
                      Foreign Trade & Global Business Management
                    </option>
                    <option value="Health Care Management">
                      Health Care Management
                    </option>
                    <option value="Mass Communication">
                      Mass Communication
                    </option>
                    <option value="Investment Banking & Wealth Management">
                      Investment Banking & Wealth Management
                    </option>
                    <option value="Media & Entertainment Management">
                      Media & Entertainment Management
                    </option>
                    <option value="Project Leadership & Management">
                      Project Leadership & Management
                    </option>
                    <option value="Strategic Management">
                      Strategic Management
                    </option>
                    <option value="Operations & Production Management">
                      Operations & Production Management
                    </option>
                    <option value="Banking">Banking</option>
                    <option value="Business Leadership">
                      Business Leadership
                    </option>
                    <option value="Financial Planning & Analysis">
                      Financial Planning & Analysis
                    </option>
                    <option value="Supply Chain Management">
                      Supply Chain Management
                    </option>
                    <option value="Analytics & Data Science">
                      Analytics & Data Science
                    </option>
                    <option value="IT and Fintech">IT and Fintech</option>
                    <option value="Information Systems Management">
                      Information Systems Management
                    </option>
                    <option value="Total  Quality Management">
                      Total Quality Management
                    </option>

                    <option value="ECONOMICS">ECONOMICS</option>
                    <option value="MATHS">MATHS</option>
                    <option value="POLITICAL SCIENCE">POLITICAL SCIENCE</option>
                    <option value="SOCIOLOGY">SOCIOLOGY</option>
                    <option value="ENGLISH">ENGLISH</option>
                    <option value="JOURNALISM & MASS COMMUNICATION">
                      JOURNALISM & MASS COMMUNICATION
                    </option>
                    <option value="HISTORY">HISTORY</option>

                    <option value="MATHEMATICS">MATHEMATICS</option>
                    <option value="DATA  SCIENCE">DATA SCIENCE</option>

                    <option value="Accounting">Accounting</option>

                    <option value="MCA">MCA</option>

                    <option value="GENERAL">GENERAL</option>
                    <option value="ARTIFICIAL INTELLIGENCE">
                      ARTIFICIAL INTELLIGENCE
                    </option>
                    <option value="DATA SCIENCE">DATA SCIENCE</option>
                    <option value="CLOUD TECHNOLOGY & CYBER SECURITY">
                      CLOUD TECHNOLOGY & CYBER SECURITY
                    </option>
                    <option value="RPA">RPA</option>
                    <option value="BLOCKCHAIN">BLOCKCHAIN</option>
                    <option value="UX">UX</option>

                    <option value="B.COM">B.COM</option>

                    <option value="GENERAL">GENERAL</option>
                    <option value="AVIATION">AVIATION</option>
                    <option value="ENTERPRISE RISK">ENTERPRISE RISK</option>
                    <option value="DIGITAL MARKETING">DIGITAL MARKETING</option>
                    <option value="RETAIL">RETAIL</option>
                    <option value="FINTECH">FINTECH</option>
                    <option value="TRAVEL  & TOURISM">TRAVEL & TOURISM</option>
                    <option value="BANKING FINANCE & INSURANCE">
                      BANKING FINANCE & INSURANCE
                    </option>
                    <option value="ENTREPRENEURSHIP">ENTREPRENEURSHIP</option>
                    <option value="HUMAN  CAPITAL  MANAGEMENT">
                      HUMAN CAPITAL MANAGEMENT
                    </option>
                    <option value="BUSINESS ANALYTICS">
                      BUSINESS ANALYTICS
                    </option>
                    <option value="INTERNATIONAL FINANCE">
                      INTERNATIONAL FINANCE
                    </option>

                    <option value="GENERAL">GENERAL</option>
                    <option value="JOURNALISM & MASS COMMUNICATION">
                      JOURNALISM & MASS COMMUNICATION
                    </option>

                    <option value="GENERAL">GENERAL</option>
                    <option value="JOURNALISM & MASS COMMUNICATION">
                      JOURNALISM & MASS COMMUNICATION
                    </option>
                  </select>

                  <div className="sing-buttom mb-20">
                    <button className="sing-btn sign" type="submit">
                      Find Best University
                    </button>
                  </div>

                  <div className=" bg-skyblue form-subalert" role="alert">
                    <img src={imgIcon3} alt="" /> Your personal information is
                    secure with us
                  </div>
                </div>
              </form>
            </div>
          </div>
          <section className="course-detalis-area">
            <div className="row">
              <div className="course-detalis-wrapper ">
                <div className="course-heading ">
                  <h1 className="courses-title">{item2.coursename}</h1>
                </div>
                <div className="coursedetails-container">
                  <div className="course-images">
                    <img src={item2.coursepageimg} alt="course img" />
                  </div>
                  <div className="course-description">

                    <div className="">
                      <h2 className="course-leranm-tittle">{item2.courseTitle}</h2>
                    </div>
                    <p>{item2.courseDesc}</p>
                  </div>
                </div>
                <div className="course-detelis-meta">
                  <div className="course-Enroll border-line-meta">
                    <p>{item2.univers}</p>
                    <span>{item2.universCount}</span>
                  </div>
                  <div className="course-update border-line-meta">
                    <p>{item2.duration}</p>
                    <span>{item2.durationYear}</span>
                  </div>
                  <div className="course-category">
                    <p>{item2.eligibility}</p>
                    <span>{item2.qualif}</span>
                  </div>
                </div>
                <div className="course-learn">
                  <div className="">
                    <h2 className="course-leranm-tittle">
                      {item2.componentsTitle}
                    </h2>
                  </div>
                  <p className="course-components">
                    <b>{item2.questioncontent1}</b> {item2.answercontent1}
                  </p>
                  <p className="course-components">
                    <b>{item2.questioncontent2}</b> {item2.answercontent2}
                  </p>
                  <p className="course-components">
                    <b>{item2.questioncontent3}</b> {item2.answercontent3}
                  </p>
                  <p className="course-components">
                    <b>{item2.questioncontent4}</b> {item2.answercontent4}
                  </p>
                  <p className="course-components">
                    <b>{item2.questioncontent5}</b> {item2.answercontent5}
                  </p>
                  <p className="course-components">
                    <b>{item2.questioncontent6}</b> {item2.answercontent6}
                  </p>
                  <p className="course-components">
                    <b>{item2.questioncontent7}</b> {item2.answercontent7}
                  </p>
                  <p className="course-components">
                    <b>{item2.questioncontent8}</b> {item2.answercontent8}
                  </p>
                  <p className="course-components">
                    <b>{item2.questioncontent9}</b> {item2.answercontent9}
                  </p>
                  {/* <Accordion /> */}
                </div>
                <div className="course-requirements">
                  <h2 className="course-leranm-tittle">{item2.enlightenTitle}</h2>
                  <p>{item2.enlightendesc}</p>
                  <div className=""></div>
                </div>
                <div className="course-requirements">
                  <h2 className="course-leranm-tittle">
                    SPECIALIZATIONS AVAILABLE
                  </h2>
                  <p>(Electives as per different Universities)</p>
                  <ul>
                    <li>

                      <Link to={`/CoursePage${id}`}>
                        {item2.specialization1}
                      </Link>
                    </li>
                    <li>

                      <Link to={`/CoursePage${id}`}>
                        {item2.specialization2}
                      </Link>
                    </li>
                    <li>

                      <Link to={`/CoursePage${id}`}>
                        {item2.specialization3}
                      </Link>
                    </li>
                    <li>

                      <Link to={`/CoursePage${id}`}>
                        {item2.specialization4}
                      </Link>
                    </li>
                    <li>

                      <Link to={`/CoursePage${id}`}>
                        {item2.specialization5}
                      </Link>
                    </li>
                    <li>

                      <Link to={`/CoursePage${id}`}>
                        {item2.specialization6}
                      </Link>
                    </li>
                    <li>

                      <Link to={`/CoursePage${id}`}>
                        {item2.specialization7}
                      </Link>
                    </li>
                    <li>

                      <Link to={`/CoursePage${id}`}>
                        {item2.specialization8}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="course-requirements">
                  <h2 className="course-leranm-tittle">
                    {item2.scopeandcareerProspects}
                  </h2>
                  <div>
                    <li>

                      {item2.scopeandcareer1}
                    </li>
                    <li>

                      {item2.scopeandcareer2}
                    </li>
                    <li>

                      {item2.scopeandcareer3}
                    </li>
                    <li>

                      {item2.scopeandcareer4}
                    </li>
                    <li>

                      {item2.scopeandcareer5}
                    </li>
                    <li>

                      {item2.scopeandcareer6}
                    </li>
                    <li>

                      {item2.scopeandcareer7}
                    </li>
                    <li>

                      {item2.scopeandcareer8}
                    </li>
                    <li>

                      {item2.scopeandcareer9}
                    </li>
                    <li>

                      {item2.scopeandcareer10}
                    </li>
                    <li>

                      {item2.scopeandcareer11}
                    </li>
                  </div>
                </div>

                <div className="course-requirements-child">
                  <h4>{item2.growthandcareer}</h4>

                  <div>
                    <p className="course-description">
                      <b>{item2.growthandcareertitle1}</b>{" "}
                      {item2.growthandcareerdesc1}
                    </p>
                    <p className="course-description">
                      <b>{item2.growthandcareertitle2}</b>{" "}
                      {item2.growthandcareerdesc2}
                    </p>
                    <p className="course-description">
                      <b>{item2.growthandcareertitle3}</b>{" "}
                      {item2.growthandcareerdesc3}
                    </p>
                    <p className="course-description">
                      <b>{item2.growthandcareertitle4}</b>{" "}
                      {item2.growthandcareerdesc4}
                    </p>
                    <p className="course-description">
                      <b>{item2.growthandcareertitle5}</b>{" "}
                      {item2.growthandcareerdesc5}
                    </p>
                    <p className="course-description">
                      <b>{item2.growthandcareertitle6}</b>{" "}
                      {item2.growthandcareerdesc6}
                    </p>
                    <p className="course-description">
                      <b>{item2.growthandcareertitle7}</b>{" "}
                      {item2.growthandcareerdesc7}
                    </p>
                    <p className="course-description">
                      <b>{item2.growthandcareertitle8}</b>{" "}
                      {item2.growthandcareerdesc8}
                    </p>
                    <p className="course-description">
                      <b>{item2.growthandcareertitle9}</b>{" "}
                      {item2.growthandcareerdesc9}
                    </p>
                    <p className="course-description">
                      {" "}
                      {item2.growthandcareerdesc10}
                    </p>
                  </div>
                </div>
                <div className="course-requirements">
                  <h2 className="course-leranm-tittle">{item2.whyonline}</h2>
                  <div>
                    <li className="why-online">

                      {item2.whyonline1}
                    </li>
                    <li className="why-online">

                      {item2.whyonline2}
                    </li>
                    <li className="why-online">

                      {item2.whyonline3}
                    </li>
                    <li className="why-online">

                      {item2.whyonline4}
                    </li>
                    <li className="why-online">

                      {item2.whyonline5}
                    </li>
                    <li className="why-online">

                      {item2.whyonline6}
                    </li>

                    <p>{item2.whyonline7}</p>
                  </div>
                </div>
                <div className="course-requirements">
                  <h2 className="course-leranm-tittle">Admission Procedure</h2>

                  <img src={item2.admissionprocedure} alt="Why Course" />
                </div>
                <div className="course-requirements">
                  <h2 className="course-leranm-tittle">{item2.benefits}</h2>

                  <div>
                    <li>

                      {item2.benefits1}
                    </li>
                    <li>

                      {item2.benefits2}
                    </li>
                    <li>

                      {item2.benefits3}
                    </li>
                    <li>

                      {item2.benefits4}
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <EmptyFile />
      )}
    </>
  );
};

export default PgCourse;
