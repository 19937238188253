const Sdata4 = [
  {
    id: 1,
    title: "Digital Marketing",
    partnersname: "VGU",
    coursepageimg: require("../../assets/digitalmarketing.png"),
    coursename: "Certificate Programmes In Digital Marketing Course Overview",
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "3-6 Months",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle: "Certificate Programmes In Digital Marketing Program Review",
    courseDesc:
      "The Online Certificate Course in Digital Marketing offers participants a comprehensive understanding of key concepts, tools, and strategies in the rapidly evolving field of digital marketing. This program is designed to equip students with practical skills and knowledge necessary to excel in various digital marketing roles. Through a flexible online platform, participants learn about essential digital marketing channels, techniques, and best practices, preparing them for career opportunities in marketing, advertising, e-commerce, and related fields.",
    componentsTitle: "Key Components",
    questioncontent1: "Introduction to Digital Marketing:",
    answercontent1:
      "Overview of digital marketing landscape, its evolution, and significance in todays business environment. Understanding key digital marketing concepts, terminology, and industry trends.",
    questioncontent2: "Website Development and Optimization:",
    answercontent2:
      "Basics of website development and design principles. Website optimization techniques for improved user experience and search engine visibility.",
    questioncontent3: "Search Engine Optimization (SEO):",
    answercontent3:
      "Fundamentals of SEO and its importance in driving organic traffic to websites. On-page and off-page SEO techniques, keyword research, and SEO analytics.",
    questioncontent4: "Pay-Per-Click (PPC) Advertising: ",
    answercontent4:
      "Introduction to PPC advertising platforms (e.g., Google Ads, Facebook Ads). Creating and managing PPC campaigns, keyword targeting, ad copywriting, and bid management.",
    questioncontent5: "Social Media Marketing (SMM): ",
    answercontent5:
      "Understanding social media platforms, audience targeting, and content strategies. Creating engaging social media content, managing social media campaigns, and analyzing social media metrics.",
    questioncontent6: "Email Marketing:",
    answercontent6:
      "Basics of email marketing, including email list building and segmentation. Designing effective email campaigns, writing compelling email copy, and measuring email campaign performance.",
    questioncontent7: "Content Marketing:",
    answercontent7:
      "Principles of content marketing and its role in attracting and engaging audiences. Creating and distributing valuable content across various digital channels.",
    questioncontent8: "Digital Analytics:",
    answercontent8:
      "Introduction to digital analytics tools (e.g., Google Analytics) and metrics. Tracking and analyzing website traffic, user behavior, and campaign performance.",
    questioncontent9: "Mobile Marketing:",
    answercontent9:
      "Overview of mobile marketing strategies and best practices. Optimizing digital marketing efforts for mobile devices and targeting mobile audiences.",
    questioncontent10: "Digital Marketing Strategy:",
    answercontent10:
      "Developing comprehensive digital marketing strategies aligned with business goals. Integrating various digital marketing channels to maximize impact and ROI.",
    questioncontent11: "Emerging Trends in Digital Marketing: ",
    answercontent11:
      "Exploring emerging technologies and trends shaping the future of digital marketing. Understanding the impact of artificial intelligence, voice search, and other innovations on digital marketing strategies.",
    questioncontent12: "Practical Projects and Case Studies:",
    answercontent12:
      "Hands-on projects and real-world case studies to apply digital marketing concepts and techniques. Analyzing and optimizing digital marketing campaigns to achieve business objectives.",

    benefits: "Benefits of Online Certificate Course in Digital Marketing:",
    benefits1:
      "Flexibility: Study at your own pace and convenience, fitting education around your schedule.",
    benefits2:
      "Accessibility: Access course materials and lectures from anywhere with an internet connection.",
    benefits3:
      "Practical Skills: Gain hands-on experience through practical projects, simulations, and real-world examples.",
    benefits4:
      "Industry-Relevant Curriculum: Learn from experienced instructors and industry professionals, ensuring the curriculum is up-to-date and relevant.",
    benefits5:
      "Career Advancement: Acquire skills sought after by employers in the digital marketing field, opening up career opportunities and advancement prospects.",
  },
  {
    id: 2,
    title: "Hospital and Healthcare",
    coursepageimg: require("../../assets/hospitalandhealthcare.png"),
    coursename:
      "Online Certificate Course in Hospital and Healthcare Management Overview",
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "3-6 Months",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle:
      "Online Certificate Course in Hospital and Healthcare Management Overview",
    courseDesc:
      "The Online Certificate Course in Hospital and Healthcare Management offers participants a comprehensive understanding of key concepts, principles, and practices in the healthcare industry. This program is designed to equip students with practical skills and knowledge necessary to effectively manage healthcare facilities and navigate the complexities of healthcare administration. Through a flexible online platform, participants learn about essential healthcare management topics, preparing them for career opportunities in hospital administration, healthcare management, consulting, and related fields.",
    componentsTitle: "Key Components",
    questioncontent1: "Introduction to Healthcare Management:",
    answercontent1:
      "Overview of the healthcare industry, including its structure, stakeholders, and regulatory environment. Understanding the role of healthcare managers and administrators in healthcare delivery.",
    questioncontent2: "Healthcare Leadership and Governance:",
    answercontent2:
      "Principles of healthcare leadership, including strategic planning, decision-making, and team management. Understanding healthcare governance structures and their impact on organizational performance.",
    questioncontent3: "Healthcare Operations Management:",
    answercontent3:
      "Basics of healthcare operations management, including resource allocation, scheduling, and workflow optimization. Improving efficiency and productivity in healthcare delivery.",
    questioncontent4: "Healthcare Quality Improvement: ",
    answercontent4:
      "Introduction to quality improvement methodologies in healthcare, such as Six Sigma, Lean Management, and Total Quality Management (TQM). Ensuring patient safety, improving healthcare outcomes, and enhancing the patient experience.",
    questioncontent5: "Healthcare Financial Management: ",
    answercontent5:
      "Basics of healthcare finance, including budgeting, revenue cycle management, and cost control. Understanding healthcare reimbursement systems and financial decision-making in healthcare organizations.",
    questioncontent6: "Healthcare Information Systems:",
    answercontent6:
      "Overview of healthcare information systems (HIS) and electronic health records (EHR). Utilizing health information technology (HIT) to improve clinical workflows, data management, and decision support.",
    questioncontent7: "Healthcare Policy and Regulation:",
    answercontent7:
      "Understanding healthcare policy frameworks, government regulations, and compliance requirements. Analyzing healthcare policy issues and their implications for healthcare management and administration.",
    questioncontent8: "Human Resources Management in Healthcare:",
    answercontent8:
      "Recruitment, training, and retention of healthcare professionals. Performance management, employee relations, and workforce development in healthcare settings.",
    questioncontent9: "Ethical and Legal Issues in Healthcare:",
    answercontent9:
      "Understanding ethical considerations and legal principles in healthcare delivery. Ensuring compliance with healthcare regulations, patient rights, and confidentiality laws.",
    questioncontent10: "Strategic Planning in Healthcare:",
    answercontent10:
      "Developing strategic plans for healthcare organizations, setting goals, and defining strategies. Aligning organizational objectives with external trends, market forces, and stakeholder expectations.",
    questioncontent11: "Healthcare Marketing and Communication: ",
    answercontent11:
      "Marketing strategies for healthcare organizations, including branding, advertising, and patient engagement. Effective communication strategies for healthcare professionals, patients, and stakeholders.",
    questioncontent12: "Emerging Trends in Healthcare Management:",
    answercontent12:
      "Exploring emerging technologies, trends, and innovations in healthcare management. Assessing the impact of digital transformation, telemedicine, and personalized medicine on healthcare delivery.",

    benefits:
      "Benefits of Online Certificate Course in Hospital and Healthcare Management:",
    benefits1:
      "Flexibility: Study at your own pace and convenience, fitting education around your schedule.",
    benefits2:
      "Accessibility: Access course materials and lectures from anywhere with an internet connection.",
    benefits3:
      "Practical Skills: Gain hands-on experience through case studies, simulations, and real-world examples.",
    benefits4:
      "Industry-Relevant Curriculum: Learn from experienced instructors and industry professionals, ensuring the curriculum is up-to-date and aligned with industry standards.",
    benefits5:
      "Career Advancement: Acquire skills sought after by employers in the healthcare industry, opening up career opportunities and advancement prospects.",
  },
  {
    id: 3,
    title: "Business Analytics with Excel",
    coursepageimg: require("../../assets/businessanalyticswithexcel.png"),
    coursename:
      "Online Certificate Course in Business Analytics with Excel Overview",
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "3-6 Months",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle:
      "Online Certificate Course in Business Analytics with Excel Overview",
    courseDesc:
      "This certificate course provides a deep understanding of debt instruments and money market securities, along with the functioning and operations of the money market. Participants will gain insights into various debt instruments, including bonds, treasury bills, commercial papers, and other fixed-income securities. The course will cover the mechanics of money markets, including short- term borrowing and lending, liquidity management, and interest rate determination.",
    componentsTitle: "Key Components",
    questioncontent1: "Introduction to Business Analytics:",
    answercontent1:
      "Overview of business analytics and its importance in driving data-driven decision-making. Understanding key concepts and terminology in business analytics.",
    questioncontent2: "Excel Fundamentals:",
    answercontent2:
      "Basic functions and features of Microsoft Excel. Introduction to data entry, formatting, and formulae in Excel.",
    questioncontent3: "Data Manipulation and Cleaning:",
    answercontent3:
      "Techniques for importing, cleaning, and transforming data in Excel. Data validation and error handling processes.",
    questioncontent4: "Data Analysis with Excel: ",
    answercontent4:
      "Exploratory data analysis (EDA) techniques in Excel. Statistical functions and tools for data analysis.",
    questioncontent5: "Data Visualization: ",
    answercontent5:
      "Creating charts, graphs, and dashboards in Excel. Visualizing data trends and patterns for effective communication.",
    questioncontent6: "PivotTables and PivotCharts:",
    answercontent6:
      "Introduction to PivotTables and PivotCharts for data summarization and analysis. Analyzing large datasets and generating insights using PivotTables.",
    questioncontent7: "Advanced Excel Functions:",
    answercontent7:
      "Advanced functions and formulas in Excel for complex data analysis. Using lookup functions, logical functions, and array formulas.",
    questioncontent8: "Statistical Analysis with Excel:",
    answercontent8:
      "Conducting statistical analysis using Excel&#39;s built-in tools. Regression analysis, hypothesis testing, and correlation analysis in Excel.",
    questioncontent9: "Forecasting and Predictive Analytics:",
    answercontent9:
      "Techniques for forecasting and predictive modeling in Excel. Time series analysis, trend analysis, and forecasting methods.",
    questioncontent10: "Optimization and Decision Models:",
    answercontent10:
      "Building optimization models and decision trees in Excel. Solving business problems and making data-driven decisions.",
    questioncontent11: "Data Mining and Machine Learning: ",
    answercontent11:
      "Introduction to data mining techniques and machine learning algorithms in Excel. Implementing predictive models and classification algorithms.",
    questioncontent12: "Real-World Applications and Case Studies:",
    answercontent12:
      "Applying business analytics concepts and techniques to real-world business scenarios. Analyzing case studies and solving business problems using Excel.",
    benefits:
      "Benefits of Online Certificate Course in Business Analytics with Excel:",
    benefits1:
      "Practical Skills: Gain hands-on experience in data analysis and visualization using Microsoft Excel.",
    benefits2:
      "Flexibility: Study at your own pace and convenience, with access to course materials online.",
    benefits3:
      "Cost-Effective: Affordable option for acquiring valuable business analytics skills.",
    benefits4:
      "Industry-Relevant Curriculum: Learn skills directly applicable to various industries and business functions.",
    benefits5:
      "Career Advancement: Enhance your resume and job prospects with in-demand business analytics skills.",
  },
  {
    id: 4,
    title: "Technological Banking and Finance",
    coursepageimg: require("../../assets/technological.png"),
    coursename:
      "Online Certificate Course in Technological Banking and Banking and Financial Services",
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "3-6 Months",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle:
      "Online Certificate Course in Technological Banking and Banking and Financial Services",
    courseDesc:
      "This certificate course provides comprehensive training in both technological advancements within the banking sector and fundamental principles of banking and financial services. Participants will gain insights into the integration of technology into banking operations, cybersecurity measures, financial products, and regulatory compliance. The course combines theoretical knowledge with practical applications to equip learners with essential skills for careers in modern banking and finance.",
    componentsTitle: "Key Components",
    questioncontent1: "Introduction to Banking and Financial Services:",
    answercontent1:
      "Overview of the banking industry. Types of financial institutions. Functions and roles of banks. Evolution of banking and financial services",
    questioncontent2: "Technological Advancements in Banking:",
    answercontent2:
      "Digital transformation in banking. Online banking platforms and mobile applications. Emerging technologies in banking (e.g., blockchain, AI). Impact of technology on banking operations and customer experience.",
    questioncontent3: "Cybersecurity in Banking:",
    answercontent3:
      "Understanding cybersecurity threats in banking. Data protection and privacy regulations. Risk management strategies. Best practices for cybersecurity in banking.",
    questioncontent4: "Financial Products and Services: ",
    answercontent4:
      "Overview of financial products (e.g., loans, savings accounts, investments). Introduction to investment banking services. Credit and debit card operations. Wealth management and advisory services.",
    questioncontent5: "Banking Operations and Risk Management: ",
    answercontent5:
      "Core banking operations (e.g., deposit-taking, lending). Risk management principles. Regulatory compliance and governance. Anti-money laundering (AML) and Know Your Customer (KYC) procedures.",
    questioncontent6: "Customer Relationship Management:",
    answercontent6:
      "Importance of customer service in banking. Strategies for building and maintaining customer relationships. Cross-selling and upselling techniques. Handling customer inquiries and complaints effectively.",
    questioncontent7: "Regulatory Environment and Compliance:",
    answercontent7:
      "Overview of banking regulations and laws. Compliance frameworks and standards. International banking regulations. Ethical considerations in banking and financial services.",

    benefits:
      "Benefits of Online Certificate Course in Business Analytics with Excel:",
    benefits1:
      "Practical Skills: Gain hands-on experience in data analysis and visualization using Microsoft Excel.",
    benefits2:
      "Flexibility: Study at your own pace and convenience, with access to course materials online.",
    benefits3:
      "Cost-Effective: Affordable option for acquiring valuable business analytics skills.",
    benefits4:
      "Industry-Relevant Curriculum: Learn skills directly applicable to various industries and business functions.",
    benefits5:
      "Career Advancement: Enhance your resume and job prospects with in-demand business analytics skills.",
  },
  {
    id: 5,
    title: "Hospital Administration",
    coursepageimg: require("../../assets/jospitaladministraion.png"),
    coursename:
      "Online Certificate Course in Hospital Administration",
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "3-6 Months",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle:
      "Online Certificate Course in Hospital Administration",
    courseDesc:
      "The certificate course in Hospital Administration provides comprehensive training in healthcare management, focusing on the administrative and operational aspects of running a hospital or healthcare facility. Participants will gain insights into healthcare policy, finance, human resources management, quality improvement, and regulatory compliance, preparing them for leadership roles in the healthcare industry.",
    componentsTitle: "Key Components",
    questioncontent1: "Introduction to Healthcare Management:",
    answercontent1:
      "Overview of healthcare systems and delivery models. Roles and responsibilities of hospital administrators. Ethical and legal considerations in healthcare management. Trends and challenges in healthcare administration",
    questioncontent2: "THealthcare Policy and Regulation:",
    answercontent2:
      "Understanding healthcare policies and regulations. Compliance with healthcare laws (HIPAA, EMTALA, etc.). Accreditation and quality assurance standards (JCI, NABH, etc.). Role of government agencies in healthcare regulation",
    questioncontent3: "Healthcare Finance and Budgeting:",
    answercontent3:
      "Basics of healthcare finance and accounting. Budgeting and financial planning for healthcare organizations. Revenue cycle management and reimbursement processes. Financial analysis and performance metrics.",
    questioncontent4: "Human Resources Management: ",
    answercontent4:
      "Recruitment and retention strategies for healthcare professionals. Staffing models and workforce planning. Training and development programs. Employee relations and performance management.",
    questioncontent5: "Hospital Operations Management: ",
    answercontent5:
      "Facility planning and management. Inventory and supply chain management. Patient flow optimization. Information technology in healthcare operations (Electronic Health Records, Hospital Information Systems)",
    questioncontent6: "Quality Improvement and Patient Safety:",
    answercontent6:
      "Quality improvement methodologies (Six Sigma, Lean, PDCA cycle). Patient safety initiatives and protocols. Risk management and adverse event reporting. Continuous quality monitoring and improvement strategies.",
    questioncontent7: "Healthcare Marketing and Public Relations:",
    answercontent7:
      "Marketing strategies for healthcare services. Branding and positioning of healthcare organizations. Community outreach and engagement. Crisis management and public relations in healthcare",

    benefits:
      "Benefits of Online Certificate Course in Business Analytics with Excel:",
    benefits1:
      "Practical Skills: Gain hands-on experience in data analysis and visualization using Microsoft Excel.",
    benefits2:
      "Flexibility: Study at your own pace and convenience, with access to course materials online.",
    benefits3:
      "Cost-Effective: Affordable option for acquiring valuable business analytics skills.",
    benefits4:
      "Industry-Relevant Curriculum: Learn skills directly applicable to various industries and business functions.",
    benefits5:
      "Career Advancement: Enhance your resume and job prospects with in-demand business analytics skills.",
 
    
    },
  {
    id: 6,
    title: "Debt and Money Market",
    coursepageimg: require("../../assets/debtandmoneymarket.png"),
    coursename:
      "Online Certificate Course in Debt and Money Market Overview",
    univers: "University",
    universCount: "10",
    duration: "Duration",
    durationYear: "3 to 6 months,",
    eligibility: "Eligibility",
    qualif: "12th Passout",
    courseTitle:
      "Online Certificate Course in Debt and Money Market Overview",
    courseDesc:
      "This certificate course provides a deep understanding of debt instruments and money market securities, along with the functioning and operations of the money market. Participants will gain insights into various debt instruments, including bonds, treasury bills, commercial papers, and other fixed-income securities. The course will cover the mechanics of money markets, including short- term borrowing and lending, liquidity management, and interest rate determination.",
    componentsTitle: "Key Components",
    questioncontent1: "Introduction to Debt Markets",
    answercontent1:
      "Overview of debt markets and their significance. Types of debt instruments (bonds, debentures, notes). Characteristics and features of debt securities. Role of debt markets in the broader financial system",
    questioncontent2: "Excel Fundamentals:",
    answercontent2:
      "Basic functions and features of Microsoft Excel. Introduction to data entry, formatting, and formulae in Excel.",
    questioncontent3: "Data Manipulation and Cleaning:",
    answercontent3:
      "Techniques for importing, cleaning, and transforming data in Excel. Data validation and error handling processes.",
    questioncontent4: "Data Analysis with Excel: ",
    answercontent4:
      "Exploratory data analysis (EDA) techniques in Excel. Statistical functions and tools for data analysis.",
    questioncontent5: "Data Visualization: ",
    answercontent5:
      "Creating charts, graphs, and dashboards in Excel. Visualizing data trends and patterns for effective communication.",
    questioncontent6: "PivotTables and PivotCharts:",
    answercontent6:
      "Introduction to PivotTables and PivotCharts for data summarization and analysis. Analyzing large datasets and generating insights using PivotTables.",
    questioncontent7: "Advanced Excel Functions:",
    answercontent7:
      "Advanced functions and formulas in Excel for complex data analysis. Using lookup functions, logical functions, and array formulas.",
    questioncontent8: "Statistical Analysis with Excel:",
    answercontent8:
      "Conducting statistical analysis using Excel&#39;s built-in tools. Regression analysis, hypothesis testing, and correlation analysis in Excel.",
    questioncontent9: "Forecasting and Predictive Analytics:",
    answercontent9:
      "Techniques for forecasting and predictive modeling in Excel. Time series analysis, trend analysis, and forecasting methods.",
    questioncontent10: "Optimization and Decision Models:",
    answercontent10:
      "Building optimization models and decision trees in Excel. Solving business problems and making data-driven decisions.",
    questioncontent11: "Data Mining and Machine Learning: ",
    answercontent11:
      "Introduction to data mining techniques and machine learning algorithms in Excel. Implementing predictive models and classification algorithms.",
    questioncontent12: "Real-World Applications and Case Studies:",
    answercontent12:
      "Applying business analytics concepts and techniques to real-world business scenarios. Analyzing case studies and solving business problems using Excel.",

    benefits:
      "Benefits of Online Certificate Course in Business Analytics with Excel:",
    benefits1:
      "Practical Skills: Gain hands-on experience in data analysis and visualization using Microsoft Excel.",
    benefits2:
      "Flexibility: Study at your own pace and convenience, with access to course materials online.",
    benefits3:
      "Cost-Effective: Affordable option for acquiring valuable business analytics skills.",
    benefits4:
      "Industry-Relevant Curriculum: Learn skills directly applicable to various industries and business functions.",
    benefits5:
      "Career Advancement: Enhance your resume and job prospects with in-demand business analytics skills.",
  },
];
export default Sdata4;
